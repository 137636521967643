<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_emptyVehicleRegister" />
      <!-- 空車登録画面 -->
      <v-form ref="form">
        <v-container fluid>
          <v-row class="search-row">
            <!-- 運行日 -->
            <div class="search-autocomplete-supplier first-search-item">
              <div class="date-style">
                <v-text-field
                  outlined
                  dense
                  v-model="operationDate"
                  class="txt-single date-style"
                  :label="$t('label.lbl_operationDate')"
                  @change="changeDate"
                  :rules="[rules.yyyymmdd, rules.required]"
                  clear-icon="mdi-close-circle"
                  clearable
                ></v-text-field>
              </div>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="operationDateCal" @input="dateMenu = false" />
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="preDateOfAchieveTo()"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextDateOfAchieveTo()"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 管轄営業所-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="jurisdictionOffice"
                :items="jurisdictionOfficeList"
                :label="$t('label.lbl_jurisdictionOffice')"
                class="txt-single"
                :hint="getJurisdictionOffice"
                @change="(event) => changeJurisdictionOffice(event)"
                :error-messages="alertMessageJurisdictionOffice"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 管轄子会社-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="jurisdictionChildComp"
                :items="jurisdictionChildCompList"
                :label="$t('label.lbl_jurisdictionChildComp')"
                class="txt-single"
                :hint="getJurisdictionChildComp"
                @change="(event) => changeJurisdictionChildComp(event)"
                :error-messages="alertMessageJurisdictionChildComp"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 社号-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="driverName"
                :items="driverNameList"
                :label="$t('label.lbl_driverName')"
                class="txt-single"
                :hint="getDriverName"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 利用営業所-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="availableOffice"
                :items="availableOfficeList"
                :label="$t('label.lbl_useOffice')"
                class="txt-single"
                :hint="getAvailableOffice"
                :rules="[rules.required]"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require asterisk-spacer">*</span>
          </v-row>

          <!--ボタン領域-->
          <v-row>
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!--検索ボタン-->
                <v-btn
                  color="primary"
                  id="get-search"
                  class="other-btn"
                  @click="getEmptyVehicleList()"
                  >{{ $t("btn.btn_search") }}</v-btn
                >
                <!--戻るボタン-->
                <v-btn
                  color="primary"
                  id="get-search"
                  class="other-btn"
                  @click="viewConfirmDialog('btn_back')"
                  >{{ $t("btn.btn_back") }}</v-btn
                >
              </div>

              <!-- 利用不可/登録 -->
              <div style="float: right; display: flex">
                <!-- 利用不可ボタン -->
                <v-btn
                  color="primary"
                  class="other-btn"
                  @click="viewConfirmDialog('btn_unavailable')"
                  >{{ $t("btn.btn_unavailable") }}
                </v-btn>
                <!-- 登録ボタン -->
                <v-btn color="primary" class="api-btn" @click="viewConfirmDialog('btn_insert')">{{
                  $t("btn.btn_insert")
                }}</v-btn>
              </div>
            </div>
          </v-row>
        </v-container>

        <!-- データテーブル -->
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            disable-filtering
            :hide-default-footer="true"
            :items-per-page="50"
            height="630px"
          >
            <!-- ヘッダーチェックボックス-->
            <template v-slot:[`header.check`]="{ header }">
              <input
                type="checkbox"
                :checked="isAllSelected"
                :value="header.check"
                @click="selectAllCheck"
                style="transform: scale(2)"
              />
            </template>
            <!-- 利用営業所状況 -->
            <template v-slot:[`header.availableOfficeStatus`]="{ header }">
              <v-row>
                <v-col>
                  <div>{{ header.text }}</div>
                  <div style="width: 80px; text-align: center">{{ $t("label.lbl_Situation") }}</div>
                </v-col>
              </v-row>
            </template>
            <!-- 車両形状大項目 -->
            <template v-slot:[`header.vehicleShapeMajor`]="{ header }">
              <v-row>
                <v-col>
                  <div>{{ header.text }}</div>
                  <div style="width: 65px; text-align: center">{{ $t("label.lbl_major") }}</div>
                </v-col>
              </v-row>
            </template>
            <!-- 車両形状小項目 -->
            <template v-slot:[`header.vehicleShapeMinor`]="{ header }">
              <v-row>
                <v-col>
                  <div>{{ header.text }}</div>
                  <div style="width: 65px; text-align: center">{{ $t("label.lbl_minor") }}</div>
                </v-col>
              </v-row>
            </template>
            <!-- 一覧チェックボックス -->
            <template v-slot:[`item.check`]="{ item, index }">
              <input
                type="checkbox"
                v-model="item.check"
                :value="listCheckbox"
                :disabled="!item.isEmpty"
                @click="selectCheck(index)"
                style="transform: scale(2)"
              />
            </template>
            <!-- 社号 -->
            <template v-slot:[`item.driverName`]="{ item }">
              <div class="listNo-style">{{ item.vehicleCd }}</div>
              <div class="listNo-style">{{ "(" + item.vehicleShape + ")" }}</div>
            </template>
            <!-- 管轄営業所 -->
            <template v-slot:[`item.jurisdictionOffice`]="{ item }">
              <div class="listNo-style">{{ item.jurisdictionOffice }}</div>
            </template>
            <!-- 管轄子会社 -->
            <template v-slot:[`item.jurisdictionChildComp`]="{ item }">
              <div class="listNo-style">{{ item.jurisdictionChildComp }}</div>
            </template>
            <!-- 利用営業所状況 -->
            <template v-slot:[`item.availableOfficeStatus`]="{ item }">
              <div v-if="item.availableOfficeStatus == '利用不可'">
                <div class="align-left-red">{{ item.availableOfficeStatus }}</div>
              </div>
              <div v-else class="listNo-style">{{ item.availableOfficeStatus }}</div>
            </template>
            <!-- 最大積載重量 -->
            <template v-slot:[`item.maxLoadingWeight`]="{ item }">
              <div class="align-right">
                {{ item.maxLoadingWeight }}
              </div>
            </template>
            <!-- ナンバー -->
            <template v-slot:[`item.licenseNumber`]="{ item }">
              <div class="listNo-style">{{ item.licenseNumber }}</div>
            </template>
            <!-- 車両形状大項目 -->
            <template v-slot:[`item.vehicleShapeMajor`]="{ item }">
              <div class="listNo-style">{{ item.vehicleShapeMajor }}</div>
            </template>
            <!-- 車両形状小項目 -->
            <template v-slot:[`item.vehicleShapeMinor`]="{ item }">
              <div class="listNo-style">{{ item.vehicleShapeMinor }}</div>
            </template>
            <!-- 備考 -->
            <template v-slot:[`item.inputRemarks`]="{ item }">
              <v-btn small @click="openDetailInputDialog(item)" text>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <!-- <span class="test">
                {{ item.inputRemarks }}
              </span> -->
              <span
                v-if="item.inputRemarks !== null && 20 < item.inputRemarks.length"
                class="text-overflow"
                style="display: inline-block; max-width: 220px; height: 21px"
              >
                {{ item.inputRemarks }}
              </span>
              <span v-else>
                {{ item.inputRemarks }}
              </span>
            </template>
          </v-data-table>
        </v-container>

        <v-navigation-drawer absolute temporary v-model="openMenu">
          <sideMenu></sideMenu>
        </v-navigation-drawer>
        <!-- 詳細入力モーダル -->
        <v-dialog v-model="isOpenDetailInputDialog" :max-width="1300" persistent>
          <detailInputDialog
            :isOpenDetailDialog.sync="isOpenDetailInputDialog"
            :editItem="editItem"
            v-on:child-event="parentMethod"
          />
        </v-dialog>
        <SimpleDialog
          :isShow.sync="infoDialog.isOpen"
          :title="infoDialog.title"
          :message="infoDialog.message"
          :homePageFlag="infoDialog.homePageFlag"
          :registVehicleListFlg="infoDialog.registVehicleListFlg"
          :emptyVehicleRegistFlg="infoDialog.emptyVehicleRegistFlg"
        />
        <ConfirmDialog
          :isShow.sync="confirmDialog.isOpen"
          :message="confirmDialog.message"
          :screenFlag="confirmDialog.screenFlag"
          :okAction="confirmDialog.okAction"
          :redMessage="confirmDialog.redMessage"
          :changeFlag="confirmDialog.changeFlag"
        />
        <v-dialog v-model="alertDialog" :max-width="800">
          <!-- 警告ダイアログ -->
          <v-card>
            <v-card-title class="blue-grey lighten-3" primary-title>
              {{ $t("btn.btn_ok") }}
            </v-card-title>
            <v-card-text class="pa-4">
              <p>{{ message }}</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="alertCancel"> OK </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-sheet>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { i18n } from "../../lang/lang.js";
import NavBar from "../../components/NavBar.vue";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil.js";
import { appConfig } from "../../assets/scripts/js/AppConfig.js";
import { getParameter } from "../../assets/scripts/js/GetParameter.js";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil.js";
import ConfirmDialog from "@/components/ConfirmDialog";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import detailInputDialog from "./EmptyVehicleRegistRemarksInputDialog";
export default {
  name: appConfig.SCREEN_ID.P_DVP_005,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    NavBar,
    detailInputDialog,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // メニュー
    openMenu: null,
    // 運行日
    operationDate: "",
    // 運行日カレンダー
    operationDateCal: "",
    // 管轄営業所
    jurisdictionOffice: "",
    // 管轄営業所リスト
    jurisdictionOfficeList: [],
    // 管轄子会社
    jurisdictionChildComp: "",
    // 管轄子会社リスト
    jurisdictionChildCompList: [],
    // 社号
    driverName: "",
    // 社号リスト
    driverNameList: [],
    // 利用営業所
    availableOffice: "04",
    // 利用営業所リスト
    availableOfficeList: [],
    // 表示用リスト
    inputList: [],
    tmpList: [],
    // 全選択チェックボックス
    isAllSelected: false,
    // 一覧選択チェックボックス
    listCheckbox: [1, 2],
    //チェック行インデックス
    checkIndex: -1,
    isOpenDetailInputDialog: false,
    editItem: {},
    // 警告ダイアログ
    alertDialog: false,
    // 管轄営業所アラートメッセージ
    alertMessageJurisdictionOffice: "",
    // 管轄子会社アラートメッセージ
    alertMessageJurisdictionChildComp: "",
    message: "",
    // 車両
    vehiclesSelected: "",
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      closeFlag: false,
      outsideClickNotCloseFlg: false,
      registVehicleListFlg: false,
      emptyVehicleRegistFlg: false,
      homePageFlag: false,
      okAction: () => {},
    },
    // 確認メッセージ
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // バリデーション
    rules: {
      yyyymmdd: function (value) {
        if (!value) {
          return true;
        }
        if (
          !(
            dateTimeHelper.validDate(commonUtil.zen2han(value), "/") && dateTimeHelper.isDate(value)
          )
        ) {
          return i18n.tc("check.chk_inputDate");
        }
        return true;
      },
      // 必須チェック
      required: (value) => !!value || i18n.tc("check.chk_input"),
    },
    // ヘッダ
    headerItems: [
      // チェックボックス
      {
        text: i18n.tc("lbl_select"),
        value: "check",
        width: "3%",
        align: "center",
        sortable: false,
      },
      // 社号
      {
        text: i18n.tc("label.lbl_driverName"),
        value: "driverName",
        width: "10%",
        align: "left",
        sortable: false,
      },
      // 管轄営業所
      {
        text: i18n.tc("label.lbl_jurisdictionOffice"),
        value: "jurisdictionOffice",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 管轄子会社
      {
        text: i18n.tc("label.lbl_jurisdictionChildComp"),
        value: "jurisdictionChildComp",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 利用営業所状況
      {
        text: i18n.tc("label.lbl_useOffice"),
        value: "availableOfficeStatus",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 最大積載重量
      {
        text: i18n.tc("label.lbl_maxLoadingWeightKg"),
        value: "maxLoadingWeight",
        width: "7%",
        align: "center",
        sortable: false,
      },
      // ナンバー
      {
        text: i18n.tc("label.lbl_licenseNumber"),
        value: "licenseNumber",
        width: "5%",
        align: "left",
        sortable: false,
      },
      // 車両形状大項目
      {
        text: i18n.tc("label.lbl_vehicleShape"),
        value: "vehicleShapeMajor",
        width: "5%",
        align: "left",
        sortable: false,
      },
      // 車両形状小項目
      {
        text: i18n.tc("label.lbl_vehicleShape"),
        value: "vehicleShapeMinor",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 備考
      {
        text: i18n.tc("label.lbl_remarks"),
        value: "inputRemarks",
        width: "15%",
        align: "left",
        sortable: false,
      },
    ],
    detailDialogData: {},
    costInfoList: [],
    vehicleList: [],
    dateMenu: false,
    pushedButton: "",
  }),
  methods: {
    // 初期化
    async init() {
      // 運行日
      if (this.$route.params.operationDate) {
        this.operationDate = this.$route.params.operationDate;
        this.operationDateCal = dateTimeHelper.dateCalc(this.$route.params.operationDate);
      } else {
        this.operationDate = dateTimeHelper.convertJST();
        this.operationDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      }
      // 営業所情報取得
      await this.getJurisdictionOfficeInfo();
      // コード情報取得
      await this.getCodeMst();
      // 一覧を表示
      this.getEmptyVehicleList();
    },
    /**
     * 管轄営業所情報取得
     */
    async getJurisdictionOfficeInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const officeList = getParameter.getJurisdictionOffice();
      await Promise.all([officeList])
        .then((result) => {
          this.jurisdictionOffice = "";
          // 画面の初期値を設定します。
          this.jurisdictionOfficeList = result[0];
          const tmpJurisdictionOffice = this.jurisdictionOfficeList.find(
            (v) => v.value == sessionStorage.getItem("sales_office_sid")
          );
          if (tmpJurisdictionOffice) {
            this.jurisdictionOffice = tmpJurisdictionOffice.value;
            this.changeJurisdictionOffice(this.jurisdictionOffice);
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    /**
     * 社号取得
     */
    getVehicleCode(params) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      this.driverName = "";
      const vehicleCodeList = getParameter.getVehicleCode(params);
      Promise.all([vehicleCodeList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.driverNameList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    /**
     * コードマスタ情報取得
     */
    async getCodeMst() {
      // ローディング画面表示ON;
      this.loadingCounter = 1;
      // 管轄子会社
      const tmpJurisdictionChildCompList = getParameter.getTmsCodeMst({
        codeType: appConfig.CODETYPE.SUBSIDIARY_CODE_DIV,
      });
      // 利用営業所
      const tmpAvailableOfficeList = getParameter.getTmsCodeMst({
        codeType: appConfig.CODETYPE.USE_OFFICE_CODE_DIV,
      });
      await Promise.all([tmpJurisdictionChildCompList, tmpAvailableOfficeList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.jurisdictionChildCompList = result[0];
          this.availableOfficeList = result[1];
          // ドロップダウンの表示をコード(コード名)になるように設定
          this.jurisdictionChildCompList.forEach((v) => {
            v.text = `${v.value}` + `（${v.name}）`;
          });
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    /**
     * 空車一覧取得
     */
    getEmptyVehicleList() {
      if (this.$refs.form.validate()) {
        // アラートメッセージ初期化
        this.alertMessageJurisdictionOffice = "";
        this.alertMessageJurisdictionChildComp = "";
        // 入力チェック
        if (!this.jurisdictionOffice && !this.jurisdictionChildComp) {
          this.alertMessageJurisdictionOffice = i18n.tc(
            "check.chk_input_jurisdictionOffice_jurisdictionChildComp"
          );
          this.alertMessageJurisdictionChildComp = i18n.tc(
            "check.chk_input_jurisdictionOffice_jurisdictionChildComp"
          );
          return;
        }
        // ローディング画面表示ON;
        this.loadingCounter = 1;
        const config = this.$httpClient.createGetApiRequestConfig();
        // 運行日
        config.params.operationDate = this.operationDate.substring(0, 10);
        // 管轄営業所SID
        if (this.jurisdictionOffice) {
          config.params.jurisdictionOfficeSid = this.jurisdictionOffice;
        }
        // 管轄子会社コード
        if (this.jurisdictionChildComp) {
          config.params.jurisdictionChildCompCd = this.jurisdictionChildComp;
        }
        // 車両SID
        if (this.driverName) {
          config.params.vehicleSid = this.driverName;
        }
        // 利用営業所
        config.params.availableOffice = this.availableOffice;
        // console.debug("executeCostMstDetailApi() Config", config);
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.BIZ_EMPTY_VEHICLE_SEARCH, config)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // console.debug("executeCostMstDetailApi() Response", response);
              // 正常時
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                const list = [];
                jsonData.resIdv.emptyVehicleList.forEach((row) => {
                  let useOfficeName = [];
                  let useOfficeSidList = [];
                  if (row.useOffice.length > 0) {
                    useOfficeName = row.useOffice.map((v) => v.useOfficeName);
                    useOfficeSidList = row.useOffice.map((v) => v.useOfficeSid);
                  }
                  list.push({
                    vehicleSid: row.vehicleSid,
                    vehicleCd: row.vehicleCode,
                    vehicleShape:
                      `${row.vehicleShapeMajor}` + `${row.vehicleShapeMinor}` == ""
                        ? i18n.tc("label.lbl_vehicleShapeUnRegist")
                        : `${row.vehicleShapeMajor}` + `${row.vehicleShapeMinor}`,
                    jurisdictionOfficeSid: row.officeSid,
                    jurisdictionOffice: row.officeName,
                    jurisdictionChildComp: row.subsidiaryName,
                    availableOfficeStatus: useOfficeName.join(),
                    maxLoadingWeight:
                      row.payload == 0 ? "0" : commonFunction.getDelimiter(row.payload, 9),
                    licenseNumber: row.registrationNumber,
                    vehicleShapeMajor: row.vehicleShapeMajor,
                    vehicleShapeMinor: row.vehicleShapeMinor,
                    inputRemarks: row.remarks,
                    isEmpty:
                      useOfficeSidList.join() == "" ||
                      (!useOfficeSidList.includes(appConfig.CONSTANT.UNAVAILABLE_OFFICE_SID) &&
                        !useOfficeSidList.includes(sessionStorage.getItem("sales_office_sid")))
                        ? true
                        : false,
                    check: false,
                    updateDateTime:
                      row.updateDateTime == "" ? row.updateDateTime : row.updateDateTime,
                  });
                });
                this.inputList = list;
                resolve(response);
              } else {
                // エラー時
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },
    /**
     * 管轄営業所変更
     */
    changeJurisdictionOffice(val) {
      const params = {};
      if (this.isNullOrBlank(val)) {
        // 管轄子会社CD
        if (this.jurisdictionChildComp) {
          params.jurisdictionChildCompCd = this.jurisdictionChildComp;
        }
        // 管轄営業所SID
        params.jurisdictionOfficeSid = this.jurisdictionOffice;
        this.getVehicleCode(params);
      } else {
        // 管轄子会社コードが選択されていない場合
        if (!this.jurisdictionChildComp ?? false) {
          // 社号リストを空にする。
          this.driverNameList = [];
          this.driverName = "";
        } else {
          params.jurisdictionChildCompCd = this.jurisdictionChildComp;
          this.getVehicleCode(params);
        }
      }
    },
    /**
     * 管轄子会社変更
     */
    changeJurisdictionChildComp(val) {
      const params = {};
      if (this.isNullOrBlank(val)) {
        // 管轄営業所SID
        if (this.jurisdictionOffice) {
          params.jurisdictionOfficeSid = this.jurisdictionOffice;
        }
        // 管轄子会社CD
        params.jurisdictionChildCompCd = this.jurisdictionChildComp;
        this.getVehicleCode(params);
      } else {
        // 管轄営業所が選択されていない場合
        if (!this.jurisdictionOffice ?? false) {
          // 社号リストを空にする。
          this.driverNameList = [];
          this.driverName = "";
        } else {
          params.jurisdictionOfficeSid = this.jurisdictionOffice;
          this.getVehicleCode(params);
        }
      }
    },

    /**
     * 備考入力モーダル表示処理
     */
    openDetailInputDialog(item) {
      this.isOpenDetailInputDialog = true;
      this.editItem = item;
    },

    /**
     * 本画面と備考入力ダイアログとのデータ受け渡しを行う関数
     */
    parentMethod: async function (item) {
      this.editedIndex = this.inputList.indexOf(item);
      this.inputList.splice(this.editedIndex, 1, item);
    },

    /**
     * ボタン押下時の処理
     */
    viewConfirmDialog(button) {
      let isValidationRequired;
      let message;
      let action = () => {};
      switch (button) {
        case "btn_insert":
          // １つも明細がチェックされていない場合、警告ダイアログを表示
          if (!this.isCheck() && this.$refs.form.validate()) {
            // なければ警告ダイアログ表示
            this.alertDialog = true;
            this.message = messsageUtil.getMessage("P-EXT-003_001_E");
            return;
          }
          isValidationRequired = true;
          this.pushedButton = button;
          message = messsageUtil.getMessage("P-RCV-001_003_C");
          action = this.emptyVehicleRegist;
          break;
        case "btn_back":
          isValidationRequired = false;
          message = messsageUtil.getMessage("P-COM-001_004_W");
          action = this.backPage;
          break;
        case "btn_unavailable":
          // １つも明細がチェックされていない場合、警告ダイアログを表示
          if (!this.isCheck() && this.$refs.form.validate()) {
            // なければ警告ダイアログ表示
            this.alertDialog = true;
            this.message = messsageUtil.getMessage("P-EXT-003_001_E");
            return;
          }
          // 選択した明細に利用営業所が含まれている場合、警告ダイアログを表示
          if (this.isIncludeAvailableOffice() && this.$refs.form.validate()) {
            this.alertDialog = true;
            this.message = messsageUtil.getMessage("P-DVP-005_002_E");
            return;
          }
          isValidationRequired = true;
          this.pushedButton = button;
          message = messsageUtil.getMessage("P-DVP-005_002_C");
          action = this.emptyVehicleRegist;
          break;
        default:
          message = "";
      }
      if (isValidationRequired) {
        let isValid = this.$refs.form.validate();
        if (!isValid) {
          return;
        }
      }
      this.confirmDialog.message = message;
      this.confirmDialog.title = appConfig.DIALOG.title;
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = action;
    },
    /**
     * 登録車両一覧画面に戻る
     */
    backPage() {
      // 登録車両一覧画面へ遷移
      this.$router.push({
        name: appConfig.SCREEN_ID.P_DVP_004,
        params: {
          operationDate: this.$route.params.operationDate,
          jurisdictionOffice: this.$route.params.jurisdictionOffice,
          jurisdictionChildComp: this.$route.params.jurisdictionChildComp,
          driverName: this.$route.params.driverName,
          availableOffice: this.$route.params.availableOffice,
        },
      });
    },

    /**
     * 空車登録処理
     */
    emptyVehicleRegist() {
      if (this.$refs.form.validate()) {
        // ローディング画面表示ON
        this.loadingCounter = 1;

        // httpClientで使用する共通IFパラメータ等を作成する。
        const body = this.$httpClient.createRequestBodyConfig();
        // 共通IF項目 画面ID
        body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_DVP_005;
        let isSuccess = false;
        // 空車リスト
        let bodyList = [];
        // 利用予定営業所SID
        let usePlanOfficeSid = "";
        // チェックが入った明細をフィルター
        const checkList = this.inputList.filter((v) => v.check == true);
        // 利用予定営業所SIDの設定
        if (this.pushedButton == "btn_insert") {
          // 登録ボタン押下時
          usePlanOfficeSid = sessionStorage.getItem("sales_office_sid");
        } else if (this.pushedButton == "btn_unavailable") {
          // 利用不可ボタン押下時
          usePlanOfficeSid = appConfig.CONSTANT.UNAVAILABLE_OFFICE_SID;
        }
        // リクエストボディの作成
        checkList.forEach((v) => {
          let inputMap = {};
          // 運行日
          inputMap.operationDate = dateTimeHelper.convertUTC(this.operationDate);
          // 車両SID
          inputMap.vehicleSid = v.vehicleSid;
          // 利用予定営業所SID
          inputMap.usePlanOfficeSid = usePlanOfficeSid;
          // 管轄営業所SID
          if (this.jurisdictionOffice) {
            inputMap.ownerOfficeSid = this.jurisdictionOffice;
          }
          // 備考
          if (this.isNullOrBlank(v.inputRemarks)) {
            inputMap.remarks = v.inputRemarks;
          }
          // 最終更新日時
          inputMap.updateDateTime =
            v.updateDateTime == "" ? dateTimeHelper.convertUTC() : v.updateDateTime;
          // 登録区分
          inputMap.registDiv = "01";
          bodyList.push(inputMap);
        });
        // 空車リスト
        body.reqIdv.emptyVehicleList = bodyList;
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.BIZ_EMPTY_VEHICLE_UPSERT, body, appConfig.APP_CONFIG)
            .then((response) => {
              // console.debug("planRegistion() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));

              // 正常時
              if (jsonData.resCom.resComCode == "000") {
                this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
                this.infoDialog.title = i18n.tc("label.lbl_result");
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = false;
                this.infoDialog.outsideClickNotCloseFlg = true;
                this.infoDialog.firstPageFlag = false;
                isSuccess = true;
                resolve();
              } else {
                // エラーメッセージをpopupのmessageに格納
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                this.infoDialog.homePageFlag = false;
                this.infoDialog.registVehicleListFlg = false;
                this.infoDialog.emptyVehicleRegistFlg = false;
                this.infoDialog.outsideClickNotCloseFlg = false;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex.message;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.homePageFlag = false;
              this.infoDialog.registVehicleListFlg = false;
              this.infoDialog.emptyVehicleRegistFlg = false;
              this.infoDialog.outsideClickNotCloseFlg = false;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
              if (isSuccess) {
                this.getEmptyVehicleList();
                this.endEvent();
              }
            });
        });
      }
    },
    /**
     * イベント終了ダイアログ表示
     */
    endEvent() {
      this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
      this.infoDialog.title = i18n.tc("label.lbl_result");
      this.infoDialog.isOpen = true;
      this.infoDialog.screenFlag = true;
      // 画面遷移ボタン
      this.infoDialog.homePageFlag = true;
      this.infoDialog.registVehicleListFlg = true;
      this.infoDialog.emptyVehicleRegistFlg = true;
    },
    /**
     * 明細行を選択しているかの判定処理
     */
    isCheck() {
      // チェックした行
      const checkList = this.inputList.filter((data) => data.check);
      // １つもチェックされていない場合、画面遷移できない
      if (!checkList.length) {
        return false;
      }
      return true;
    },

    /**
     * 選択した明細行に利用予定営業所が含まれているかの判定処理
     */
    isIncludeAvailableOffice() {
      // チェックがあり、利用営業所が存在する行
      const chkAvailableOfficeList = this.inputList.filter(
        (v) => v.check && v.availableOfficeStatus != ""
      );
      // チェックした行に利用営業所が存在する場合
      if (chkAvailableOfficeList.length) {
        return true;
      }
      return false;
    },
    /**
     * 警告ダイアログ、OKボタン押下
     */
    alertCancel() {
      this.alertDialog = false;
    },
    /**
     * 全選択チェックボックス処理
     */
    selectAllCheck() {
      if (!this.isAllSelected) {
        this.listCheckbox = [];
        this.listCheckbox = true;
        this.isAllSelected = true;
        for (var i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].isEmpty) {
            this.inputList[i].check = true;
          }
        }
      } else {
        this.listCheckbox = [];
        this.listCheckbox = false;
        for (var j = 0; j < this.inputList.length; j++) {
          if (this.inputList[j].isEmpty) {
            this.inputList[j].check = false;
          }
        }
        this.isAllSelected = false;
      }
    },
    /**
     * 一覧チェックボックス処理
     */
    selectCheck(index) {
      //チェックした行のインデックス
      this.checkIndex = index;
    },
    /**
     * 明細の利用営業所が利用不可かどうか判定
     */
    isAvailableOffice(office) {
      if (office == "利用不可") {
        return true;
      }
      return false;
    },
    // ヘッダー関連
    // カレンダー関連
    /** 運行日付をー１日 */
    preDateOfAchieveTo() {
      if (this.operationDateCal == null) {
        return;
      }
      let date = new Date(this.operationDateCal);
      this.operationDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },
    /** 運行日付を+１日 */
    nextDateOfAchieveTo() {
      if (this.operationDateCal == null) {
        return;
      }
      let date = new Date(this.operationDateCal);
      this.operationDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },

    /** 日付入力 */
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.slice(0, 4) + "/" + inputVal.slice(4, 6) + "/" + inputVal.slice(6, 8);
      } else {
        strDt = inputVal.slice(0, 4) + "/" + inputVal.slice(5, 7) + "/" + inputVal.slice(8, 10);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.operationDateCal = dateTimeHelper.dateCalc(strDt);
        this.operationDate = strDt;
      } else {
        this.operationDateCal = null;
      }
    },

    /** カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。*/
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    /**
     * アラートメッセージクリア処理
     */
    clearAlertMessage() {
      // アラートメッセージを非表示にする。
      this.alertMessageJurisdictionOffice = "";
      this.alertMessageJurisdictionChildComp = "";
    },

    /**
     * 存在チェック
     */
    isNullOrBlank(val) {
      if ((val ?? false) && val != "") {
        return true;
      }
      return false;
    },
  },

  computed: {
    // 管轄営業所ヒント取得
    getJurisdictionOffice() {
      let tmpJurisdictionOffice = this.jurisdictionOfficeList.find(
        (v) => v.value == this.jurisdictionOffice
      );
      if (tmpJurisdictionOffice) {
        return tmpJurisdictionOffice.name;
      }
      return "";
    },
    // 管轄子会社ヒント取得
    getJurisdictionChildComp() {
      let tmpJurisdictionChildComp = this.jurisdictionChildCompList.find(
        (v) => v.value == this.jurisdictionChildComp
      );
      if (tmpJurisdictionChildComp) {
        return tmpJurisdictionChildComp.name;
      }
      return "";
    },
    // 社号ヒント取得
    getDriverName() {
      let driverName = this.driverNameList.find((v) => v.value == this.driverName);
      if (driverName) {
        return driverName.name;
      }
      return "";
    },
    getAvailableOffice() {
      let availableOffice = this.availableOfficeList.find((v) => v.value == this.availableOffice);
      if (availableOffice) {
        return availableOffice.name;
      }
      return "";
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    jurisdictionOffice(val) {
      if (this.isNullOrBlank(val)) {
        // アラートメッセージを非表示にする。
        this.clearAlertMessage();
      }
    },
    jurisdictionChildComp(val) {
      if (this.isNullOrBlank(val)) {
        // アラートメッセージを非表示にする。
        this.clearAlertMessage();
      }
    },
    operationDateCal: {
      handler(val) {
        this.operationDate = this.formatDate(val);
      },
      deep: true,
    },
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.v-data-table {
  white-space: pre-line;
}
.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.align-left-red {
  color: red;
}
</style>
