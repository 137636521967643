<template>
  <v-dialog :value="isShow" :max-width="800" :retain-focus="false" persistent>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on"></span>
    </template>

    <v-card>
      <v-card-title id="sub-bar">
        <span id="lbl-screen-name">{{ dialogTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <!-- 幹線発地 -->
            <v-col>
              <v-autocomplete
                dense
                v-model="fromTrunkBase"
                :items="trunkBases"
                class="txt-single"
                persistent-hint
                :label="$t('label.lbl_fromTrunkBase')"
                style="margin: 30px"
                clearable
              ></v-autocomplete>
            </v-col>
            <!-- 幹線着地 -->
            <v-col>
              <v-autocomplete
                dense
                v-model="toTrunkBase"
                :items="trunkBases"
                class="txt-single"
                persistent-hint
                :label="$t('label.lbl_toTrunkBase')"
                style="margin: 30px"
                clearable
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-btn color="primary" elevation="2" @click="addTrunk()" style="margin: 0 0 0 auto"
            >OK
          </v-btn>
          <v-btn color="light-grey" elevation="2" @click="cancelInput()" style="margin: 0 0 0 20px"
            >キャンセル
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TrunkLineAddDialog",
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    lineInfo: {
      index: { type: String, default: "" },
      item: null,
    },
    dialogTitle: { type: String, default: "" },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      fromTrunkBase: "",
      toTrunkBase: "",
      trunkBases: [],
    };
  },
  computed: {},
  methods: {
    init() {
      this.getTrunkBases();
    },

    /**
     * 幹線拠点の一覧を取得
     */
    getTrunkBases() {
      const trunkBasesTemp = [
        {
          text: "柏原営業所",
          value: "2410000002",
          name: "柏原営業所",
        },
        {
          text: "焼津IC",
          value: "2410000006",
          name: "焼津IC",
        },
        {
          text: "浜松営業所",
          value: "2410000010",
          name: "浜松営業所",
        },
      ];
      this.trunkBases = trunkBasesTemp;
    },

    addTrunk() {
      console.log("幹線追加");
      console.log(this.lineInfo);
      this.$emit("setTrunkInfo", this.fromTrunkBase, this.toTrunkBase, this.lineInfo);
      this.$emit("update:isShow", false);
    },

    cancelInput() {
      this.$emit("cancelTransportCompChange", this.lineInfo.index, this.lineInfo.item);
      this.$emit("update:isShow", false);
    },
  },

  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
</style>
