<template>
  <v-dialog :value="isShow" @input="close()" max-width="65vw">
    <v-card class="mx-auto px-4 py-2" height="max-content">
      <v-card-title>
        <p style="background: linear-gradient(transparent 50%, #ffff00 95%)">
          {{ $t("label.lbl_remarksRegistration") }}
        </p>
      </v-card-title>
      <v-card-subtitle>
        <span style="font-size: 0.9rem; vertical-align: Top">{{ " 最大200文字まで" }}</span>
      </v-card-subtitle>
      <!-- 詳細入力 -->
      <v-container fluid>
        <v-textarea
          v-model="textFieldValue"
          filled
          :rules="[(val) => val.length <= 200 || $t('check.chk_limitLength_200')]"
          :maxlength="200"
          :error.sync="hasError"
        ></v-textarea>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- 閉じるボタン -->
        <v-btn color="primary" class="other-btn" @click="close()">{{ $t("btn.btn_close") }}</v-btn>
        <!-- 設定ボタン -->
        <v-btn color="primary" class="other-btn" :disabled="hasError" @click="updateRemarks()">{{
          $t("btn.btn_config")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "RemarksEditDialog",
  props: {
    isShow: { type: Boolean, required: true },
    remarks: { type: String, required: false, default: "" },
  },
  data() {
    return {
      hasError: false,
      textFieldValue: "",
    };
  },
  watch: {
    isShow(newValue) {
      // ダイアログを開いたとき、テキストエリアの内容をremarksで渡ってきた値にする
      if (newValue) {
        this.textFieldValue = this.remarks;
      }
    },
  },
  methods: {
    close() {
      this.$emit("update:isShow", false);
    },
    updateRemarks() {
      if (this.hasError) {
        return;
      }
      this.$emit("update:remarks", this.textFieldValue);
      this.$nextTick(() => this.close());
    },
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
