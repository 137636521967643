<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_makeLotByOfficeList" />
      <v-form ref="form" lazy-validation>
        <!-- 検索項目-->
        <v-container fluid>
          <!-- 1行目-->
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :search-input.sync="supplierInput"
                @change="changeSupplier"
                :rules="[rules.isValue2Required(inquiryNumber, suppliersSelected)]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 発注日(From)-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="orderDateFrom"
                :label="$t('label.lbl_orderDateFrom')"
                @change="changeDateFrom($event, 'order')"
                :rules="[
                  rules.inputRequiredCreate(orderDateFrom, orderDateTo),
                  rules.yyyymmdd_create,
                ]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>

            <div class="calender-btn-area">
              <v-menu
                v-model="orderDateFromMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="orderDateFromCal"
                  @input="orderDateFromMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevFromDate('order')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextFromDate('order')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <div class="from_to">～</div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 発注日(To) -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="orderDateTo"
                :label="$t('label.lbl_orderDateTo')"
                @change="changeDateTo($event, 'order')"
                :rules="[
                  rules.inputRequiredCreate(orderDateTo, orderDateFrom),
                  rules.yyyymmdd_create,
                ]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="alertMessage"
              ></v-text-field>
            </div>

            <div class="calender-btn-area">
              <v-menu
                v-model="orderDateToMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="orderDateToCal"
                  @input="orderDateToMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevToDate('order')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextToDate('order')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 担当営業所 -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="officeSelected"
                :items="officeList"
                :label="$t('label.lbl_transportOffice')"
                :hint="setSrhOffice()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                @change="changeOffice"
                :search-input.sync="getOfficeSelected"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- ルート登録 -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="routeRegistrationDivSelected"
                :items="routeRegistrationList"
                :label="$t('label.lbl_routeRegistration')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
          </v-row>

          <!-- 2段目 -->
          <v-row class="search-row" style="margin-top: 20px">
            <!-- 出荷元-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="pickupPointSelected"
                :items="pickupPointList"
                :label="$t('label.lbl_pickupPoint')"
                :hint="setSrhPickup()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                @change="changePickup"
                :search-input.sync="getPickupSelected"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 都道府県(出荷元)-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="pickupPointprefecturesSelected"
                :label="$t('label.lbl_prefectures')"
                :items="prefecturesList"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                @change="chengePickupPointPrefectures()"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 市区町村(出荷元)-->
            <div class="first-search-item" style="width: 256px">
              <v-text-field
                outlined
                dense
                v-model="pickupMunicipalitiesName"
                :label="$t('label.lbl_municipality')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                @change="chengePickupPointMunicipality()"
                @click="openModalMunicipality('pickup')"
              ></v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 集荷予定日(From)-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="pickupScheduleDateFrom"
                :label="$t('label.lbl_pickupScheduleDateFrom')"
                @change="changeDateFrom($event, 'pickup')"
                :rules="[
                  rules.inputRequiredCreate(pickupScheduleDateFrom, pickupScheduleDateTo),
                  rules.yyyymmdd_create,
                ]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>

            <div class="calender-btn-area">
              <v-menu
                v-model="pickupScheduleDateFromMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="pickupScheduleDateFromCal"
                  @input="pickupScheduleDateFromMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevFromDate('pickup')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextFromDate('pickup')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <div class="from_to">～</div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 集荷予定日(To)-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="pickupScheduleDateTo"
                :label="$t('label.lbl_pickupScheduleDateTo')"
                @change="changeDateTo($event, 'pickup')"
                :rules="[
                  rules.inputRequiredCreate(pickupScheduleDateTo, pickupScheduleDateFrom),
                  rules.yyyymmdd_create,
                ]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="pickupAlertMessage"
              ></v-text-field>
            </div>

            <div class="calender-btn-area">
              <v-menu
                v-model="pickupScheduleDateToMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="pickupScheduleDateToCal"
                  @input="pickupScheduleDateToMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevToDate('pickup')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextToDate('pickup')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 送り状発行状態 -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="invNumStatusSelected"
                :items="invNumStatusList"
                :label="$t('label.lbl_invoice')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
          </v-row>
          <!-- 3段目 -->
          <v-row class="search-row" style="margin-top: 20px">
            <!-- お届け先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="deliveryPointSelected"
                :items="deliveryPointList"
                :label="$t('label.lbl_addressee')"
                :hint="setSrhDelivery()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                @change="changeDelivery"
                :search-input.sync="getDeliverySelected"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 都道府県(お届け先)-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="deliveryPointprefecturesSelected"
                :label="$t('label.lbl_prefectures')"
                :items="prefecturesList"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                @change="chengeDeliveryPointPrefectures()"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 市区町村(お届け先)-->
            <div class="first-search-item" style="width: 256px">
              <v-text-field
                outlined
                dense
                v-model="deliveryMunicipalitiesName"
                :label="$t('label.lbl_municipality')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                @change="chengeDeliveryPointMunicipality()"
                @click="openModalMunicipality('delivery')"
              ></v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 到着予定日(From)-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="deliveryScheduleDateFrom"
                :label="$t('label.lbl_deliveryScheduleDateFrom')"
                @change="changeDateFrom($event, 'delivery')"
                :rules="[
                  rules.inputRequiredCreate(deliveryScheduleDateFrom, deliveryScheduleDateTo),
                  rules.yyyymmdd_create,
                ]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>

            <div class="calender-btn-area">
              <v-menu
                v-model="deliveryScheduleDateFromMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="deliveryScheduleDateFromCal"
                  @input="deliveryScheduleDateFromMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevFromDate('delivery')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextFromDate('delivery')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <div class="from_to">～</div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 到着予定日(To)-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="deliveryScheduleDateTo"
                :label="$t('label.lbl_deliveryScheduleDateTo')"
                @change="changeDateTo($event, 'delivery')"
                :rules="[
                  rules.inputRequiredCreate(deliveryScheduleDateTo, deliveryScheduleDateFrom),
                  rules.yyyymmdd_create,
                ]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="deliveryAlertMessage"
              ></v-text-field>
            </div>
            <div class="calender-btn-area">
              <v-menu
                v-model="deliveryScheduleDateToMenu"
                :close-on-content-click="false"
                :nudge-right="-200"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="deliveryScheduleDateToCal"
                  @input="deliveryScheduleDateToMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevToDate('delivery')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextToDate('delivery')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 荷札発行状態 -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="shippingTagStatusSelected"
                :items="shippingTagStatusList"
                :label="$t('label.lbl_shippingTag')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
          </v-row>
          <!-- 4段目 -->
          <v-row class="search-row">
            <!-- お客様管理番号 -->
            <div class="add-textbox-remark first-search-item">
              <v-text-field
                outlined
                dense
                v-model="inquiryNumber"
                :label="$t('label.lbl_inquiryNumber')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :rules="[
                  rules.onlyOneInputRequired2(
                    inquiryNumber,
                    freightOrderNumber,
                    lotNumber,
                    invNum,
                    'inquiryNumber'
                  ),
                ]"
              ></v-text-field>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 受注番号 -->
            <div class="search-textbox-voucherNo">
              <v-text-field
                outlined
                dense
                v-model="freightOrderNumber"
                :label="$t('label.lbl_freightOrderNumber')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :rules="[
                  rules.onlyOneInputRequired2(
                    inquiryNumber,
                    freightOrderNumber,
                    lotNumber,
                    invNum,
                    'freightOrderNumber'
                  ),
                ]"
              ></v-text-field>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- ロット番号 -->
            <div class="search-textbox-voucherNo">
              <v-text-field
                outlined
                dense
                v-model="lotNumber"
                :label="$t('label.lbl_lotNumber')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :rules="[
                  rules.onlyOneInputRequired2(
                    inquiryNumber,
                    freightOrderNumber,
                    lotNumber,
                    invNum,
                    'lotNumber'
                  ),
                ]"
              ></v-text-field>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 送り状番号 -->
            <div class="search-textbox-voucherNo">
              <v-text-field
                outlined
                dense
                v-model="invNum"
                :label="$t('label.lbl_invNumSid')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :rules="[
                  rules.onlyOneInputRequired2(
                    inquiryNumber,
                    freightOrderNumber,
                    lotNumber,
                    invNum,
                    'invNum'
                  ),
                ]"
              ></v-text-field>
            </div>
          </v-row>
          <v-row style="padding-top: 10px">
            <!-- 5段目 -->
            <div class="search-row-exeBtn">
              <!--ボタンエリア-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン -->
                <v-btn color="primary" class="api-btn" @click="getApiList(true, true)">{{
                  $t("btn.btn_search")
                }}</v-btn>
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <div class="btn-area" style="float: right">
                <!--CSV出力ボタン-->
                <!-- <v-btn class="other-btn" @click="csvOutput()">
                  {{ $t("btn.btn_transportOrderCsvDown") }}
                </v-btn> -->
                <!--ルート登録ボタン-->
                <v-btn class="other-btn" @click="showRootRegistration()">
                  {{ $t("btn.btn_routeRegistration") }}
                </v-btn>
                <!--送り状発行ボタン-->
                <v-btn class="other-btn" @click="getInvoiceIssue()">
                  {{ $t("btn.btn_invPrint") }}
                </v-btn>
                <!-- 荷札発行ボタン-->
                <v-btn color="primary" class="api-btn" v-on:click="getTagIssue()">
                  {{ $t("btn.btn_shippingTagPrint") }}
                </v-btn>
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row" style="margin-top: 10px; margin-left: 0px">
              <div style="float: left">
                <!-- 一括ボタン-->
                <v-btn color="primary" class="api-btn" v-on:click="batchSelect()">
                  {{ $t("btn.btn_batch") }}
                </v-btn>
                <!-- 空白 -->
                <span class="item-spacer">&nbsp;</span>

                <!--検索結果件数表示-->
                <div class="text-label" style="float: right">
                  <span class="toatlNum">総件数：{{ totalCount }}件</span>
                </div>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--ページング数-->
                <div class="text-label" style="clear: both; float: left">
                  <div>{{ $t("label.lbl_itemsPerPage") }}</div>
                </div>

                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                    mandatory
                  >
                    <v-btn class="pasing-btn" value="100" @click="u_100_on()" small>
                      {{ 100 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="250" @click="u_100_clear(250)" small>
                      {{ 250 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="500" @click="u_100_clear(500)" small>
                      {{ 500 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="columnDefs"
          :items="inputList"
          :page.sync="page"
          :ripple="false"
          :server-items-length="itemsPerPage"
          disable-filtering
          height="413px"
          :options.sync="sortOptions"
          hide-default-header
          hide-default-footer
        >
          <!-- ヘッダー -->
          <template v-slot:header>
            <thead class="v-data-table-header">
              <tr>
                <template v-for="(colDef, colDefIdx) in columnDefs">
                  <th
                    role="columnHeader"
                    scope="col"
                    :class="getColumnClass(colDef, true)"
                    :style="getColumnStyle(colDef)"
                    :key="colDefIdx"
                  >
                    <div v-for="(itemDef, itemDefIdx) in colDef.items" :key="itemDefIdx">
                      <template v-if="itemDef.value == 'check'">
                        <input
                          type="checkbox"
                          :checked="isAllSelected"
                          @click="selectAllCheck"
                          style="transform: scale(2)"
                        />
                      </template>
                      <template v-else>
                        <span>{{ itemDef.text }}</span>
                      </template>
                    </div>
                  </th>
                </template>
              </tr>
            </thead>
          </template>
          <!-- 明細行 -->
          <template v-slot:item="{ item, index }">
            <tr>
              <template v-for="(colDef, colDefIdx) in columnDefs">
                <td :class="getColumnClass(colDef, false)" :key="colDefIdx">
                  <template v-for="(itemDef, itemDefIdx) in colDef.items">
                    <div :class="getColItemClass(colDef, itemDefIdx)" :key="itemDefIdx">
                      <!-- 選択 -->
                      <template v-if="itemDef.value == 'check'">
                        <input
                          type="checkbox"
                          v-model="item.check"
                          :value="listCheckbox"
                          @click="checkRow(item, index)"
                          @blur="blurRowCheck()"
                          style="transform: scale(2)"
                          :key="itemDefIdx"
                        />
                      </template>
                      <!-- 詳細ボタン -->
                      <template v-else-if="itemDef.value == 'detail'">
                        <v-btn style="font-size: 100%" @click="openOrderDetailDialog(item)" text>
                          <div class="text-label" style="color: #75a9ff">
                            <span class="text-label" style="border-bottom: 1px solid #75a9ff">
                              {{ $t("label.lbl_Detail") }}
                            </span>
                          </div>
                        </v-btn>
                      </template>
                      <!-- ロット分割ボタン -->
                      <template v-else-if="itemDef.value == 'lotDivisionBtn'">
                        <v-btn
                          class="other-btn"
                          @click="showlotDivision(item)"
                          :class="lotDivisionButtonVisibility(item)"
                          :disabled="lotDivisionButtonDisabled(item)"
                        >
                          {{ $t("btn.btn_lotDivision") }}
                        </v-btn>
                      </template>
                      <template v-else>
                        <span v-html="showValue(item[itemDef.value])"></span>
                      </template>
                    </div>
                  </template>
                </td>
              </template>
            </tr>
          </template>
        </v-data-table>

        <!-- ページネーション -->
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :thirdPageFlag="infoDialog.thirdPageFlag"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />

      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- CSV出力確認ダイアログ -->
      <CsvOutputDialog
        :isShow.sync="csvOutputDialog.isOpen"
        :message="csvOutputDialog.message"
        :screenFlag="csvOutputDialog.screenFlag"
        :okAction="csvOutputDialog.okAction"
        :redMessage="csvOutputDialog.redMessage"
        :changeFlag="csvOutputDialog.changeFlag"
      />
      <!-- 市区町村ダイアログ -->
      <v-dialog v-model="municipalitiesDialog" :max-width="800">
        <v-card>
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name">{{ $t("label.lbl_selectMunicipality") }}</span>
          </v-card-title>
          <div style="margin-left: 35px; width: 92%">
            <div
              class="text-label"
              style="margin-top: 0px; padding-top: 20px; padding-bottom: 20px"
            >
              {{ $t($data.modalPrefecture) }}
            </div>
            <v-text-field
              outlined
              dense
              v-model="modalMunicipality"
              :label="$t('label.lbl_municipality')"
              class="txt-single"
              clear-icon="mdi-close-circle"
              clearable
              @change="changeMunicipalities"
            ></v-text-field>
            <!--検索結果件数表示-->
            <div class="text-label" style="margin-bottom: 10px">
              <span>総件数：{{ modalTotalCount }}件</span>
            </div>
          </div>
          <!-- 市区町村表示 -->
          <v-simple-table fixed-header id="listData">
            <template v-slot:default>
              <thead>
                <tr>
                  <!-- 市区町村 -->
                  <th style="padding-left: 65px">{{ $t("label.lbl_municipality") }}</th>
                </tr>
              </thead>
              <tbody>
                <v-radio-group
                  style="margin-top: 0px; padding-top: 0px"
                  v-model="modalMunicipalitiesSetValue"
                >
                  <tr
                    v-for="municipality in $data.modalMunicipalitiesNameList"
                    :key="municipality.value"
                  >
                    <!-- ラジオボタン(市区町村名) -->
                    <v-radio
                      :label="$t(municipality.modalMunicipalitiesName)"
                      :value="$t(municipality.value)"
                      style="padding-left: 30px"
                      v-on:change="setCanSet"
                    ></v-radio>
                  </tr>
                </v-radio-group>
              </tbody>
            </template>
          </v-simple-table>
          <template>
            <div style="margin-top: 30px; margin-bottom: 10px">
              <!-- 閉じるボタン-->
              <v-btn color="primary" class="api-btn" @click="closeMunicipalitiesDialog()">
                {{ $t("btn.btn_close") }}
              </v-btn>
              <!-- 設定ボタン-->
              <v-btn
                color="primary"
                class="api-btn"
                style="float: right; margin-right: 12px"
                v-on:click="setMunicipalities"
                v-bind:disabled="canSet"
              >
                {{ $t("btn.btn_config") }}
              </v-btn>
            </div>
          </template>
          <template>
            <span class="item-spacer">&nbsp;</span>
          </template>
        </v-card>
      </v-dialog>
      <!-- 受注詳細ダイアログ -->
      <v-dialog v-model="orderDetailDialog" :max-width="1300">
        <v-card>
          <v-card-title primary-title>
            <!-- 閉じるボタン-->
            <v-btn color="primary" class="api-btn" @click="orderDetailDialog = false">
              {{ $t("btn.btn_close") }}
            </v-btn>
          </v-card-title>

          <!-- 受注明細表示 -->
          <v-simple-table fixed-header class="orderDetailList" id="listData">
            <template v-slot:default>
              <thead>
                <tr>
                  <!-- No -->
                  <th class="text-center" style="min-width: 100px">{{ $t("label.lbl_No") }}</th>
                  <!-- 品名 -->
                  <th class="text-center" style="min-width: 100px">
                    {{ $t("label.lbl_productCodeName") }}
                  </th>
                  <!-- 荷姿 -->
                  <th class="text-center" style="min-width: 100px">
                    {{ $t("label.lbl_packing") }}
                  </th>
                  <!-- 小口数 -->
                  <th class="text-center" style="min-width: 100px">
                    {{ $t("label.lbl_smallLotsQuantity") }}
                  </th>
                  <!-- 重量(kg) -->
                  <th class="text-center" style="min-width: 200px">
                    {{ $t("label.lbl_weight") }}
                  </th>
                  <!-- 縦L(cm) -->
                  <th class="text-center" style="min-width: 200px">
                    {{ $t("label.lbl_verticalLcm") }}
                  </th>
                  <!-- 横W(cm) -->
                  <th class="text-center" style="min-width: 200px">
                    {{ $t("label.lbl_besideLcm") }}
                  </th>
                  <!-- 高H(cm)-->
                  <th class="text-center" style="min-width: 200px">
                    {{ $t("label.lbl_heightLcm") }}
                  </th>
                  <!-- 容量(㎥) -->
                  <th class="text-center" style="min-width: 200px">
                    {{ $t("label.lbl_capacitym3") }}
                  </th>
                  <!-- 重量計(kg) -->
                  <th class="text-center" style="min-width: 200px">
                    {{ $t("label.lbl_totalWeight") }}
                  </th>
                  <!-- 換算重量計(kg) -->
                  <th class="text-center" style="min-width: 200px">
                    {{ $t("label.lbl_conversionTotalWeight") }}
                  </th>
                  <!-- 輸送要件 -->
                  <th class="text-center" style="min-width: 200px">
                    {{ $t("label.lbl_transportRequirement") }}
                  </th>
                  <!-- 備考 -->
                  <th class="text-center" style="min-width: 380px">
                    {{ $t("label.lbl_remarks") }}
                  </th>
                  <!-- 換算重量(kg)-->
                  <th class="text-center" style="min-width: 200px">
                    {{ $t("label.lbl_conversionWeight") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="od in $data.modalOrderDetailsList" :key="od.modalNo">
                  <!-- No -->
                  <td class="text-center">{{ od.modalNo }}</td>
                  <!-- 品名 -->
                  <td class="text-center">{{ od.modalProductCodeName }}</td>
                  <!-- 荷姿 -->
                  <td class="text-center">{{ od.modalPacking }}</td>
                  <!-- 小口数 -->
                  <td class="text-center">{{ od.modalSmallLotsQuantity }}</td>
                  <!-- 重量(kg) -->
                  <td class="text-center">{{ od.modalWeight }}</td>
                  <!-- 縦L(cm) -->
                  <td class="text-center">{{ od.modalVerticalLcm }}</td>
                  <!-- 横W(cm) -->
                  <td class="text-center">{{ od.modalBesideLcm }}</td>
                  <!-- 高H(cm) -->
                  <td class="text-center">{{ od.modalHeightLcm }}</td>
                  <!-- 容量(㎥) -->
                  <td class="text-center">{{ od.modalCapacitym3 }}</td>
                  <!-- 重量計(kg) -->
                  <td class="text-center">{{ od.modalTotalWeight }}</td>
                  <!-- 換算重量計(kg) -->
                  <td class="text-center">{{ od.modalConversionTotalWeight }}</td>
                  <!-- 輸送要件 -->
                  <td class="text-center">{{ od.modalTransportRequirementArrow }}</td>
                  <!-- 備考 -->
                  <td class="text-center">{{ od.modalRemarks }}</td>
                  <!-- 換算重量(kg) -->
                  <td class="text-center">{{ od.modalConversionWeight }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import ConfirmDialog from "@/components/ConfirmDialog";
import CsvOutputDialog from "@/components/ConfirmDialog";
import NavBar from "../../components/NavBar.vue";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { i18n } from "../../lang/lang.js";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.MENU_ID.P_TOD_LIST,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    CsvOutputDialog,
    NavBar,
  },
  mixins: [commonMixin, commonRules],
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    sortItem: "",
    sortOptions: {},
    u_100: false,
    // 取引先
    suppliersSelected: "",
    supplierList: [],
    supplierHint: "",
    // 発注日
    orderDateFrom: "",
    orderDateFromMenu: false,
    orderDateFromCal: "",
    orderDateTo: "",
    orderDateToMenu: false,
    orderDateToCal: "",
    // 担当営業所
    officeSelected: "",
    officeList: [],
    productHintOffice: "",
    // ルート登録
    routeRegistrationDivSelected: "",
    routeRegistrationList: [],
    // 配車計画状況
    // pickupStatusDiv: "01",
    // deliveryStatusDiv: "01",
    pickupDispatchStatusDivList: [],
    deliveryDispatchStatusDivList: [],
    // 出荷元
    pickupPointSelected: "",
    pickupPointCd: "",
    pickupPointSid: "",
    pickupPointList: [],
    productHintPickup: "",
    // 都道府県リスト
    prefecturesList: [],
    // 都道府県(出荷元)
    pickupPointprefecturesSelected: "",
    // 都道府県(出荷元)名称
    pickupPointPrefecturesSelectedName: "",
    // 市区町村(出荷元)
    pickupMunicipalities: "",
    // 市区町村名(出荷元)
    pickupMunicipalitiesName: "",
    // 集荷区分
    pickupDivSelected: "",
    pickupDivList: [],
    // 集荷予定日
    pickupScheduleDateFrom: "",
    pickupScheduleDateFromCal: "",
    pickupScheduleDateFromMenu: false,
    pickupScheduleDateTo: "",
    pickupScheduleDateToCal: "",
    pickupScheduleDateToMenu: false,
    // 送り状
    invNumStatusSelected: "",
    invNumStatusList: [],
    // 配達先
    deliveryPointSelected: "",
    deliveryPointList: [],
    deliveryPointCd: "",
    deliveryPointSid: "",
    // 都道府県(お届け先)
    deliveryPointprefecturesSelected: "",
    // 都道府県(お届け先)名称
    deliveryPointPrefecturesSelectedName: "",
    // 市区町村(お届け先)
    deliveryMunicipalities: "",
    // 市区町村名(お届け先)
    deliveryMunicipalitiesName: "",
    // 到着予定日
    deliveryScheduleDateFrom: "",
    deliveryScheduleDateFromCal: "",
    deliveryScheduleDateFromMenu: false,
    deliveryScheduleDateTo: "",
    deliveryScheduleDateToCal: "",
    deliveryScheduleDateToMenu: false,
    // 荷札
    shippingTagStatusSelected: "",
    shippingTagStatusList: [],
    // お客様管理番号
    inquiryNumber: "",
    // 受注番号
    freightOrderNumber: "",
    // ロット番号
    lotNumber: "",
    // 送り状番号
    invNum: "",
    // 表示件数
    totalCount: "",
    // ページング
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    // 表示件数切替ボタン
    itemsPerPageBtn: "100",
    inputList: [],
    // 一覧選択チェックボックス
    listCheckbox: [],
    // 初期データ
    defaultData: [],
    // テーブルの列定義
    columnDefs: [
      // 選択
      {
        items: [
          {
            text: "選択",
            value: "check",
          },
        ],
        width: "50px",
        align: "center",
      },
      // No
      {
        items: [
          {
            text: "No",
            value: "No",
          },
        ],
        width: "60px",
        align: "center",
      },
      // 受注日・受注番号
      {
        items: [
          {
            text: i18n.tc("label.lbl_orderDate"),
            value: "orderDate",
          },
          {
            text: i18n.tc("label.lbl_freightOrderNumber"),
            value: "freightOrderNumber",
          },
        ],
        width: "150px",
        align: "center",
      },
      // 取引先・エリア
      {
        items: [
          {
            text: i18n.tc("label.lbl_supplier"),
            value: "supplier",
          },
          {
            text: i18n.tc("label.lbl_area"),
            value: "area",
          },
        ],
        minWidth: "200px",
        align: "center",
      },
      // 出荷元・お届け先
      {
        items: [
          {
            text: i18n.tc("label.lbl_pickupPoint"),
            value: "pickupPoint",
          },
          {
            text: i18n.tc("label.lbl_addressee"),
            value: "deliveryPoint",
          },
        ],
        minWidth: "200px",
        align: "center",
      },
      // 集荷予定日・到着日
      {
        items: [
          {
            text: i18n.tc("label.lbl_pickupScheduleDate"),
            value: "pickupScheduleDate",
          },
          {
            text: i18n.tc("label.lbl_deliveryScheduleDate"),
            value: "deliveryScheduleDate",
          },
        ],
        minWidth: "140px",
        align: "center",
      },
      // 集荷予定時間・到着時間
      {
        items: [
          {
            text: i18n.tc("label.lbl_pickupScheduleTime"),
            value: "pickupScheduleTime",
          },
          {
            text: i18n.tc("label.lbl_deliveryScheduleTime"),
            value: "deliveryScheduleTime",
          },
        ],
        minWidth: "160px",
        align: "center",
      },
      // ルート設定・配車状況
      {
        items: [
          {
            text: i18n.tc("label.lbl_rootStatusDiv"),
            value: "rootStatusDiv",
          },
          {
            text: i18n.tc("label.lbl_deliveryStatusDiv"),
            value: "deliveryStatusDiv",
          },
        ],
        minWidth: "160px",
        align: "center",
      },
      // 詳細・ロット分割
      {
        items: [
          {
            text: i18n.tc("label.lbl_Detail"),
            value: "detail",
          },
          {
            text: i18n.tc("label.lbl_lotDivision"),
            value: "lotDivisionBtn",
          },
        ],
        minWidth: "200px",
        align: "center",
      },
      // 品名
      {
        items: [
          {
            text: i18n.tc("label.lbl_productCodeName"),
            value: "representativeProductsName",
          },
        ],
        align: "center",
        minWidth: "350px",
        maxWidth: "800px",
      },
      // 小口数・重量計KG
      {
        items: [
          {
            text: i18n.tc("label.lbl_smallLotsQuantity"),
            value: "smallLotQuantity",
          },
          {
            text: i18n.tc("label.lbl_totalWeight"),
            value: "weight",
          },
        ],
        minWidth: "120px",
        align: "center",
      },
      // 元請営業所・担当営業所
      {
        items: [
          {
            text: i18n.tc("label.lbl_primeContractor"),
            value: "primeContractor",
          },
          {
            text: i18n.tc("label.lbl_transportOffice"),
            value: "officeSelected",
          },
        ],
        minWidth: "200px",
        align: "center",
      },
      // お客様管理番号・送り状番号
      {
        items: [
          {
            text: i18n.tc("label.lbl_inquiryNumber"),
            value: "inquiryNum",
          },
          {
            text: i18n.tc("label.lbl_invNumSid"),
            value: "invNumSid",
          },
        ],
        minWidth: "250px",
        align: "center",
      },
      // 送り状発行時間
      {
        items: [
          {
            text: i18n.tc("label.lbl_invoicePrintDate"),
            value: "invoicePrintDate",
          },
        ],
        minWidth: "250px",
        align: "center",
      },
      // 荷札発行時間
      {
        items: [
          {
            text: i18n.tc("label.lbl_taglabelPrintDate"),
            value: "taglabelPrintDate",
          },
        ],
        minWidth: "250px",
        align: "center",
      },
      // ロット番号
      {
        items: [
          {
            text: i18n.tc("label.lbl_lotNumber"),
            value: "lotNumber",
          },
        ],
        minWidth: "250px",
        align: "center",
      },
    ],
    municipalitiesDialogheaderItems: [
      // 市区町村
      {
        text: i18n.tc("label.lbl_municipality"),
        value: "modalMunicipalitiesName",
        width: "100%",
        align: "left",
        sortable: false,
      },
    ],

    // メニュー
    openMenu: null,
    supplierInput: "",
    getOfficeSelected: "",
    getPickupSelected: "",
    getDeliverySelected: "",
    // 全選択チェックボックス
    isAllSelected: false,
    updateDialogMessage: "",
    backMessage: "",
    searchParam: {},

    // 市区町村ダイアログ
    municipalitiesDialog: false,

    // 市区町村ダイアログ 都道府県
    modalPrefecture: "",
    // 市区町村ダイアログ 市区町村
    modalMunicipality: "",
    // 市区町村ダイアログ 総件数
    modalTotalCount: "",
    // 市区町村ダイアログ 検索結果
    baseMunicipalitiesNameList: [],
    // 市区町村ダイアログ 表示データ
    modalMunicipalitiesNameList: [],
    // 市区町村ダイアログ呼び出し元
    modalMunicipalitiesCaller: "",
    // 市区町村ダイアログ 設定値
    modalMunicipalitiesSetValue: "",
    // 市区町村ダイアログ キーワード
    modalMunicipalitiesKeyword: "",
    // 市区町村ダイアログ 設定ボタン 活性化設定
    canSet: true,

    // 受注詳細ダイアログ
    orderDetailDialog: false,
    // 詳細ダイアログ 表示データ
    modalOrderDetailsList: [],
    // 編集用チェックボックスリスト
    chengeListCheckbox: [],
    listCheckboxBtn: [],

    // バリデーションチェックメッセージダイアログ
    alertMessage: "",
    pickupAlertMessage: "",
    deliveryAlertMessage: "",
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      thirdPageFlag: false,
    },
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    csvOutputDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
  }),

  created() {
    if (this.$route.params.searchParam) {
      this.searchParam = this.$route.params.searchParam;

      this.suppliersSelected = this.searchParam.suppliersSelected;
      this.orderDateFrom = this.searchParam.orderDateFrom;
      this.orderDateTo = this.searchParam.orderDateTo;
      this.officeSelected = this.searchParam.officeSelected;
      this.routeRegistrationDivSelected = this.searchParam.routeRegistrationDivSelected;
      this.pickupPointSelected = this.searchParam.pickupPointSelected;
      this.pickupPointprefecturesSelected = this.searchParam.pickupPointprefecturesSelected;
      this.pickupMunicipalitiesName = this.searchParam.pickupMunicipalitiesName;
      this.pickupScheduleDateFrom = this.searchParam.pickupScheduleDateFrom;
      this.pickupScheduleDateTo = this.searchParam.pickupScheduleDateTo;
      this.invNumStatusSelected = this.searchParam.invNumStatusSelected;
      this.deliveryPointSelected = this.searchParam.deliveryPointSelected;
      this.deliveryPointprefecturesSelected = this.searchParam.deliveryPointprefecturesSelected;
      this.deliveryMunicipalitiesName = this.searchParam.deliveryMunicipalitiesName;
      this.deliveryScheduleDateFrom = this.searchParam.deliveryScheduleDateFrom;
      this.deliveryScheduleDateTo = this.searchParam.deliveryScheduleDateTo;
      this.shippingTagStatusSelected = this.searchParam.shippingTagStatusSelected;
      this.inquiryNumber = this.searchParam.inquiryNumber;
      this.freightOrderNumber = this.searchParam.freightOrderNumber;
      this.lotNumber = this.searchParam.lotNumber;
      this.invNum = this.searchParam.invNum;

      this.pickupPointCd = this.searchParam.pickupPointCd;
      this.deliveryPointCd = this.searchParam.deliveryPointCd;

      this.supplierHint = this.searchParam.supplierHint;
      this.productHintOffice = this.searchParam.productHintOffice;
      this.productHintPickup = this.searchParam.productHintPickup;
      this.productHintDelivery = this.searchParam.productHintDelivery;

      this.pickupMunicipalities = this.searchParam.pickupMunicipalities;
      this.deliveryMunicipalities = this.searchParam.deliveryMunicipalities;

      this.supplierList = this.searchParam.supplierList;
      this.officeList = this.searchParam.officeList;
      this.pickupPointList = this.searchParam.pickupPointList;
      this.deliveryPointList = this.searchParam.deliveryPointList;

      this.orderDateFromCal = this.dateCalc(this.orderDateFrom);
      this.orderDateToCal = this.dateCalc(this.orderDateTo);
      this.pickupScheduleDateFromCal = this.dateCalc(this.pickupScheduleDateFrom);
      this.pickupScheduleDateToCal = this.dateCalc(this.pickupScheduleDateTo);
      this.deliveryScheduleDateFromCal = this.dateCalc(this.deliveryScheduleDateFrom);
      this.deliveryScheduleDateToCal = this.dateCalc(this.deliveryScheduleDateTo);

      this.getApiList(true, true);
    }
  },

  methods: {
    link() {
      location.href = this.returnURL;
    },
    u_100_on() {
      this.u_100 = true;
      this.itemsPerPage = 100;
      this.page = 1;
      this.getApiList(false, false);
    },
    u_100_clear(val) {
      this.u_100 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.getApiList(false, false);
    },

    /**
     * 営業所別ロット組（荷繰り）登録画面：全選択チェックボックス処理
     */
    selectAllCheck() {
      if (!this.isAllSelected) {
        this.listCheckbox = [];
        this.listCheckbox = true;
        this.isAllSelected = true;
        for (var i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = true;
          this.listCheckboxBtn.push(this.inputList[i]);
        }
      } else {
        this.listCheckbox = [];
        this.listCheckbox = false;
        for (var j = 0; j < this.inputList.length; j++) {
          this.inputList[j].check = false;

          // 画面遷移用リストを初期化
          this.listCheckboxBtn = [];
        }
        this.isAllSelected = false;
      }
    },

    /**
     * 初期データ取得
     */
    getCustomInfo(val) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // ------- ↓↓共通処理が完成次第、修正↓↓-----------
      const params = {};

      if (isNaN(val)) {
        // 取引先名称
        params.supplierName = val;
      } else {
        // 取引先コード（前方一致）
        params.supplierCode = val;
      }

      const clientList = getParameter.getTmsBizCustomerMst(params);
      // const clientList = [
      //   {
      //     value: "4400710",
      //     text: "4400710（ボルボ・カー・ジャパン㈱埼玉サービスセンター）",
      //     name: "ボルボ・カー・ジャパン㈱埼玉サービスセンター",
      //   },
      // ];
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];

          if (this.$route.params.searchSuppliersSelected) {
            // this.getSuppliersSelected();
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // ------- ↓↓共通処理が完成次第、修正↓↓-----------
      // ルート登録
      const routeRegistrationList = getParameter.getTmsCodeMst({
        codeType: appConfig.CODETYPE.DONE_DIV,
      });
      // 送り状
      const invNumStatusList = getParameter.getTmsCodeMst({
        codeType: appConfig.CODETYPE.ISSUE_DIV,
      });
      // 荷札
      const shippingTagStatusList = getParameter.getTmsCodeMst({
        codeType: appConfig.CODETYPE.ISSUE_DIV,
      });
      // 都道府県
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.procKbn = "01";
      // let prefecturesList = [];
      const prefecturesList = new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_POSTCODE_SEARCH, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // const jsonData = {
            //   resCom: {
            //     resComPagination: {
            //       totalPage: 1,
            //       totalRecord: 100,
            //     },
            //     resComCode: "000",
            //   },
            //   reqCom: {
            //     reqComComponentId: "P-TOD-002",
            //     reqComCompanySid: null,
            //     reqComOfficeSid: null,
            //     reqComDeviceImei: "WEB",
            //     reqComAppVersion: "24.11.01",
            //     reqComExecUser: null,
            //     reqComExecTimestamp: "2025/01/22 04:34:59.192",
            //     reqComRequestId: "9854fab4-ce0d-431d-a637-9c2db5a27fc6",
            //     reqComLanguageCd: "ja",
            //     reqComTimeZone: "Asia/Tokyo",
            //   },
            //   resIdv: {
            //     postCodeList: [
            //       {
            //         postCodeSid: "",
            //         localGrovernmentPrefectureCd: "01",
            //         localGrovernmentCd: "",
            //         postCode: "",
            //         prefectureName: "北海道",
            //         prefectureKana: "ホッカイドウ",
            //         cityName: "",
            //         cityKana: "",
            //         townAreaName: "",
            //         townAreaKana: "",
            //       },
            //       {
            //         postCodeSid: "",
            //         localGrovernmentPrefectureCd: "03",
            //         localGrovernmentCd: "",
            //         postCode: "",
            //         prefectureName: "岩手県",
            //         prefectureKana: "イワテケン",
            //         cityName: "",
            //         cityKana: "",
            //         townAreaName: "",
            //         townAreaKana: "",
            //       },
            //       {
            //         postCodeSid: "",
            //         localGrovernmentPrefectureCd: "05",
            //         localGrovernmentCd: "",
            //         postCode: "",
            //         prefectureName: "秋田県",
            //         prefectureKana: "アキタケン",
            //         cityName: "",
            //         cityKana: "",
            //         townAreaName: "",
            //         townAreaKana: "",
            //       },
            //     ],
            //   },
            // };
            // console.debug("GetParameter getPrefecture Response", response, jsonData);
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.postCodeList.forEach((row) => {
                list.push({
                  value: row.localGrovernmentPrefectureCd,
                  text: row.prefectureName,
                  name: row.prefectureName,
                });
              });
              // prefecturesList = list;
              resolve(list);
            } else {
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((ex) => {
            console.error("GetParameter getPrefecture Err", ex);
            reject(messsageUtil.getMessage("P-999-999_999_E"));
          });
      });

      // ルート登録
      // const routeRegistrationList = [
      //   {
      //     text: "未",
      //     value: "0",
      //   },
      //   {
      //     text: "済",
      //     value: "1",
      //   },
      // ];

      // // 送り状
      // const invNumStatusList = [
      //   {
      //     text: "未発行",
      //     value: "0",
      //   },
      //   {
      //     text: "発行済",
      //     value: "1",
      //   },
      // ];

      // 荷札
      // const shippingTagStatusList = [
      //   {
      //     text: "未発行",
      //     value: "0",
      //   },
      //   {
      //     text: "発行済",
      //     value: "1",
      //   },
      // ];
      // const prefecturesList = [
      //   {
      //     text: "北海道",
      //     value: "01",
      //   },
      //   {
      //     text: "岩手県",
      //     value: "03",
      //   },
      //   {
      //     text: "秋田県",
      //     value: "05",
      //   },
      // ];

      Promise.all([routeRegistrationList, invNumStatusList, shippingTagStatusList, prefecturesList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.routeRegistrationList = result[0];
          this.invNumStatusList = result[1];
          this.shippingTagStatusList = result[2];
          this.prefecturesList = result[3];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 事業所・営業所マスタ取得
     */
    getMstOffice(params) {
      console.log("params: " + params);
      const config = this.$httpClient.createGetApiRequestConfig();
      Object.assign(config.params, params);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_OFFICE_SEARCH, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // const jsonData = {
            //   resCom: {
            //     resComPagination: {
            //       totalPage: 1,
            //       totalRecord: 100,
            //     },
            //     resComCode: "000",
            //   },
            //   reqCom: {
            //     reqComComponentId: "P-TOD-002",
            //     reqComCompanySid: null,
            //     reqComOfficeSid: null,
            //     reqComDeviceImei: "WEB",
            //     reqComAppVersion: "24.11.01",
            //     reqComExecUser: null,
            //     reqComExecTimestamp: "2025/01/22 04:34:59.192",
            //     reqComRequestId: "9854fab4-ce0d-431d-a637-9c2db5a27fc6",
            //     reqComLanguageCd: "ja",
            //     reqComTimeZone: "Asia/Tokyo",
            //   },
            //   resIdv: {
            //     timeZone: "Asia/Tokyo",
            //     officeInfoList: [
            //       {
            //         officeSid: "2100000019",
            //         officeCode: "019",
            //         officeName: "日高営業所",
            //         officeNameKana: "ヒダカエイギョウショ",
            //         officeShortName: "日高営業所",
            //         officeShortNameKana: "ヒダカエイギョウショ",
            //         postCode: "3501213",
            //         address1: "埼玉県日高市",
            //         address2: "高萩995-1",
            //         telephoneNumber: "042-985-1972",
            //         telephoneNumber2: null,
            //         faxNumber: "042-985-1973",
            //         updateDatetime: "2024/07/18 15:00:00",
            //       },
            //     ],
            //   },
            // };
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              let list = [];
              jsonData.resIdv.officeInfoList.forEach((row) => {
                list.push({
                  text: `${row.officeCode ?? ""} ` + `（${row.officeName ?? ""}）`,
                  value: row.officeSid ?? "",
                  name: row.officeName ?? "",
                });
              });
              resolve(list);
            } else {
              // エラー時
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((resolve) => {
            console.error("transportList getMstOffice Err", resolve);
            reject(messsageUtil.getMessage("P-999-999_999_E"));
          });
      });
    },

    /**
     * 営業所別ロット組（荷繰り）登録画面： 検索ボタン押下処理
     */
    getApiList(checkBoxFlag, isSearchConditionUpdate) {
      // 入力チェック結果フラグ
      let inputCheckResult = false;
      let check = 0;
      // メッセージ初期化
      this.alertMessage = "";
      this.pickupAlertMessage = "";
      this.deliveryAlertMessage = "";
      // 受注日FromToの日付、入力チェック
      if (new Date(this.orderDateFrom).getTime() > new Date(this.orderDateTo).getTime()) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
        check++;
      }
      // 集荷予定日FromToの日付、入力チェック
      if (this.pickupScheduleDateFrom && this.pickupScheduleDateTo) {
        if (
          new Date(this.pickupScheduleDateFrom).getTime() >
          new Date(this.pickupScheduleDateTo).getTime()
        ) {
          this.pickupAlertMessage = i18n.tc("check.chk_inputFromVali");
          check++;
        }
      }
      // 到着日FromToの日付、入力チェック
      if (this.deliveryScheduleDateFrom && this.deliveryScheduleDateTo) {
        if (
          // FromToの日付、入力チェック
          new Date(this.deliveryScheduleDateFrom).getTime() >
          new Date(this.deliveryScheduleDateTo).getTime()
        ) {
          this.deliveryAlertMessage = i18n.tc("check.chk_inputFromVali");
          check++;
        }
      }

      if (check == 0) {
        /**
         * 登録画面からの遷移の場合、入力チェックを実施するとvalidateが定義されていないと
         * エラーがでるので回避するためこのようなコードとなっている。
         */
        if (this.$route.params.searchParam) {
          // 登録画面からの遷移の場合、入力チェックは実施しない。
          inputCheckResult = true;
        } else {
          // 上記外の場合はチェックを行う。
          inputCheckResult = this.$refs.form.validate();
        }

        if (inputCheckResult) {
          // バリデーションエラーメッセージを非表示
          this.alertMessage = "";
          this.pickupAlertMessage = "";
          this.deliveryAlertMessage = "";

          // 初期検索かページング検索か判断
          if (checkBoxFlag) {
            this.isAllSelected = false;
          }
          // 検索条件を保存
          this.searchParam.suppliersSelected = this.suppliersSelected;
          this.searchParam.orderDateFrom = this.orderDateFrom;
          this.searchParam.orderDateTo = this.orderDateTo;
          this.searchParam.officeSelected = this.officeSelected;
          this.searchParam.routeRegistrationDivSelected = this.routeRegistrationDivSelected;
          this.searchParam.pickupPointSelected = this.pickupPointSelected;
          this.searchParam.pickupPointprefecturesSelected = this.pickupPointprefecturesSelected;
          this.searchParam.pickupMunicipalitiesName = this.pickupMunicipalitiesName;
          this.searchParam.pickupScheduleDateFrom = this.pickupScheduleDateFrom;
          this.searchParam.pickupScheduleDateTo = this.pickupScheduleDateTo;
          this.searchParam.invNumStatusSelected = this.invNumStatusSelected;
          this.searchParam.deliveryPointSelected = this.deliveryPointSelected;
          this.searchParam.deliveryPointprefecturesSelected = this.deliveryPointprefecturesSelected;
          this.searchParam.deliveryMunicipalitiesName = this.deliveryMunicipalitiesName;
          this.searchParam.deliveryScheduleDateFrom = this.deliveryScheduleDateFrom;
          this.searchParam.deliveryScheduleDateTo = this.deliveryScheduleDateTo;
          this.searchParam.shippingTagStatusSelected = this.shippingTagStatusSelected;
          this.searchParam.inquiryNumber = this.inquiryNumber;
          this.searchParam.freightOrderNumber = this.freightOrderNumber;
          this.searchParam.lotNumber = this.lotNumber;
          this.searchParam.invNum = this.invNum;

          this.searchParam.pickupPointCd = this.pickupPointCd;
          this.searchParam.deliveryPointCd = this.deliveryPointCd;

          this.searchParam.supplierHint = this.supplierHint;
          this.searchParam.productHintOffice = this.productHintOffice;
          this.searchParam.productHintPickup = this.productHintPickup;
          this.searchParam.productHintDelivery = this.productHintDelivery;

          this.searchParam.pickupMunicipalities = this.pickupMunicipalities;
          this.searchParam.deliveryMunicipalities = this.deliveryMunicipalities;

          this.searchParam.supplierList = this.supplierList;
          this.searchParam.officeList = this.officeList;
          this.searchParam.pickupPointList = this.pickupPointList;
          this.searchParam.deliveryPointList = this.deliveryPointList;

          // ローディング画面表示ON
          this.loadingCounter = 1;

          // httpClientで使用する共通IFパラメータ等を作成する。
          const config = this.$httpClient.createGetApiRequestConfig();
          // 取引先
          if (this.suppliersSelected) {
            config.params.suppliersSelected = this.suppliersSelected;
          }
          // 受注日From
          if (this.orderDateFrom) {
            config.params.orderDateFrom = dateTimeHelper.convertUTC(this.orderDateFrom);
          }
          // 受注日To
          if (this.orderDateTo) {
            config.params.orderDateTo = dateTimeHelper.convertUTC(
              this.orderDateTo + " 23:59:59.999"
            );
          }
          // 担当営業所SID
          if (this.officeSelected) {
            config.params.officeSelected = this.officeSelected;
          }
          // ルート登録
          if (this.routeRegistrationDivSelected) {
            config.params.routeRegistrationDiv = this.routeRegistrationDivSelected;
          }
          // 集荷状態区分
          // config.params.pickupStatusDiv = this.pickupStatusDiv;
          // // 配達状態区分
          // config.params.deliveryStatusDiv = this.deliveryStatusDiv;
          // 集荷拠点取引先CD
          if (this.pickupPointCd) {
            config.params.pickupPointCd = this.pickupPointCd;
          }
          // 集荷拠点SID
          if (this.pickupPointSid) {
            config.params.pickupPointSid = this.pickupPointSid;
          } else if (this.pickupPointSelected) {
            config.params.pickupPointSid = this.pickupPointSelected;
          }
          // 都道府県(出荷元)
          if (this.pickupPointprefecturesSelected) {
            config.params.pickupPointprefecturesCd = this.pickupPointprefecturesSelected;
          }
          // 市区町村(出荷元)
          if (this.pickupMunicipalities) {
            config.params.pickupMunicipalitiesCd = this.pickupMunicipalities;
          }
          // 集荷予定日From
          if (this.pickupScheduleDateFrom) {
            config.params.pickupScheduleDateFrom = dateTimeHelper.convertUTC(
              this.pickupScheduleDateFrom
            );
          }
          // 集荷予定日To
          if (this.pickupScheduleDateTo) {
            config.params.pickupScheduleDateTo = dateTimeHelper.convertUTC(
              this.pickupScheduleDateTo + " 23:59:59.999"
            );
          }
          // 送り状
          if (this.invNumStatusSelected) {
            config.params.invNumStatusSelected = this.invNumStatusSelected;
          }
          // 配達拠点取引先CD
          if (this.deliveryPointCd) {
            config.params.deliveryPointCd = this.deliveryPointCd;
          }
          // 配達拠点SID
          if (this.deliveryPointSid) {
            config.params.deliveryPointSid = this.deliveryPointSid;
          } else if (this.deliveryPointSelected) {
            config.params.deliveryPointSid = this.deliveryPointSelected;
          }
          // 都道府県(お届け先)
          if (this.deliveryPointprefecturesSelected) {
            config.params.deliveryPointprefecturesCd = this.deliveryPointprefecturesSelected;
          }
          // 市区町村(お届け先)
          if (this.deliveryMunicipalities) {
            config.params.deliveryMunicipalitiesCd = this.deliveryMunicipalities;
          }
          // 到着日From
          if (this.deliveryScheduleDateFrom) {
            config.params.deliveryScheduleDateFrom = dateTimeHelper.convertUTC(
              this.deliveryScheduleDateFrom
            );
          }
          // 到着日To
          if (this.deliveryScheduleDateTo) {
            config.params.deliveryScheduleDateTo = dateTimeHelper.convertUTC(
              this.deliveryScheduleDateTo + " 23:59:59.999"
            );
          }
          // 荷札
          if (this.shippingTagStatusSelected) {
            config.params.shippingTagStatusSelected = this.shippingTagStatusSelected;
          }
          // 受注番号
          if (this.freightOrderNumber) {
            config.params.freightOrderNumber = this.freightOrderNumber;
          }
          // お客様管理番号
          if (this.inquiryNumber) {
            config.params.inquiryNumber = this.inquiryNumber;
          }
          // ロット番号
          if (this.lotNumber) {
            config.params.lotNumber = this.lotNumber;
          }
          // 送り状SID
          if (this.invNum) {
            config.params.invNum = this.invNum;
          }

          // ページャー処理
          if ((this.page >= 2 && this.inputList.length == 0) || isSearchConditionUpdate) {
            config.params.reqComPageIndex = "1";
            this.page = 1;
          } else {
            config.params.reqComPageIndex = this.page;
          }
          // ページング
          config.params.reqComPaginationFlg = "1";
          config.params.reqComPageLimit = this.itemsPerPage;

          if (this.sortItem != "") {
            config.params.reqComSortItem = this.sortItem;
          }
          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .secureGet(appConfig.API_URL.BIZ_TRANSPORT_SEARCH, config)
              .then((response) => {
                // console.debug("getApiList(checkBoxFlag) Response", response);
                const jsonData = JSON.parse(JSON.stringify(response.data));
                // const jsonData = {
                //   resCom: {
                //     resComPagination: {
                //       totalPage: 1,
                //       totalRecord: 100,
                //     },
                //     resComCode: "000",
                //   },
                //   reqCom: {
                //     reqComComponentId: "P-TOD-002",
                //     reqComCompanySid: null,
                //     reqComOfficeSid: null,
                //     reqComDeviceImei: "WEB",
                //     reqComAppVersion: "24.11.01",
                //     reqComExecUser: null,
                //     reqComExecTimestamp: "2025/01/22 04:34:59.192",
                //     reqComRequestId: "9854fab4-ce0d-431d-a637-9c2db5a27fc6",
                //     reqComLanguageCd: "ja",
                //     reqComTimeZone: "Asia/Tokyo",
                //   },
                //   resIdv: {
                //     timeZone: "Asia/Tokyo",
                //     orders: [
                //       {
                //         orderDate: "2024/07/10 00:00:00.000",
                //         freightOrderNumber: "2400000001",
                //         supplierCd: "4135200",
                //         supplier: "ダイハツ",
                //         area: "埼玉県狭山市",
                //         officeSid: "S000001630",
                //         businessNo: "00001",
                //         pickupWayDiv: "01",
                //         pickupBaseSid: "2100000141",
                //         pickupPoint: "智光山",
                //         pickupScheduleDate: "2024/07/11 00:00:00.000",
                //         pickupScheduleTime: "11:00 - 12:00",
                //         pickupScheduleTimeFrom: "2024/07/11 11:00:00.000",
                //         pickupScheduleTimeTo: "2024/07/11 12:00:00.000",
                //         deliveryWayDiv: "01",
                //         deliveryBaseSid: "S000001687",
                //         deliveryPoint: "柏原営業所",
                //         deliveryScheduleDate: "2024/07/12 00:00:00.000",
                //         deliveryScheduleTime: "17:00 - 18:00",
                //         deliveryScheduleTimeFrom: "2024/07/12 17:00:00.000",
                //         deliveryScheduleTimeTo: "2024/07/12 18:00:00.000",
                //         rootStatusDiv: "済",
                //         deliveryStatusDiv: "配車済",
                //         representativeProductsName: "ｺﾍﾟﾝ,ﾀﾝﾄ,付属品",
                //         smallLotsQuantity: "4",
                //         weight: "3100",
                //         primeContractor: "日高営業所",
                //         officeSelected: "埼玉営業所",
                //         inquiryNumber: "555501",
                //         invNumSid: "99000000001",
                //         invoicePrintDate: "2024/11/02 15:43:12",
                //         taglabelPrintDate: "2024/11/02 20:43:12",
                //         lotNumber: "2500000001",
                //         updateDateTime: "2024/07/10 17:00:00.000",
                //       },
                //       {
                //         orderDate: "2024/07/10 00:00:00.000",
                //         freightOrderNumber: "2400000001",
                //         supplierCd: "4135200",
                //         supplier: "ダイハツ",
                //         area: "埼玉県狭山市",
                //         officeSid: "S000001630",
                //         businessNo: "00001",
                //         pickupWayDiv: "01",
                //         pickupBaseSid: "2100000141",
                //         pickupPoint: "智光山",
                //         pickupScheduleDate: "2024/07/11 00:00:00.000",
                //         pickupScheduleTime: "11:00 - 12:00",
                //         pickupScheduleTimeFrom: "2024/07/11 11:00:00.000",
                //         pickupScheduleTimeTo: "2024/07/11 12:00:00.000",
                //         deliveryWayDiv: "01",
                //         deliveryBaseSid: "S000001687",
                //         deliveryPoint: "柏原営業所",
                //         deliveryScheduleDate: "2024/07/12 00:00:00.000",
                //         deliveryScheduleTime: "17:00 - 18:00",
                //         deliveryScheduleTimeFrom: "2024/07/12 17:00:00.000",
                //         deliveryScheduleTimeTo: "2024/07/12 18:00:00.000",
                //         rootStatusDiv: "未",
                //         deliveryStatusDiv: "未配車",
                //         representativeProductsName: "ｺﾍﾟﾝ,ﾀﾝﾄ,付属品",
                //         smallLotsQuantity: "4",
                //         weight: "3100",
                //         primeContractor: "日高営業所",
                //         officeSelected: "埼玉営業所",
                //         inquiryNumber: "555501",
                //         invNumSid: "99000000002",
                //         invoicePrintDate: "2024/11/02 15:43:12",
                //         taglabelPrintDate: "2024/11/02 20:43:12",
                //         lotNumber: "2500000001",
                //         updateDateTime: "2024/07/10 17:00:00.000",
                //       },
                //       {
                //         orderDate: "2024/07/10 00:00:00.000",
                //         freightOrderNumber: "2400000001",
                //         supplierCd: "4135200",
                //         supplier: "ダイハツ",
                //         area: "埼玉県狭山市",
                //         officeSid: "S000001630",
                //         businessNo: "00001",
                //         pickupWayDiv: "01",
                //         pickupBaseSid: "2100000141",
                //         pickupPoint: "智光山",
                //         pickupScheduleDate: "2024/07/11 00:00:00.000",
                //         pickupScheduleTime: "11:00 - 12:00",
                //         pickupScheduleTimeFrom: "2024/07/11 11:00:00.000",
                //         pickupScheduleTimeTo: "2024/07/11 12:00:00.000",
                //         deliveryWayDiv: "01",
                //         deliveryBaseSid: "S000001687",
                //         deliveryPoint: "柏原営業所",
                //         deliveryScheduleDate: "2024/07/12 00:00:00.000",
                //         deliveryScheduleTime: "17:00 - 18:00",
                //         deliveryScheduleTimeFrom: "2024/07/12 17:00:00.000",
                //         deliveryScheduleTimeTo: "2024/07/12 18:00:00.000",
                //         rootStatusDiv: "済",
                //         deliveryStatusDiv: "配車済",
                //         representativeProductsName: "ｺﾍﾟﾝ,ﾀﾝﾄ,付属品",
                //         smallLotsQuantity: "4",
                //         weight: "3100",
                //         primeContractor: "日高営業所",
                //         officeSelected: "埼玉営業所",
                //         inquiryNumber: "555501",
                //         invNumSid: "99000000001",
                //         invoicePrintDate: "2024/11/02 15:43:12",
                //         taglabelPrintDate: "2024/11/02 20:43:12",
                //         lotNumber: "2500000001",
                //         updateDateTime: "2024/07/10 17:00:00.000",
                //       },
                //       {
                //         orderDate: "2024/07/10 00:00:00.000",
                //         freightOrderNumber: "2400000001",
                //         supplierCd: "4135200",
                //         supplier: "ダイハツ",
                //         area: "埼玉県狭山市",
                //         officeSid: "S000001630",
                //         businessNo: "00001",
                //         pickupWayDiv: "01",
                //         pickupBaseSid: "2100000141",
                //         pickupPoint: "智光山",
                //         pickupScheduleDate: "2024/07/11 00:00:00.000",
                //         pickupScheduleTime: "11:00 - 12:00",
                //         pickupScheduleTimeFrom: "2024/07/11 11:00:00.000",
                //         pickupScheduleTimeTo: "2024/07/11 12:00:00.000",
                //         deliveryWayDiv: "01",
                //         deliveryBaseSid: "S000001687",
                //         deliveryPoint: "柏原営業所",
                //         deliveryScheduleDate: "2024/07/12 00:00:00.000",
                //         deliveryScheduleTime: "17:00 - 18:00",
                //         deliveryScheduleTimeFrom: "2024/07/12 17:00:00.000",
                //         deliveryScheduleTimeTo: "2024/07/12 18:00:00.000",
                //         rootStatusDiv: "未",
                //         deliveryStatusDiv: "未配車",
                //         representativeProductsName: "ｺﾍﾟﾝ,ﾀﾝﾄ,付属品",
                //         smallLotsQuantity: "4",
                //         weight: "3100",
                //         primeContractor: "日高営業所",
                //         officeSelected: "埼玉営業所",
                //         inquiryNumber: "555501",
                //         invNumSid: "99000000003",
                //         invoicePrintDate: "2024/11/02 15:43:12",
                //         taglabelPrintDate: "2024/11/02 20:43:12",
                //         lotNumber: "",
                //         updateDateTime: "2024/07/10 17:00:00.000",
                //       },
                //       {
                //         orderDate: "2024/07/10 00:00:00.000",
                //         freightOrderNumber: "2400000001",
                //         supplierCd: "4135200",
                //         supplier: "ダイハツ",
                //         area: "埼玉県狭山市",
                //         officeSid: "S000001630",
                //         businessNo: "00001",
                //         pickupWayDiv: "01",
                //         pickupBaseSid: "2100000141",
                //         pickupPoint: "智光山",
                //         pickupScheduleDate: "2024/07/11 00:00:00.000",
                //         pickupScheduleTime: "11:00 - 12:00",
                //         pickupScheduleTimeFrom: "2024/07/11 11:00:00.000",
                //         pickupScheduleTimeTo: "2024/07/11 12:00:00.000",
                //         deliveryWayDiv: "01",
                //         deliveryBaseSid: "S000001687",
                //         deliveryPoint: "柏原営業所",
                //         deliveryScheduleDate: "2024/07/12 00:00:00.000",
                //         deliveryScheduleTime: "17:00 - 18:00",
                //         deliveryScheduleTimeFrom: "2024/07/12 17:00:00.000",
                //         deliveryScheduleTimeTo: "2024/07/12 18:00:00.000",
                //         rootStatusDiv: "済",
                //         deliveryStatusDiv: "配車済",
                //         representativeProductsName: "ｺﾍﾟﾝ,ﾀﾝﾄ,付属品",
                //         smallLotsQuantity: "4",
                //         weight: "3100",
                //         primeContractor: "日高営業所",
                //         officeSelected: "埼玉営業所",
                //         inquiryNumber: "555501",
                //         invNumSid: "99000000001",
                //         invoicePrintDate: "2024/11/02 15:43:12",
                //         taglabelPrintDate: "2024/11/02 20:43:12",
                //         lotNumber: "",
                //         updateDateTime: "2024/07/10 17:00:00.000",
                //       },
                //       {
                //         orderDate: "2024/07/10 00:00:00.000",
                //         freightOrderNumber: "2400000001",
                //         supplierCd: "4135200",
                //         supplier: "ダイハツ",
                //         area: "埼玉県狭山市",
                //         officeSid: "4134700",
                //         businessNo: "00001",
                //         pickupWayDiv: "01",
                //         pickupBaseSid: "2100000141",
                //         pickupPoint: "智光山",
                //         pickupScheduleDate: "2024/07/11 00:00:00.000",
                //         pickupScheduleTime: "11:00 - 12:00",
                //         pickupScheduleTimeFrom: "2024/07/11 11:00:00.000",
                //         pickupScheduleTimeTo: "2024/07/11 12:00:00.000",
                //         deliveryWayDiv: "01",
                //         deliveryBaseSid: "S000001687",
                //         deliveryPoint: "柏原営業所",
                //         deliveryScheduleDate: "2024/07/12 00:00:00.000",
                //         deliveryScheduleTime: "17:00 - 18:00",
                //         deliveryScheduleTimeFrom: "2024/07/12 17:00:00.000",
                //         deliveryScheduleTimeTo: "2024/07/12 18:00:00.000",
                //         rootStatusDiv: "済",
                //         deliveryStatusDiv: "配車済",
                //         representativeProductsName: "ｺﾍﾟﾝ,ﾀﾝﾄ,付属品",
                //         smallLotsQuantity: "4",
                //         weight: "3100",
                //         primeContractor: "日高営業所",
                //         officeSelected: "株式会社太陽",
                //         inquiryNumber: "555501",
                //         invNumSid: "99000000002",
                //         invoicePrintDate: "2024/11/02 15:43:12",
                //         taglabelPrintDate: "2024/11/02 20:43:12",
                //         lotNumber: "2500000001",
                //         updateDateTime: "2024/07/10 17:00:00.000",
                //       },
                //       {
                //         orderDate: "2024/07/10 00:00:00.000",
                //         freightOrderNumber: "2400000001",
                //         supplierCd: "4135200",
                //         supplier: "ダイハツ",
                //         area: "埼玉県狭山市",
                //         officeSid: "S000001630",
                //         businessNo: "00001",
                //         pickupWayDiv: "01",
                //         pickupBaseSid: "S000001650",
                //         pickupPoint: "茨城営業所",
                //         pickupScheduleDate: "2024/07/11 00:00:00.000",
                //         pickupScheduleTime: "11:00 - 12:00",
                //         pickupScheduleTimeFrom: "2024/07/11 11:00:00.000",
                //         pickupScheduleTimeTo: "2024/07/11 12:00:00.000",
                //         deliveryWayDiv: "01",
                //         deliveryBaseSid: "S000001687",
                //         deliveryPoint: "柏原営業所",
                //         deliveryScheduleDate: "2024/07/12 00:00:00.000",
                //         deliveryScheduleTime: "17:00 - 18:00",
                //         deliveryScheduleTimeFrom: "2024/07/12 17:00:00.000",
                //         deliveryScheduleTimeTo: "2024/07/12 18:00:00.000",
                //         rootStatusDiv: "済",
                //         deliveryStatusDiv: "配車済",
                //         representativeProductsName: "ｺﾍﾟﾝ,ﾀﾝﾄ,付属品",
                //         smallLotsQuantity: "4",
                //         weight: "3100",
                //         primeContractor: "日高営業所",
                //         officeSelected: "埼玉営業所",
                //         inquiryNumber: "555501",
                //         invNumSid: "99000000001",
                //         invoicePrintDate: "2024/11/02 15:43:12",
                //         taglabelPrintDate: "2024/11/02 20:43:12",
                //         lotNumber: "",
                //         updateDateTime: "2024/07/10 17:00:00.000",
                //       },
                //       {
                //         orderDate: "2024/07/10 00:00:00.000",
                //         freightOrderNumber: "2400000001",
                //         supplierCd: "4135200",
                //         supplier: "ダイハツ",
                //         area: "埼玉県狭山市",
                //         officeSid: "S000001630",
                //         businessNo: "00001",
                //         pickupWayDiv: "01",
                //         pickupBaseSid: "2100000141",
                //         pickupPoint: "智光山",
                //         pickupScheduleDate: "2024/07/11 00:00:00.000",
                //         pickupScheduleTime: "11:00 - 12:00",
                //         pickupScheduleTimeFrom: "2024/07/11 11:00:00.000",
                //         pickupScheduleTimeTo: "2024/07/11 12:00:00.000",
                //         deliveryWayDiv: "01",
                //         deliveryBaseSid: "S000001652",
                //         deliveryPoint: "江別営業所",
                //         deliveryScheduleDate: "2024/07/12 00:00:00.000",
                //         deliveryScheduleTime: "17:00 - 18:00",
                //         deliveryScheduleTimeFrom: "2024/07/12 17:00:00.000",
                //         deliveryScheduleTimeTo: "2024/07/12 18:00:00.000",
                //         rootStatusDiv: "未",
                //         deliveryStatusDiv: "未配車",
                //         representativeProductsName: "ｺﾍﾟﾝ,ﾀﾝﾄ,付属品",
                //         smallLotsQuantity: "4",
                //         weight: "3100",
                //         primeContractor: "日高営業所",
                //         officeSelected: "埼玉営業所",
                //         inquiryNumber: "555501",
                //         invNumSid: "99000000003",
                //         invoicePrintDate: "2024/11/02 15:43:12",
                //         taglabelPrintDate: "2024/11/02 20:43:12",
                //         lotNumber: "",
                //         updateDateTime: "2024/07/10 17:00:00.000",
                //       },
                //       {
                //         orderDate: "2024/07/10 00:00:00.000",
                //         freightOrderNumber: "2400000001",
                //         supplierCd: "4135200",
                //         supplier: "ダイハツ",
                //         area: "埼玉県狭山市",
                //         officeSid: "S000001630",
                //         businessNo: "00001",
                //         pickupWayDiv: "01",
                //         pickupBaseSid: "2100000141",
                //         pickupPoint: "智光山",
                //         pickupScheduleDate: "2024/07/19 00:00:00.000",
                //         pickupScheduleTime: "11:00 - 12:00",
                //         pickupScheduleTimeFrom: "2024/07/11 11:00:00.000",
                //         pickupScheduleTimeTo: "2024/07/11 12:00:00.000",
                //         deliveryWayDiv: "01",
                //         deliveryBaseSid: "S000001687",
                //         deliveryPoint: "柏原営業所",
                //         deliveryScheduleDate: "2024/07/12 00:00:00.000",
                //         deliveryScheduleTime: "17:00 - 18:00",
                //         deliveryScheduleTimeFrom: "2024/07/12 17:00:00.000",
                //         deliveryScheduleTimeTo: "2024/07/12 18:00:00.000",
                //         rootStatusDiv: "未",
                //         deliveryStatusDiv: "未配車",
                //         representativeProductsName: "ｺﾍﾟﾝ,ﾀﾝﾄ,付属品",
                //         smallLotsQuantity: "4",
                //         weight: "3100",
                //         primeContractor: "日高営業所",
                //         officeSelected: "埼玉営業所",
                //         inquiryNumber: "555501",
                //         invNumSid: "99000000003",
                //         invoicePrintDate: "2024/11/02 15:43:12",
                //         taglabelPrintDate: "2024/11/02 20:43:12",
                //         lotNumber: "",
                //         updateDateTime: "2024/07/10 17:00:00.000",
                //       },
                //       {
                //         orderDate: "2024/07/10 00:00:00.000",
                //         freightOrderNumber: "2400000001",
                //         supplierCd: "4135200",
                //         supplier: "ダイハツ",
                //         area: "埼玉県狭山市",
                //         officeSid: "S000001630",
                //         businessNo: "00001",
                //         pickupWayDiv: "01",
                //         pickupBaseSid: "2100000141",
                //         pickupPoint: "智光山",
                //         pickupScheduleDate: "2024/07/11 00:00:00.000",
                //         pickupScheduleTime: "11:30 - 12:00",
                //         pickupScheduleTimeFrom: "2024/07/11 11:00:00.000",
                //         pickupScheduleTimeTo: "2024/07/11 12:00:00.000",
                //         deliveryWayDiv: "01",
                //         deliveryBaseSid: "S000001687",
                //         deliveryPoint: "柏原営業所",
                //         deliveryScheduleDate: "2024/07/12 00:00:00.000",
                //         deliveryScheduleTime: "17:00 - 18:00",
                //         deliveryScheduleTimeFrom: "2024/07/12 17:00:00.000",
                //         deliveryScheduleTimeTo: "2024/07/12 18:00:00.000",
                //         rootStatusDiv: "未",
                //         deliveryStatusDiv: "未配車",
                //         representativeProductsName: "ｺﾍﾟﾝ,ﾀﾝﾄ,付属品",
                //         smallLotsQuantity: "4",
                //         weight: "3100",
                //         primeContractor: "日高営業所",
                //         officeSelected: "埼玉営業所",
                //         inquiryNumber: "555501",
                //         invNumSid: "99000000003",
                //         invoicePrintDate: "2024/11/02 15:43:12",
                //         taglabelPrintDate: "2024/11/02 20:43:12",
                //         lotNumber: "",
                //         updateDateTime: "2024/07/10 17:00:00.000",
                //       },
                //       {
                //         orderDate: "2024/07/10 00:00:00.000",
                //         freightOrderNumber: "2400000001",
                //         supplierCd: "4135200",
                //         supplier: "ダイハツ",
                //         area: "埼玉県狭山市",
                //         officeSid: "S000001630",
                //         businessNo: "00001",
                //         pickupWayDiv: "01",
                //         pickupBaseSid: "2100000141",
                //         pickupPoint: "智光山",
                //         pickupScheduleDate: "2024/07/11 00:00:00.000",
                //         pickupScheduleTime: "11:00 - 12:00",
                //         pickupScheduleTimeFrom: "2024/07/11 11:00:00.000",
                //         pickupScheduleTimeTo: "2024/07/11 12:00:00.000",
                //         deliveryWayDiv: "01",
                //         deliveryBaseSid: "S000001687",
                //         deliveryPoint: "柏原営業所",
                //         deliveryScheduleDate: "2024/07/15 00:00:00.000",
                //         deliveryScheduleTime: "17:00 - 18:00",
                //         deliveryScheduleTimeFrom: "2024/07/12 17:00:00.000",
                //         deliveryScheduleTimeTo: "2024/07/12 18:00:00.000",
                //         rootStatusDiv: "未",
                //         deliveryStatusDiv: "未配車",
                //         representativeProductsName: "ｺﾍﾟﾝ,ﾀﾝﾄ,付属品",
                //         smallLotsQuantity: "4",
                //         weight: "3100",
                //         primeContractor: "日高営業所",
                //         officeSelected: "埼玉営業所",
                //         inquiryNumber: "555501",
                //         invNumSid: "99000000003",
                //         invoicePrintDate: "2024/11/02 15:43:12",
                //         taglabelPrintDate: "2024/11/02 20:43:12",
                //         lotNumber: "",
                //         updateDateTime: "2024/07/10 17:00:00.000",
                //       },
                //       {
                //         orderDate: "2024/07/10 00:00:00.000",
                //         freightOrderNumber: "2400000001",
                //         supplierCd: "4135200",
                //         supplier: "ダイハツ",
                //         area: "埼玉県狭山市",
                //         officeSid: "S000001630",
                //         businessNo: "00001",
                //         pickupWayDiv: "01",
                //         pickupBaseSid: "2100000141",
                //         pickupPoint: "智光山",
                //         pickupScheduleDate: "2024/07/11 00:00:00.000",
                //         pickupScheduleTime: "11:00 - 12:00",
                //         pickupScheduleTimeFrom: "2024/07/11 11:00:00.000",
                //         pickupScheduleTimeTo: "2024/07/11 12:00:00.000",
                //         deliveryWayDiv: "01",
                //         deliveryBaseSid: "S000001687",
                //         deliveryPoint: "柏原営業所",
                //         deliveryScheduleDate: "2024/07/12 00:00:00.000",
                //         deliveryScheduleTime: "17:00 - 19:00",
                //         deliveryScheduleTimeFrom: "2024/07/12 17:00:00.000",
                //         deliveryScheduleTimeTo: "2024/07/12 18:00:00.000",
                //         rootStatusDiv: "未",
                //         deliveryStatusDiv: "未配車",
                //         representativeProductsName: "ｺﾍﾟﾝ,ﾀﾝﾄ,付属品",
                //         smallLotsQuantity: "4",
                //         weight: "3100",
                //         primeContractor: "日高営業所",
                //         officeSelected: "埼玉営業所",
                //         inquiryNumber: "555501",
                //         invNumSid: "99000000003",
                //         invoicePrintDate: "2024/11/02 15:43:12",
                //         taglabelPrintDate: "2024/11/02 20:43:12",
                //         lotNumber: "",
                //         updateDateTime: "2024/07/10 17:00:00.000",
                //       },
                //     ],
                //   },
                // };
                // 成功
                if (jsonData.resCom.resComCode == "000") {
                  // ↓↓-------仮置きでテストデータを表示 API完成次第、ここは削除=-------↓↓
                  // const orders = [];
                  // for (let i = 1; i <= 100; i++) {
                  //   orders.push({
                  //     supplierName: "取引先名テスト" + i,
                  //     pickupBaseName: "集荷元名テスト",
                  //     pickupScheduleDate: "2024/07/10 00:00:00.000",
                  //     pickupScheduleTime: "01:00 - 02:00",
                  //     deliveryBaseName: "配達先テスト",
                  //     deliveryScheduleDate: "2024/07/11 00:00:00.000",
                  //     deliveryScheduleTime: "10:00 - 13:00",
                  //     pickupStatusName: "配車済",
                  //     deliveryStatusName: "未配車",
                  //     vehiclePlanName: "対象",
                  //     privateName: "非公開",
                  //     orderDate: "2024/07/12 00:00:00.000",
                  //     totalWeight: "30000.333",
                  //     smallLotsQuantity: "50000",
                  //     invNumSid: "1234567890",
                  //     inquiryNumber: "9876543210",
                  //     invoicePrintDate: "2024/07/13 00:00:00.000",
                  //     taglabelPrintDate: "2024/07/14 00:00:00.000",
                  //   });
                  // }
                  // const jsonData = {
                  //   resIdv: {
                  //     orders,
                  //   },
                  //   resCom: {
                  //     resComPagination: {
                  //       totalPage: 3,
                  //       totalRecord: 7,
                  //     },
                  //   },
                  // };
                  // ↑↑---------------------------------------------------------↑↑
                  this.pageCount = jsonData.resCom.resComPagination.totalPage;
                  this.totalCount = jsonData.resCom.resComPagination.totalRecord;
                  const list = [];
                  // 営業所別ロット組（荷繰り）登録を取得
                  jsonData.resIdv.orders.forEach((row) => {
                    list.push({
                      No: jsonData.resIdv.orders.indexOf(row) + 1,
                      // 取引先
                      supplier: row.supplier,
                      // 出荷元
                      pickupPoint: row.pickupPoint,
                      // 出荷元SID
                      pickupPointSid: row.pickupBaseSid,
                      // 集荷予定日（画面表示用=JST:秒数なし）
                      pickupScheduleDate: dateTimeHelper
                        .convertUTC2JST(row.pickupScheduleDate)
                        .substr(0, 10),
                      // 集荷予定時間
                      pickupScheduleTime: row.pickupScheduleTime,
                      // 配達先
                      deliveryPoint: row.deliveryPoint,
                      // お届け先SID
                      deliveryPointSid: row.deliveryBaseSid,
                      // 配達予定日（画面表示用=JST:秒数なし）
                      deliveryScheduleDate: dateTimeHelper
                        .convertUTC2JST(row.deliveryScheduleDate)
                        .substr(0, 10),
                      // 配達予定時間
                      deliveryScheduleTime: row.deliveryScheduleTime,
                      // 対象/対象外
                      // vehiclePlanFlg: row.vehiclePlanName,
                      // 公開/非公開
                      // privateFlg: row.privateName,
                      // 受注日（画面表示用=JST:秒数なし）
                      orderDate: dateTimeHelper.convertUTC2JST(row.orderDate).substr(0, 10),
                      // 受注番号
                      freightOrderNumber: row.freightOrderNumber,
                      // 元請営業所
                      primeContractor: row.primeContractor,
                      // 担当営業所
                      officeSelected: row.officeSelected,
                      // 担当営業所SID
                      officeSelectedSid: row.officeSid,
                      // エリア
                      area: row.area,
                      // 品名
                      representativeProductsName: row.representativeProductsName,
                      // 重量
                      weight: commonFunction.getDelimiterFew(row.weight, 9, 3),
                      // 小口数
                      smallLotQuantity: commonFunction.getDelimiter(row.smallLotsQuantity, 5),
                      // ルート設定
                      rootStatusDiv: row.rootStatusDiv,
                      // 送り状番号
                      invNumSid: row.invNumSid,
                      // お客様管理番号
                      inquiryNum: row.inquiryNumber,
                      // 配車状況
                      deliveryStatusDiv: row.deliveryStatusDiv,
                      // 送り状発行時間
                      invoicePrintDate:
                        row.invoicePrintDate != null
                          ? dateTimeHelper.convertUTC2JST(row.invoicePrintDate).substr(0, 19)
                          : "",
                      // 荷札発行時間
                      taglabelPrintDate:
                        row.taglabelPrintDate != null
                          ? dateTimeHelper.convertUTC2JST(row.taglabelPrintDate).substr(0, 19)
                          : "",
                      // ロット番号
                      lotNumber: row.lotNumber,

                      // チェックボタン状態
                      check: false,
                    });
                  });
                  this.inputList = list;

                  if (!checkBoxFlag) {
                    // 検索ボタン以外で検索処理が走った場合、元々チェックしていたレコードにチェックをいれる
                    for (var k = 0; k < this.inputList.length; k++) {
                      for (var j = 0; j < this.listCheckboxBtn.length; j++) {
                        if (this.inputList[k].No == this.listCheckboxBtn[j].No) {
                          this.inputList[k].check = true;
                        }
                      }
                    }
                  } else {
                    // 検索ボタン押下時は、チェックボックスを詰めていたListを初期化する
                    this.listCheckboxBtn.length = 0;
                  }

                  // ページング検索の場合の全選択チェックボックス処理
                  if (this.isAllSelected) {
                    this.isAllSelected = true;
                    for (var i = 0; i < this.inputList.length; i++) {
                      this.inputList[i].check = true;
                    }
                  }
                  // this.loadingCounter = 0; // API完成次第、この行も削除
                  resolve(response);
                } else {
                  // エラー時
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = true;
                  reject();
                }
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          });
          // ローディング画面表示OFF
          // this.loadingCounter = 0;
        }
      }
    },

    // 列単位のHTMLクラス指定
    getColumnClass(colDef, isHeader) {
      let result = "";
      if (colDef.align) {
        result += " text-" + colDef.align;
      }
      if (isHeader) {
        if (colDef.class) {
          result += " " + colDef.class;
        }
      }
      return result.trim();
    },

    // 列単位のHTMLスタイル指定
    getColumnStyle(colDef) {
      let result = "";
      if (colDef.minWidth) {
        result += " min-width: " + colDef.minWidth + ";";
      }
      if (colDef.maxWidth) {
        result += " max-width: " + colDef.maxWidth + ";";
      }
      if (colDef.minWidth || colDef.maxWidth) {
        result += " width: auto;";
      }
      if (colDef.width) {
        result += " width: " + colDef.width + ";";
        if (!colDef.minWidth) {
          result += " min-width: " + colDef.width + ";";
        }
      }
      return result.trim();
    },

    // 項目単位のHTMLクラス指定
    getColItemClass(colDef, itemDefIdx) {
      let result = "";
      /* 項目ごとに定義したスタイルを参照できるようにする */
      result += " " + colDef.items[itemDefIdx].value;
      /* ほかの項目と共通のスタイル指定の参照 */
      if (colDef.items[itemDefIdx].class) {
        result += " " + colDef.items[itemDefIdx].class;
      }
      return result.trim();
    },

    // ロット分割ボタンの表示有無
    lotDivisionButtonVisibility(item) {
      // v-show だと display が none になり 体裁が崩れるので visibility で制御する
      return item.rootStatusDiv == "未" ? "visible" : "hidden";
    },

    // ロット分割ボタンの活性、非活性
    lotDivisionButtonDisabled(item) {
      // v-show だと display が none になり 体裁が崩れるので visibility で制御する
      return item.rootStatusDiv == "済" ? true : false;
    },

    // 市町村ダイアログ 設定ボタンの表示有無
    setMunicipalitiesButtonVisibility() {
      // v-show だと display が none になり 体裁が崩れるので visibility で制御する
      return !this.modalMunicipalitiesSetValue ? "hidden" : "visible";
    },

    /**
     * 営業所別ロット組（荷繰り）登録画面： CSV出力ボタン押下
     */
    csvOutput() {
      if (!this.csvOutputCheck()) {
        return;
      }
      this.csvOutputDialog.message = messsageUtil.getMessage("P-TOD-002_001_C");
      this.csvOutputDialog.isOpen = true;
      this.csvOutputDialog.screenFlag = true;
      this.csvOutputDialog.changeFlag = false;
      this.csvOutputDialog.okAction = this.csvOutputOk;
    },
    /**
     * 日付大小比較
     */
    compDate(dateF, dateT) {
      if (dateF && dateT) {
        if (new Date(dateF).getTime() > new Date(dateT).getTime()) {
          return false;
        }
      }
      return true;
    },
    /**
     * CSVを出力する時の検索条件の入力チェック
     */
    csvOutputCheck() {
      // 単項目チェック
      if (!this.$refs.form.validate()) {
        return false;
      }

      // 相関チェック
      let check = 0;

      // メッセージ初期化
      this.alertMessage = "";
      this.pickupAlertMessage = "";
      this.deliveryAlertMessage = "";

      // 発注日Toは発注日Fromよりも未来の日付を入力してください。
      if (!this.compDate(this.orderDateFrom, this.orderDateTo)) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
        check++;
      }

      // 集荷予定日Toは集荷予定日Fromよりも未来の日付を入力してください。
      if (!this.compDate(this.pickupScheduleDateFrom, this.pickupScheduleDateTo)) {
        this.pickupAlertMessage = i18n.tc("check.chk_inputFromVali");
        check++;
      }

      // 配達予定日Toは配達予定日Fromよりも未来の日付を入力してください。
      if (!this.compDate(this.deliveryScheduleDateFrom, this.deliveryScheduleDateTo)) {
        this.deliveryAlertMessage = i18n.tc("check.chk_inputFromVali");
        check++;
      }

      if (check > 0) {
        return false;
      }

      return true;
    },
    /**
     * 営業所別ロット組（荷繰り）登録画面： CSV出力開始
     */
    csvOutputOk() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const body = this.$httpClient.createRequestBodyConfig();
      // 共通パラメータの設定
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_TOD_002;
      //body.reqCom.reqComComponentId = "A-TOD-007";

      // 検索条件の設定
      // 取引先SID
      body.reqCom.reqComOfficeSid = sessionStorage.getItem("sales_office_sid");
      // 取引先CD
      if (this.suppliersSelected) {
        body.reqIdv.shipperClientCd = this.suppliersSelected;
      }
      // 受注日From
      if (this.orderDateFrom) {
        body.reqIdv.orderFromDate = dateTimeHelper.convertUTC(this.orderDateFrom);
      }
      // 受注日To
      if (this.orderDateTo) {
        body.reqIdv.orderToDate = dateTimeHelper.convertUTC(this.orderDateTo + " 23:59:59.999");
      }
      // 担当営業所SID
      if (this.officeSelected) {
        body.reqIdv.officeSid = this.officeSelected;
      }
      // 集荷状態区分
      // body.reqIdv.pickupStatusDiv = this.pickupStatusDiv;
      // 配達状態区分
      // body.reqIdv.deliveryStatusDiv = this.deliveryStatusDiv;
      // ルート登録
      if (this.routeRegistrationDivSelected) {
        body.reqIdv.routeRegistrationDivSelected = this.routeRegistrationDivSelected;
      }
      // 集荷拠点取引先CD
      if (this.pickupPointCd) {
        body.reqIdv.pickupPointCd = this.pickupPointCd;
      }
      // 集荷拠点SID
      if (this.pickupPointSid) {
        body.reqIdv.pickupPointSid = this.pickupPointSid;
      } else if (this.pickupPointSelected) {
        body.reqIdv.pickupPointSid = this.pickupPointSelected;
      }
      // 都道府県(出荷元)
      if (this.pickupPointPrefecturesSelected) {
        body.reqIdv.pickupPointPrefecturesSelected = this.pickupPointPrefecturesSelected;
      }
      // 市区町村(出荷元)
      if (this.pickupMunicipalities) {
        body.reqIdv.pickupMunicipalities = this.pickupMunicipalities;
      }
      // 集荷予定日From
      if (this.pickupScheduleDateFrom) {
        body.reqIdv.pickupScheduleFromDate = dateTimeHelper.convertUTC(this.pickupScheduleDateFrom);
      }
      // 集荷予定日To
      if (this.pickupScheduleDateTo) {
        body.reqIdv.pickupScheduleToDate = dateTimeHelper.convertUTC(
          this.pickupScheduleDateTo + " 23:59:59.999"
        );
      }
      // 配達拠点取引先CD
      if (this.deliveryPointCd) {
        body.reqIdv.deliveryPointCd = this.deliveryPointCd;
      }
      // 配達拠点SID
      if (this.deliveryPointSid) {
        body.reqIdv.deliveryPointSid = this.deliveryPointSid;
      } else if (this.deliveryPointSelected) {
        body.reqIdv.deliveryPointSid = this.deliveryPointSelected;
      }
      // 都道府県(お届け先)
      if (this.deliveryPointPrefecturesSelected) {
        body.reqIdv.deliveryPointPrefecturesSelected = this.deliveryPointPrefecturesSelected;
      }
      // 市区町村(お届け先)
      if (this.deliveryMunicipalities) {
        body.reqIdv.deliveryMunicipalities = this.deliveryMunicipalities;
      }
      // 配達予定日From
      if (this.deliveryScheduleDateFrom) {
        body.reqIdv.deliveryScheduleFromDate = dateTimeHelper.convertUTC(
          this.deliveryScheduleDateFrom
        );
      }
      // 配達予定日To
      if (this.deliveryScheduleDateTo) {
        body.reqIdv.deliveryScheduleToDate = dateTimeHelper.convertUTC(
          this.deliveryScheduleDateTo + " 23:59:59.999"
        );
      }
      // お客様管理番号
      if (this.inquiryNumber) {
        body.reqIdv.inquiryNumber = this.inquiryNumber;
      }
      // 受注番号
      if (this.freightOrderNumber) {
        body.reqIdv.freightOrderNumber = this.freightOrderNumber;
      }
      // ロット番号
      if (this.lotNumber) {
        body.reqIdv.lotNumber = this.lotNumber;
      }
      // 送り状SID
      if (this.invNum) {
        body.reqIdv.invNumSid = this.invNum;
      }
      // 送り状
      if (this.invNumStatusSelected) {
        body.reqIdv.invNumStatusSelected = this.invNumStatusSelected;
      }
      // 荷札
      if (this.shippingTagStatusSelected) {
        body.reqIdv.shippingTagStatusSelected = this.shippingTagStatusSelected;
      }

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力(受注CSV出力)
          .securePost(appConfig.API_URL.BIZ_TRANSPORT_ORDERS_CSV, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // 返却されたurlを共通関数へ呼び出し
              commonFunction.getLink(jsonData.resIdv.filePath);
              resolve(response);
            } else {
              // エラー時(件数0件またはapiエラー時)
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              resolve(response);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
            this.dataOutPutDialog = false;
          });
      });
    },

    /**
     * 営業所別ロット組（荷繰り）登録画面： 送り状発行API
     */
    getInvoiceIssue() {
      if (this.listCheckboxBtn.length > 0) {
        // ローディング画面表示ON
        this.loadingCounter = 1;
        // httpClientで使用する共通IFパラメータ等を作成する。
        const body = this.$httpClient.createRequestBodyConfig();
        // 共通IF項目 画面ID
        body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_TOD_002;
        // タイムゾーン
        body.reqIdv.timeZone = dateTimeHelper.getTimeZone();
        // 受注情報List
        var bodyList = [];
        for (var i = 0; i < this.listCheckboxBtn.length; i++) {
          var inputMap = {};
          // 送り状SID
          inputMap.invNumSid = this.listCheckboxBtn[i].invNumSid;
          // ロットSID
          inputMap.lotSID = this.listCheckboxBtn[i].lotNumber;

          bodyList.push(inputMap);
        }
        body.reqIdv.invNumSids = bodyList;
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.BIZ_TRANSPORT_INVOICE, body, appConfig.APP_CONFIG)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // 成功
              if (jsonData.resCom.resComCode == "000") {
                // 返却されたurlをデータに格納、ダウンロードボタンの活性化
                this.returnURL = jsonData.resIdv.filePath;
                this.link();
                resolve(response);
              } else {
                // エラー時
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      } else {
        // 警告ダイアログ表示
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
      }
    },

    /**
     * 警告ダイアログ、OKボタン押下
     */
    updateOk() {
      this.updateDialogMessage = false;
    },

    /**
     * 営業所別ロット組（荷繰り）登録画面： 荷札発行API
     */
    getTagIssue() {
      if (this.listCheckboxBtn.length > 0) {
        // ローディング画面表示ON
        this.loadingCounter = 1;
        // httpClientで使用する共通IFパラメータ等を作成する。
        const body = this.$httpClient.createRequestBodyConfig();
        // 共通IF項目 画面ID
        body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_TOD_002;
        // タイムゾーン
        body.reqIdv.timeZone = dateTimeHelper.getTimeZone();
        // 受注情報List
        var bodyList = [];
        for (var i = 0; i < this.listCheckboxBtn.length; i++) {
          var inputMap = {};
          // 送り状SID
          inputMap.invNumSid = this.listCheckboxBtn[i].invNumSid;
          // 貨物受注SID
          inputMap.freightOrderSid = this.listCheckboxBtn[i].freightOrderNumber;
          // ロットSID
          inputMap.lotSID = this.listCheckboxBtn[i].lotNumber;
          bodyList.push(inputMap);
        }
        body.reqIdv.taglabel = bodyList;
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.BIZ_TRANSPORT_TAGLABEL, body, appConfig.APP_CONFIG)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // 成功
              if (jsonData.resCom.resComCode == "000") {
                // 返却されたurlをデータに格納、ダウンロードボタンの活性化
                this.returnURL = jsonData.resIdv.filePath;
                this.link();
                resolve(response);
              } else {
                // エラー時
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      } else {
        // 警告ダイアログ表示
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
      }
    },

    /**
     * 初期値
     */
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      if (this.$route.params.searchParam) {
        // console.debug(this.suppliersSelected);
        // 遷移フラグ
        this.transitionFlg = true;
      } else {
        this.suppliersSelected = "";
        this.orderDateFrom = dateTimeHelper.convertJST().substr(0, 10);
        this.orderDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.orderDateTo = dateTimeHelper.convertJST().substr(0, 10);
        this.orderDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.officeSelected = "";
        // this.pickupStatusDiv = "01";
        // this.deliveryStatusDiv = "01";
        this.pickupPointSelected = "";
        this.pickupScheduleDateFrom = dateTimeHelper.convertJST().substr(0, 10);
        this.pickupScheduleDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.pickupScheduleDateTo = dateTimeHelper.convertJST().substr(0, 10);
        this.pickupScheduleDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.deliveryPointSelected = "";
        this.deliveryScheduleDateFrom = dateTimeHelper.convertJST().substr(0, 10);
        this.deliveryScheduleDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.deliveryScheduleDateTo = dateTimeHelper.convertJST().substr(0, 10);
        this.deliveryScheduleDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.inquiryNumber = "";
        this.invNum = "";
        this.pickupPointprefecturesSelected = "";
        this.pickupMunicipalities = "";
        this.deliveryPointprefecturesSelected = "";
        this.deliveryMunicipalities = "";
        this.shippingTagStatusSelected = "";
        this.invNumStatusSelected = "";
      }
      this.getMstCode();
    },

    /**
     * チェックボックス押下処理
     */
    // checkList(item, index) {
    //   if (!item.check) {
    //     // チェックを入れた場合

    //     // 編集用リスト
    //     this.chengeListCheckbox.push(item);

    //     // 画面遷移用リスト
    //     this.listCheckboxBtn.push(item);
    //   } else {
    //     // チェックを外した場合

    //     // 外したレコードの送り状SIDを取得
    //     let inputListOutlistNo = this.inputList[index].invNumSid;

    //     // 画面遷移用リストを初期化
    //     this.listCheckboxBtn = [];

    //     // チェックを外したレコード以外を画面遷移用リストに詰めなおす
    //     for (var i = 0; i < this.chengeListCheckbox.length; i++) {
    //       if (this.chengeListCheckbox[i].invNumSid != inputListOutlistNo) {
    //         this.listCheckboxBtn.push(this.chengeListCheckbox[i]);
    //       }
    //     }

    //     // 編集用リストも更新
    //     this.chengeListCheckbox = [];
    //     for (var j = 0; j < this.listCheckboxBtn.length; j++) {
    //       this.chengeListCheckbox.push(this.listCheckboxBtn[j]);
    //     }
    //   }
    // },

    /**
     * チェックボックス押下処理
     */
    checkRow(item, index) {
      if (!item.check) {
        // チェックを入れた場合

        // 編集用リスト
        this.chengeListCheckbox.push(item);

        // 画面遷移用リスト
        this.listCheckboxBtn.push(item);

        // 最後にチェックした行を記憶しておく
        this.lastFocusRow = index;
      } else {
        // チェックを外した場合

        // 外したレコードの受注番号を取得
        let inputListOutlistNo = this.inputList[index].freightOrderSid;

        // 画面遷移用リストを初期化
        this.listCheckboxBtn = [];

        // チェックを外したレコード以外を画面遷移用リストに詰めなおす
        for (var i = 0; i < this.chengeListCheckbox.length; i++) {
          if (this.chengeListCheckbox[i].freightOrderSid != inputListOutlistNo) {
            this.listCheckboxBtn.push(this.chengeListCheckbox[i]);
          }
        }

        // 編集用リストも更新
        this.chengeListCheckbox = [];
        for (var j = 0; j < this.listCheckboxBtn.length; j++) {
          this.chengeListCheckbox.push(this.listCheckboxBtn[j]);
        }

        // 最後にチェックした行を消去しておく
        this.lastFocusRow = -1;
      }
    },

    /**
     * チェックボックスフォーカス外れ処理
     * 最終選択行をクリアする
     */
    blurRowCheck() {
      if (this.lastFocusRow >= 0) {
        // 少し待ってからクリアする
        setTimeout(() => {
          this.lastFocusRow = -1;
        }, 200);
      }
    },

    /**
     * 一括ボタン押下処理
     */
    batchSelect() {
      if (this.lastFocusRow >= 0) {
        let rowidx = this.lastFocusRow;
        let lastSelOrder = this.inputList[rowidx];
        if (lastSelOrder.check == true) {
          let listCheckboxBtn = this.listCheckboxBtn;
          this.inputList
            // 出荷元・お届け先・集荷予定日・集荷予定時間・到着日・到着時間・担当営業所が一致する行を検索
            .filter((order) => {
              let result = order.check == false && this.isCompatibleOrder(order, lastSelOrder);
              return result;
            })
            // その行を選択した状態にする
            .forEach((order) => {
              order.check = true;
              listCheckboxBtn.push(order);
            });
        }
      }
    },

    /**
     * 受注データどうしの互換性チェック
     */
    isCompatibleOrder(order1, order2) {
      let result =
        order1.pickupPointSid == order2.pickupPointSid &&
        order1.deliveryPointSid == order2.deliveryPointSid &&
        order1.pickupScheduleDate == order2.pickupScheduleDate &&
        order1.pickupScheduleTime == order2.pickupScheduleTime &&
        order1.deliveryScheduleDate == order2.deliveryScheduleDate &&
        order1.deliveryScheduleTime == order2.deliveryScheduleTime &&
        order1.officeSelectedSid == order2.officeSelectedSid;
      return result;
    },

    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 日付Fromを－１日します。
     */
    prevFromDate(param) {
      switch (param) {
        case "order":
          this.orderDateFromCal = commonFunction.addDate(this.orderDateFromCal, "");
          break;
        case "pickup":
          this.pickupScheduleDateFromCal = commonFunction.addDate(
            this.pickupScheduleDateFromCal,
            ""
          );
          break;
        case "delivery":
          this.deliveryScheduleDateFromCal = commonFunction.addDate(
            this.deliveryScheduleDateFromCal,
            ""
          );
          break;
      }
    },

    /**
     * 日付Fromを＋１日します。
     */
    nextFromDate(param) {
      switch (param) {
        case "order":
          this.orderDateFromCal = commonFunction.addDate(this.orderDateFromCal, "add");
          break;
        case "pickup":
          this.pickupScheduleDateFromCal = commonFunction.addDate(
            this.pickupScheduleDateFromCal,
            "add"
          );
          break;
        case "delivery":
          this.deliveryScheduleDateFromCal = commonFunction.addDate(
            this.deliveryScheduleDateFromCal,
            "add"
          );
          break;
      }
    },
    /**
     * 日付Toを－１日します。
     */
    prevToDate(param) {
      switch (param) {
        case "order":
          this.orderDateToCal = commonFunction.addDate(this.orderDateToCal, "");
          break;
        case "pickup":
          this.pickupScheduleDateToCal = commonFunction.addDate(this.pickupScheduleDateToCal, "");
          break;
        case "delivery":
          this.deliveryScheduleDateToCal = commonFunction.addDate(
            this.deliveryScheduleDateToCal,
            ""
          );
          break;
      }
    },

    /**
     * 日付Toを＋１日します。
     */
    nextToDate(param) {
      switch (param) {
        case "order":
          this.orderDateToCal = commonFunction.addDate(this.orderDateToCal, "add");
          break;
        case "pickup":
          this.pickupScheduleDateToCal = commonFunction.addDate(
            this.pickupScheduleDateToCal,
            "add"
          );
          break;
        case "delivery":
          this.deliveryScheduleDateToCal = commonFunction.addDate(
            this.deliveryScheduleDateToCal,
            "add"
          );
          break;
      }
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 日付入力(From)
     */
    changeDateFrom(val, param) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      var formatedDt = null;
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        formatedDt = dateTimeHelper.dateCalc(dateTimeHelper.convertUTC2JST(strDt.substr(0, 10)));
      }
      switch (param) {
        case "order":
          this.orderDateFromCal = formatedDt;
          break;
        case "pickup":
          this.pickupScheduleDateFromCal = formatedDt;
          break;
        case "delivery":
          this.deliveryScheduleDateFromCal = formatedDt;
          break;
      }
    },

    changecreateDateFrom(val) {
      this.createDateFromCal = commonFunction.directInput(val);
    },

    /**
     * 日付入力(To)
     */
    changeDateTo(val, param) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      var formatedDt = null;
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        formatedDt = dateTimeHelper.dateCalc(dateTimeHelper.convertUTC2JST(strDt.substr(0, 10)));
      }
      switch (param) {
        case "order":
          this.orderDateToCal = formatedDt;
          break;
        case "pickup":
          this.pickupScheduleDateToCal = formatedDt;
          break;
        case "delivery":
          this.deliveryScheduleDateToCal = formatedDt;
          break;
      }
    },

    /**
     * 営業所別ロット組（荷繰り）登録画面：取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      return commonFunction.getListSetName(this.supplierList, this.suppliersSelected);
    },

    /**
     * 営業所別ロット組（荷繰り）登録画面：輸送担当営業所コードリストボックス取得処理
     */
    setSrhOffice() {
      return commonFunction.getListSetName(this.officeList, this.officeSelected);
    },
    changeSupplier(value) {
      let val = this.supplierList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.supplierHint = val.text;
      }
      this.supplierList = this.supplierList.filter((v) => v.value == value);
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.supplierHint = "";
      } else {
        // 処理なし
      }
    },
    /**
     * 担当営業所 プルダウンチェンジイベント
     */
    changeOffice(value) {
      // 担当営業所を選択時
      let val = this.officeList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintOffice = val.text;
        this.getOfficeSelected = val.text;
      }
      this.officeList = this.officeList.filter((v) => v.value == value);
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintOffice = "";
      } else {
        // 処理なし
      }
    },
    /**
     * 配達先 プルダウンチェンジイベント
     */
    changeDelivery(value) {
      // 配達先を選択時
      let val = this.deliveryPointList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintDelivery = val.text;
        this.getDeliverySelected = val.text;
        this.deliveryPointCd = val.customerCode;
        this.deliveryPointSid = val.value;
      }
      this.deliveryPointList = this.deliveryPointList.filter((v) => v.value == value);
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintDelivery = "";
        this.deliveryPointCd = "";
        this.deliveryPointSid = "";
      } else {
        // 処理なし
      }
    },

    /**
     * 営業所別ロット組（荷繰り）登録画面：集荷元コードリストボックス取得処理
     */
    setSrhPickup() {
      return commonFunction.getListSetName(this.pickupPointList, this.pickupPointSelected);
    },

    /**
     * 営業所別ロット組（荷繰り）登録画面：配達先コードリストボックス取得処理
     */
    setSrhDelivery() {
      return commonFunction.getListSetName(this.deliveryPointList, this.deliveryPointSelected);
    },

    /**
     * 集荷先 プルダウンチェンジイベント
     */
    changePickup(value) {
      // 集荷先を選択時
      let val = this.pickupPointList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintPickup = val.text;
        this.getPickupSelected = val.text;
        this.pickupPointCd = val.customerCode;
        this.pickupPointSid = val.value;
      }
      this.pickupPointList = this.pickupPointList.filter((v) => v.value == value);
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintPickup = "";
        this.pickupPointCd = "";
        this.pickupPointSid = "";
      } else {
        // 処理なし
      }
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // 画面遷移後の場合
      if (this.transitionFlg) {
        this.suppliersSelected = "";
        this.supplierList = [];
        this.officeSelected = "";
        this.officeList = [];
        this.pickupPointSelected = "";
        this.pickupPointList = [];
        this.deliveryPointSelected = "";
        this.deliveryPointList = [];
        this.inquiryNumber = "";
        this.invNum = "";

        // 初期化状態の設定
        this.orderDateFrom = dateTimeHelper.convertJST().substr(0, 10);
        this.orderDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.orderDateTo = dateTimeHelper.convertJST().substr(0, 10);
        this.orderDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.pickupScheduleDateFrom = dateTimeHelper.convertJST().substr(0, 10);
        this.pickupScheduleDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.pickupScheduleDateTo = dateTimeHelper.convertJST().substr(0, 10);
        this.pickupScheduleDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.deliveryScheduleDateFrom = dateTimeHelper.convertJST().substr(0, 10);
        this.deliveryScheduleDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.deliveryScheduleDateTo = dateTimeHelper.convertJST().substr(0, 10);
        this.deliveryScheduleDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());

        // 遷移フラグ
        this.transitionFlg = false;
      }

      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
      this.alertMessage = "";
      this.pickupAlertMessage = "";
      this.deliveryAlertMessage = "";
    },

    // 都道府県(出荷元) チェンジイベント
    chengePickupPointPrefectures() {
      const copyPrefecturesList = JSON.parse(JSON.stringify(this.prefecturesList));
      const selectPrefectures = copyPrefecturesList.find(
        (v) => v.value === this.pickupPointprefecturesSelected
      );
      this.pickupPointPrefecturesSelectedName = selectPrefectures.text;
    },
    // 都道府県(お届け先) チェンジイベント
    chengeDeliveryPointPrefectures() {
      const copyPrefecturesList = JSON.parse(JSON.stringify(this.prefecturesList));
      const selectPrefectures = copyPrefecturesList.find(
        (v) => v.value === this.deliveryPointprefecturesSelected
      );
      this.deliveryPointPrefecturesSelectedName = selectPrefectures.text;
    },

    // 市区町村(出荷元) チェンジイベント
    chengePickupPointMunicipality(value) {
      if (!value) {
        this.pickupMunicipalities = null;
      }
    },

    // 市区町村(出荷元) チェンジイベント
    chengeDeliveryPointMunicipality(value) {
      if (!value) {
        this.deliveryMunicipalities = null;
      }
    },

    // 市区町村 プッシュイベント
    openModalMunicipality(caller) {
      this.modalMunicipality = "";

      let localGrovernmentCd = "";
      if ("pickup" === caller) {
        if (
          this.pickupPointprefecturesSelected == "" ||
          this.pickupPointprefecturesSelected == null
        ) {
          return;
        }
        this.modalPrefecture = this.pickupPointPrefecturesSelectedName;
        localGrovernmentCd = this.pickupPointprefecturesSelected;
      } else {
        if (
          this.deliveryPointprefecturesSelected == "" ||
          this.deliveryPointprefecturesSelected == null
        ) {
          return;
        }
        this.modalPrefecture = this.deliveryPointPrefecturesSelectedName;
        localGrovernmentCd = this.deliveryPointprefecturesSelected;
      }

      // 呼び出し元設定
      this.modalMunicipalitiesCaller = caller;

      let list = this.getMunicipalities({
        procKbn: "02",
        localGrovernmentCd: localGrovernmentCd,
      });

      this.modalTotalCount = list.length;
      this.municipalitiesDialog = true;
    },

    /**
     * 市区町村取得
     */
    getMunicipalities(params) {
      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();
      // console.log(params);

      Object.assign(config.params, params);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_POSTCODE_SEARCH, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 成功
            if (jsonData.resCom.resComCode == "000") {
              // const jsonData = {
              //   resCom: {
              //     resComPagination: {
              //       totalPage: 1,
              //       totalRecord: 100,
              //     },
              //   },
              //   reqCom: {
              //     reqComComponentId: "P-TOD-002",
              //     reqComCompanySid: null,
              //     reqComOfficeSid: null,
              //     reqComDeviceImei: "WEB",
              //     reqComAppVersion: "24.11.01",
              //     reqComExecUser: null,
              //     reqComExecTimestamp: "2025/01/22 04:34:59.192",
              //     reqComRequestId: "9854fab4-ce0d-431d-a637-9c2db5a27fc6",
              //     reqComLanguageCd: "ja",
              //     reqComTimeZone: "Asia/Tokyo",
              //   },
              //   resIdv: {
              //     postCodeList: [
              //       {
              //         postCodeSid: "1001000024060001",
              //         localGrovernmentPrefectureCd: "01",
              //         localGrovernmentCd: "01102",
              //         postCode: "0010000",
              //         prefectureName: "北海道",
              //         prefectureKana: "ホッカイドウ",
              //         cityName: "札幌市北区",
              //         cityKana: "サッポロシキタク",
              //         townAreaName: "北十条西（１〜４丁目）",
              //         townAreaKana: "キタ１０ジョウニシ（１−４チョウメ）",
              //       },
              //       {
              //         postCodeSid: "1003001324060001",
              //         localGrovernmentPrefectureCd: "01",
              //         localGrovernmentCd: "01104",
              //         postCode: "0030013",
              //         prefectureName: "北海道",
              //         prefectureKana: "ホッカイドウ",
              //         cityName: "札幌市白石区",
              //         cityKana: "サッポロシシロイシク",
              //         townAreaName: "中央三条",
              //         townAreaKana: "チュウオウ３ジョウ",
              //       },
              //     ],
              //   },
              // };
              const list = [];
              jsonData.resIdv.postCodeList.forEach((row) => {
                list.push({
                  modalMunicipalitiesName: row.cityName,
                  value: row.localGrovernmentCd,
                });
              });
              this.baseMunicipalitiesNameList = list;
              this.modalMunicipalitiesNameList = list;
              this.modalTotalCount = list.length;
              // return list;
              resolve(response);
            } else {
              // エラー時
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((resolve) => {
            console.error("GetParameter getTmsCodeMst Err", resolve);
            reject(messsageUtil.getMessage("P-999-999_999_E"));
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 市区町村 テキストボックスチェンジイベント
     */
    changeMunicipalities() {
      this.modalMunicipalitiesSetValue = "";

      let caller = this.modalMunicipalitiesCaller;
      let localGrovernmentCd =
        "pickup" === caller
          ? this.pickupPointprefecturesSelected
          : this.deliveryPointprefecturesSelected;

      if (this.modalMunicipality == "" || this.modalMunicipality == null) {
        this.modalMunicipalitiesNameList = this.getMunicipalities({
          procKbn: "02",
          localGrovernmentCd: localGrovernmentCd,
        });
      } else {
        // 市区町村を選択時
        this.modalMunicipalitiesNameList = this.getMunicipalities({
          procKbn: "02",
          localGrovernmentCd: localGrovernmentCd,
          searchKeyword: this.modalMunicipality,
        });
      }
      this.modalTotalCount = this.modalMunicipalitiesNameList.length;
    },

    setCanSet() {
      this.canSet = false;
    },

    // 市区町村 設定ボタン押下イベント
    setMunicipalities() {
      const copyChangeMunicipalitiesList = JSON.parse(
        JSON.stringify(this.baseMunicipalitiesNameList)
      );
      const selectMunicipalities = copyChangeMunicipalitiesList.find(
        (v) => v.value === this.modalMunicipalitiesSetValue
      );

      const selectMunicipalitiesName = selectMunicipalities.modalMunicipalitiesName;

      if ("pickup" === this.modalMunicipalitiesCaller) {
        this.pickupMunicipalities = this.modalMunicipalitiesSetValue;
        this.pickupMunicipalitiesName = selectMunicipalitiesName;
      } else {
        this.deliveryMunicipalities = this.modalMunicipalitiesSetValue;
        this.deliveryMunicipalitiesName = selectMunicipalitiesName;
      }

      this.municipalitiesDialog = false;
    },

    // 市区町村ダイアログ 閉じるボタン押下イベント
    closeMunicipalitiesDialog() {
      this.modalMunicipalitiesKeyword = "";
      this.canSet = true;
      this.municipalitiesDialog = false;
    },

    // ルート登録画面 遷移
    showRootRegistration() {
      if (this.listCheckboxBtn.length == 0) {
        // 警告ダイアログ表示
        this.infoDialog.message = messsageUtil.getMessage("P-EXT-003_001_E");
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
        return;
      }

      // 遷移時に渡す送り状SID、ロットSIDを設定
      let lotSIDList = [];
      for (var i = 0; i < this.listCheckboxBtn.length; i++) {
        lotSIDList.push(this.listCheckboxBtn[i].lotNumber);
      }

      let arrCheckDuplicates = [...new Set(lotSIDList)];

      if (arrCheckDuplicates.length == 1) {
        // 何もしない。
      } else if (arrCheckDuplicates.length == 2 && arrCheckDuplicates.includes("")) {
        // 何もしない
      } else {
        // 警告ダイアログ表示
        this.infoDialog.message = messsageUtil.getMessage("P-TOD-002_001_E");
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
        return;
      }

      let transportInfoList = [];
      this.listCheckboxBtn.some((order) => {
        transportInfoList.push({
          invNumSid: order.invNumSid,
          lotSid: order.lotNumber,
        });
        return false;
      });

      this.$router.push({
        name: appConfig.SCREEN_ID.P_TOD_007,
        params: {
          // 受注情報リスト(送り状SID、ロットSIDのリスト)
          transportInfoList: transportInfoList,
          // 戻り遷移用 検索条件
          searchParam: this.searchParam,
        },
      });
    },

    // 詳細ボタン プッシュイベント
    openOrderDetailDialog(item) {
      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.invNumSid = item.invNumSid;
      if (item.lotNumber) {
        config.params.lotSid = item.lotNumber;
      }

      return new Promise((resolve) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_TRANSPORT_DETAIL_SEARCH, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // const jsonData = {
            //   resCom: {
            //     resComPagination: {
            //       totalPage: 1,
            //       totalRecord: 100,
            //     },
            //     resComCode: "000",
            //   },
            //   reqCom: {
            //     reqComComponentId: "P-TOD-002",
            //     reqComCompanySid: null,
            //     reqComOfficeSid: null,
            //     reqComDeviceImei: "WEB",
            //     reqComAppVersion: "24.11.01",
            //     reqComExecUser: null,
            //     reqComExecTimestamp: "2025/01/22 04:34:59.192",
            //     reqComRequestId: "9854fab4-ce0d-431d-a637-9c2db5a27fc6",
            //     reqComLanguageCd: "ja",
            //     reqComTimeZone: "Asia/Tokyo",
            //   },
            //   resIdv: {
            //     timeZone: "Asia/Tokyo",
            //     orderDetails: [
            //       {
            //         invNumSid: "99000000001",
            //         packingSids: {
            //           packingSid: "25000000000000000002",
            //           updateDateTime: "2024/07/10 00:00:00.000",
            //         },
            //         itemName: "test",
            //         packingNacCd: "02",
            //         packingNacName: "袋",
            //         totalWeight: "2",
            //         freightSizeL: "3",
            //         freightSizeW: "4",
            //         freightSizeH: "5",
            //         smallLotsQuantity: "1",
            //         volumeWeight: "10",
            //         transportRequirements: [
            //           {
            //             transportRequirement: "輸送要件リスト1",
            //           },
            //         ],
            //         remarks:
            //           "備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考1備考",
            //         deleteFlg: "0",
            //       },
            //       {
            //         invNumSid: "99000000002",
            //         packingSids: {
            //           packingSid: "25000000000000000002",
            //           updateDateTime: "2024/07/10 00:00:00.000",
            //         },
            //         itemName: "test2",
            //         packingNacCd: "03",
            //         packingNacName: "段ボール",
            //         totalWeight: "7",
            //         freightSizeL: "2",
            //         freightSizeW: "6",
            //         freightSizeH: "7",
            //         smallLotsQuantity: "5",
            //         volumeWeight: "20",
            //         transportRequirements: [
            //           {
            //             transportRequirement: "輸送要件リスト2",
            //           },
            //           {
            //             transportRequirement: "輸送要件リスト3",
            //           },
            //         ],
            //         remarks:
            //           "備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考",
            //         deleteFlg: "0",
            //       },
            //       {
            //         invNumSid: "99000000002",
            //         packingSids: {
            //           packingSid: "25000000000000000002",
            //           updateDateTime: "2024/07/10 00:00:00.000",
            //         },
            //         itemName: "test2",
            //         packingNacCd: "03",
            //         packingNacName: "段ボール",
            //         totalWeight: "7",
            //         freightSizeL: "2",
            //         freightSizeW: "6",
            //         freightSizeH: "7",
            //         smallLotsQuantity: "5",
            //         volumeWeight: "20",
            //         transportRequirements: [
            //           {
            //             transportRequirement: "輸送要件リスト2",
            //           },
            //           {
            //             transportRequirement: "輸送要件リスト3",
            //           },
            //         ],
            //         remarks:
            //           "備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考",
            //         deleteFlg: "0",
            //       },
            //       {
            //         invNumSid: "99000000002",
            //         packingSids: {
            //           packingSid: "25000000000000000002",
            //           updateDateTime: "2024/07/10 00:00:00.000",
            //         },
            //         itemName: "test2",
            //         packingNacCd: "03",
            //         packingNacName: "段ボール",
            //         totalWeight: "7",
            //         freightSizeL: "2",
            //         freightSizeW: "6",
            //         freightSizeH: "7",
            //         smallLotsQuantity: "5",
            //         volumeWeight: "20",
            //         transportRequirements: [
            //           {
            //             transportRequirement: "輸送要件リスト2",
            //           },
            //           {
            //             transportRequirement: "輸送要件リスト3",
            //           },
            //         ],
            //         remarks:
            //           "備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考",
            //         deleteFlg: "0",
            //       },
            //       {
            //         invNumSid: "99000000002",
            //         packingSids: {
            //           packingSid: "25000000000000000002",
            //           updateDateTime: "2024/07/10 00:00:00.000",
            //         },
            //         itemName: "test2",
            //         packingNacCd: "03",
            //         packingNacName: "段ボール",
            //         totalWeight: "7",
            //         freightSizeL: "2",
            //         freightSizeW: "6",
            //         freightSizeH: "7",
            //         smallLotsQuantity: "5",
            //         volumeWeight: "20",
            //         transportRequirements: [
            //           {
            //             transportRequirement: "輸送要件リスト2",
            //           },
            //           {
            //             transportRequirement: "輸送要件リスト3",
            //           },
            //         ],
            //         remarks:
            //           "備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考",
            //         deleteFlg: "0",
            //       },
            //       {
            //         invNumSid: "99000000002",
            //         packingSids: {
            //           packingSid: "25000000000000000002",
            //           updateDateTime: "2024/07/10 00:00:00.000",
            //         },
            //         itemName: "test2",
            //         packingNacCd: "03",
            //         packingNacName: "段ボール",
            //         totalWeight: "7",
            //         freightSizeL: "2",
            //         freightSizeW: "6",
            //         freightSizeH: "7",
            //         smallLotsQuantity: "5",
            //         volumeWeight: "20",
            //         transportRequirements: [
            //           {
            //             transportRequirement: "輸送要件リスト2",
            //           },
            //           {
            //             transportRequirement: "輸送要件リスト3",
            //           },
            //         ],
            //         remarks:
            //           "備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考2備考",
            //         deleteFlg: "0",
            //       },
            //     ],
            //   },
            // };
            // 成功
            if (jsonData.resCom.resComCode == "000") {
              let no = 0;
              const list = [];
              jsonData.resIdv.orderDetails.forEach((orderDetail) => {
                orderDetail.no = ++no;
                orderDetail.capacitym3 =
                  (orderDetail.freightSizeL * orderDetail.freightSizeW * orderDetail.freightSizeH) /
                  100;
                orderDetail.conversionTotalWeight =
                  orderDetail.smallLotsQuantity * orderDetail.volumeWeight;
                let arraytrAnsportRequirement = [];
                orderDetail.transportRequirements.forEach((requirement) => {
                  arraytrAnsportRequirement.push(requirement.transportRequirement);
                });

                list.push({
                  modalNo: orderDetail.no,
                  modalProductCodeName: orderDetail.itemName,
                  modalPacking: orderDetail.packingNacName,
                  modalSmallLotsQuantity: orderDetail.smallLotsQuantity,
                  modalWeight: orderDetail.totalWeight,
                  modalVerticalLcm: orderDetail.freightSizeL,
                  modalBesideLcm: orderDetail.freightSizeW,
                  modalHeightLcm: orderDetail.freightSizeH,
                  modalCapacitym3: orderDetail.capacitym3,
                  modalTotalWeight: orderDetail.totalWeight,
                  modalConversionTotalWeight: orderDetail.volumeWeight,
                  modalTransportRequirementArrow: arraytrAnsportRequirement.join(","),
                  modalRemarks: orderDetail.remarks,
                  modalConversionWeight: orderDetail.conversionTotalWeight,
                });
              });
              this.modalOrderDetailsList = list;
              this.orderDetailDialog = true;
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
        // this.loadingCounter = 0;
      });
    },

    // ロット分割画面 遷移
    showlotDivision(item) {
      this.$router.push({
        name: appConfig.SCREEN_ID.P_TOD_005,
        params: {
          // 受注日
          orderDate: item.orderDate,
          // 元請営業所
          primeContractor: item.primeContractor,
          // 担当営業所
          officeSelected: item.officeSelected,
          // 取引先
          clientName: item.supplier,
          // 受注番号
          tansportOrder: item.freightOrderNumber,
          // 出荷元
          pickupPoint: item.pickupPoint,
          // お届け先
          deliveryPoint: item.deliveryPoint,
          // 集荷予定日
          pickupScheduleDate: item.pickupScheduleDate,
          // 集荷予定日 時間
          pickupScheduleTime: item.pickupScheduleTime,
          // 到着日
          deliveryScheduleDate: item.deliveryScheduleDate,
          // 到着日 時間
          deliveryScheduleTime: item.deliveryScheduleTime,
          // 送り状SID
          invNumSid: item.invNumSid,
          // 戻り遷移用 検索条件
          searchParam: this.searchParam,
        },
      });
    },

    /**
     * 日付の値をカレンダー用に変換
     */
    dateCalc(dateValue) {
      return dateValue == null ? null : dateTimeHelper.dateCalc(dateValue);
    },

    /**
     * 段組み表項目出力
     */
    showValue(value) {
      return value == "" || value == null ? "&nbsp;" : this.escapeHtml(value);
    },

    /**
     * htmlをエスケープする
     */
    escapeHtml(string) {
      if (typeof string !== "string") {
        return string;
      }
      return string.replace(/[&'`"<>]/g, function (match) {
        return {
          "&": "&amp;",
          "'": "&#x27;",
          "`": "&#x60;",
          '"': "&quot;",
          "<": "&lt;",
          ">": "&gt;",
        }[match];
      });
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    // 現時点で取引先との紐づきがないためコメントアウト
    // suppliersSelected(newValue, oldValue) {
    //   // 輸送担当営業所/ 集荷元/ 配達先クリア
    //   if (oldValue != null && oldValue != "") {
    //     this.officeList = [];
    //     this.officeSelected = "";
    //     this.pickupPointList = [];
    //     this.pickupPointSelected = "";
    //     this.deliveryPointList = [];
    //     this.deliveryPointSelected = "";
    //   }
    // },

    orderDateFromCal: {
      handler(val) {
        this.orderDateFrom = this.formatDate(val);
      },
      deep: true,
    },
    orderDateToCal: {
      handler(val) {
        this.orderDateTo = this.formatDate(val);
      },
      deep: true,
    },
    pickupScheduleDateFromCal: {
      handler(val) {
        this.pickupScheduleDateFrom = this.formatDate(val);
      },
      deep: true,
    },
    pickupScheduleDateToCal: {
      handler(val) {
        this.pickupScheduleDateTo = this.formatDate(val);
      },
      deep: true,
    },
    deliveryScheduleDateFromCal: {
      handler(val) {
        this.deliveryScheduleDateFrom = this.formatDate(val);
      },
      deep: true,
    },
    deliveryScheduleDateToCal: {
      handler(val) {
        this.deliveryScheduleDateTo = this.formatDate(val);
      },
      deep: true,
    },

    page: function (newValue) {
      this.page = newValue;
      this.getApiList(false, false);
    },
    sortOptions: {
      handler(items) {
        // console.log("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_TOD_002_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.getApiList(false, false);
        }
      },
      deep: true,
    },
    /**
     * 輸送担当営業所取得
     */
    getOfficeSelected(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintOffice) {
          return;
        }
        // 現時点で取引先との紐づきがないためコメントアウト
        // if (this.suppliersSelected == null || this.suppliersSelected == "") {
        //   this.officeList = [];
        //   this.officeSelected = "";
        //   return;
        // }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 選択した会社コードを取得
          // let supplierCd = this.supplierList.find(
          //   (element) => element.value === this.suppliersSelected
          // ).value;
          const params = {};
          // 入力値
          params.officeSearchKeyword = val;

          // 有効期限区分
          params.validDateKbn = 1;

          // ------- ↓↓共通処理が完成次第、修正↓↓-----------
          const officeListTmp = this.getMstOffice(params);
          // const officeListTmp = [
          //   {
          //     text: "001（日高営業所）",
          //     value: "2400000001",
          //     name: "日高営業所",
          //   },
          // ];

          Promise.all([officeListTmp])
            .then((result) => {
              // 画面の初期値を設定します。
              this.officeList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.officeList.length = 0;
          this.officeList = [];
        }
      }
    },

    /**
     * 集荷元取得
     */
    getPickupSelected(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintPickup) {
          return;
        }
        // 現時点で取引先との紐づきがないためコメントアウト
        // if (this.suppliersSelected == null || this.suppliersSelected == "") {
        //   this.pickupPointList = [];
        //   this.pickupPointSelected = "";
        //   return;
        // }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // ------- ↓↓共通処理が完成次第、修正↓↓-----------
          // 選択した会社コードを取得
          // let supplierCd = this.supplierList.find(
          //   (element) => element.value === this.suppliersSelected
          // ).value;
          const params = {};
          // 取引先コード
          // params.customerCode = supplierCd;
          // 拠点管理種別（1=拠点を取得）
          // params.baseOwnerType = "1";
          // 有効期限区分（1=すべて取得）
          params.validDateKbn = "1";
          // 自拠点区分（1=自拠点を含む）
          params.selfEstablishedLocationsKbn = "1";
          // 拠点検索キーワード
          params.baseSearchKeyword = val;

          const pickupListTmp = getParameter.getTmsBizBaseMst(params);
          // const pickupListTmp = [
          //   {
          //     text: "002（VCJ埼玉SC）",
          //     value: "2400000002",
          //     customerCode: "4400710",
          //     name: "VCJ埼玉SC",
          //   },
          //   {
          //     text: "003（VC東名横浜）",
          //     value: "2400000003",
          //     customerCode: "4400711",
          //     name: "VC東名横浜",
          //   },
          //   {
          //     text: "004（VC港北NT）",
          //     value: "2400000004",
          //     customerCode: "4400712",
          //     name: "VC港北NT",
          //   },
          //   {
          //     text: "005（VC目黒）",
          //     value: "2400000005",
          //     customerCode: "4400713",
          //     name: "VC目黒",
          //   },
          //   {
          //     text: "006（VC練馬）",
          //     value: "2400000006",
          //     customerCode: "4400714",
          //     name: "VC練馬",
          //   },
          //   {
          //     text: "007（VC杉並）",
          //     value: "2400000007",
          //     customerCode: "4400715",
          //     name: "VC杉並",
          //   },
          //   {
          //     text: "008（VC世田谷）",
          //     value: "2400000008",
          //     customerCode: "4400716",
          //     name: "VC世田谷",
          //   },
          // ];

          Promise.all([pickupListTmp])
            .then((result) => {
              // 画面の初期値を設定します。
              this.pickupPointList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.pickupPointList.length = 0;
          this.pickupPointList = [];
        }
      }
    },
    /**
     * 配達先取得
     */
    getDeliverySelected(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintDelivery) {
          return;
        }
        // 現時点で取引先との紐づきがないためコメントアウト
        // if (this.suppliersSelected == null || this.suppliersSelected == "") {
        //   this.deliveryPointList = [];
        //   this.deliveryPointSelected = "";
        //   return;
        // }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // ------- ↓↓共通処理が完成次第、修正↓↓-----------
          // 選択した会社コードを取得
          // let supplierCd = this.supplierList.find(
          //   (element) => element.value === this.suppliersSelected
          // ).value;
          const params = {};
          // 取引先コード
          // params.customerCode = supplierCd;
          // 拠点管理種別（1=拠点を取得）
          // params.baseOwnerType = "1";
          // 有効期限区分（1=すべて取得）
          params.validDateKbn = "1";
          // 自拠点区分（1=自拠点を含む）
          params.selfEstablishedLocationsKbn = "1";

          // 拠点検索キーワード
          params.baseSearchKeyword = val;

          const deliveryListTmp = getParameter.getTmsBizBaseMst(params);
          // const deliveryListTmp = [
          //   {
          //     text: "002（VCJ埼玉SC）",
          //     value: "2400000002",
          //     customerCode: "4400710",
          //     name: "VCJ埼玉SC",
          //   },
          //   {
          //     text: "003（VC東名横浜）",
          //     value: "2400000003",
          //     customerCode: "4400711",
          //     name: "VC東名横浜",
          //   },
          //   {
          //     text: "004（VC港北NT）",
          //     value: "2400000004",
          //     customerCode: "4400712",
          //     name: "VC港北NT",
          //   },
          //   {
          //     text: "005（VC目黒）",
          //     value: "2400000005",
          //     customerCode: "4400713",
          //     name: "VC目黒",
          //   },
          //   {
          //     text: "006（VC練馬）",
          //     value: "2400000006",
          //     customerCode: "4400714",
          //     name: "VC練馬",
          //   },
          //   {
          //     text: "007（VC杉並）",
          //     value: "2400000007",
          //     customerCode: "4400715",
          //     name: "VC杉並",
          //   },
          //   {
          //     text: "008（VC世田谷）",
          //     value: "2400000008",
          //     customerCode: "4400716",
          //     name: "VC世田谷",
          //   },
          // ];

          Promise.all([deliveryListTmp])
            .then((result) => {
              // 画面の初期値を設定します。
              this.deliveryPointList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.deliveryPointList.length = 0;
          this.deliveryPointList = [];
        }
      }
    },
    supplierInput(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.supplierHint) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 取引先取得
          this.getCustomInfo(val);
        }
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
.from_to {
  font-weight: bold;
}
.toatlNum {
  margin-left: 10px;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .product-fixed1 {
  position: sticky !important;
  left: 0 !important;
  z-index: 6 !important;
}
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .product-fixed2 {
  position: sticky !important;
  left: 50px !important;
  z-index: 6 !important;
}
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .product-fixed3 {
  position: sticky !important;
  left: 170px !important;
  z-index: 6 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .product-fixed3-search {
  position: sticky !important;
  left: 170px !important;
  z-index: 6 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(1) {
  left: 0 !important;
  z-index: 5 !important;
}
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(2) {
  left: 50px !important;
  z-index: 5 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody tr td {
  background-color: #ffffff;
}

//tdに背景色を設定(横固定をするため)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody tr:nth-child(odd) td {
  background-color: #dde5f0;
}

.add-textbox-remark {
  width: 40rem;
}

div#listData.orderDetailList {
  height: 500px !important;
  overflow-y: auto;
  overflow-x: auto;
  width: 1780px;
}

div#listData.orderDetailList > .v-data-table__wrapper td:nth-child(1) {
  position: sticky !important;
  left: 0 !important;
  z-index: 5 !important;
}

div#listData.orderDetailList > .v-data-table__wrapper td:nth-child(2) {
  position: sticky !important;
  left: 100px !important;
  z-index: 5 !important;
}
</style>
