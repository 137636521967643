<template>
  <div style="background: white; overflow-x: hidden">
    <Loading :loadingCounter="loadingCounter" />
    <Loading />
    <!-- 検索項目 -->
    <v-card class="mx-auto">
      <v-card-title>
        <p style="background: linear-gradient(transparent 50%, #ffff00 95%)">
          {{ $t("label.lbl_remarksInput") }}
        </p>
      </v-card-title>
      <v-card-subtitle>
        <p style="float: left">{{ $t("label.lbl_emptyVehicleRegistrationDiscription") }}</p>
        <span class="require asterisk-spacer">*</span>
        <span style="font-size: 0.9rem; vertical-align: Top" class="require">{{
          " 最大200文字まで"
        }}</span>
      </v-card-subtitle>
      <v-card-text>
        <!-- 詳細入力 -->
        <v-form ref="detailInput" lazy-validation>
          <v-container fluid>
            <v-textarea
              v-model="inputRemarks"
              filled
              :rules="[rules.required, rules.maxLength]"
              :maxlength="200"
            ></v-textarea>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-col>
          <div style="float: right">
            <!-- 閉じるボタン -->
            <v-btn
              ref="closeBtn"
              color="primary"
              id="closeBtn"
              class="other-btn"
              right
              @click="viewConfirmDialog('btn_close')"
              >{{ $t("btn.btn_close") }}</v-btn
            >
            <!-- 設定ボタン -->
            <v-btn
              color="primary"
              id="btn-content-config"
              class="other-btn"
              @click="viewConfirmDialog('btn_config')"
              >{{ $t("btn.btn_config") }}</v-btn
            >
          </div>
        </v-col>
      </v-card-actions>
    </v-card>

    <ConfirmDialog
      :isShow.sync="confirmDialog.isOpen"
      :message="confirmDialog.message"
      :okAction="confirmDialog.okAction"
      :redMessage="confirmDialog.redMessage"
      :screenFlag="confirmDialog.screenFlag"
      :changeFlag="confirmDialog.changeFlag"
    />

    <SimpleDialog
      :isShow.sync="infoDialog.isOpen"
      :title="infoDialog.title"
      :message="infoDialog.message"
      :redMessage="infoDialog.redMessage"
      :screenFlag="infoDialog.screenFlag"
      :changeFlag="infoDialog.changeFlag"
      :firstPageFlag="infoDialog.firstPageFlag"
    />
  </div>
</template>

<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import ConfirmDialog from "@/components/ConfirmDialog";
import { i18n } from "../../lang/lang.js";
export default {
  name: "EmptyVehicleRegistRemarksInputDialog",
  components: {
    Loading,
    ConfirmDialog,
    SimpleDialog,
  },
  // 親画面から渡される値
  props: {
    editItem: { type: Object, default: null },
    isOpenDetailDialog: { type: Boolean, default: false },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 確認メッセージ
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
      okAction: () => {},
    },
    // メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
    rules: {
      required: function (value) {
        if (value !== undefined && value !== null && value !== "") {
          value = String(value);
          value = value.replace(/\s+/g, "");
          if (value === "") {
            return i18n.tc("check.chk_input");
          }
        } else {
          return i18n.tc("check.chk_input");
        }
        return true;
      },
      maxLength: function (value) {
        if (!!value && 200 < value.length) {
          return i18n.tc("check.chk_limitLength_200");
        }
        return true;
      },
    },
    defaultDetailInput: "",
    inputRemarks: "",
  }),

  methods: {
    /**
     * ボタン押下時の処理
     */
    viewConfirmDialog(button) {
      let message;
      let action;
      switch (button) {
        case "btn_config":
          if (!this.$refs.detailInput.validate()) {
            return;
          }
          this.config();
          return;
        case "btn_close":
          message = messsageUtil.getMessage("P-COM-001_004_W");
          action = this.close;
          break;
        default:
          message = "";
      }
      this.confirmDialog.message = message;
      this.confirmDialog.title = appConfig.DIALOG.title;
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = action;
    },
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      this.defaultDetailInput = this.editItem.inputRemarks;
      this.inputRemarks = this.editItem.inputRemarks;
      this.$refs.detailInput.resetValidation();
    },

    /**
     * 閉じる処理
     */
    close() {
      this.editItem.inputRemarks = this.defaultDetailInput;
      this.$emit("update:isOpenDetailDialog", false);
    },

    /**
     * 設定処理
     */
    config() {
      this.editItem.inputRemarks = this.inputRemarks;
      this.$emit("child-event", this.editItem);
      this.$emit("update:isOpenDetailDialog", false);
    },
    reload() {},
  },
  computed: {},
  watch: {
    isOpenDetailDialog: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.text-popBtn {
  padding-bottom: 30px;
}

.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}

// ツールチップ ...(省略)設定
.text-overflow {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-row {
  pointer-events: none;
}

.search-autocomplete-product {
  width: 24rem;
}

.calender-component {
  margin: 16px 0 0 0;
  height: 3rem;
}
</style>
