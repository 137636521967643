<template>
  <div style="height: 100%; width: 100%">
    <v-data-table
      :headers="headers"
      :items="routes"
      fixed-header
      hide-default-footer
      disable-filtering
      disable-pagination
      disable-sort
      :item-class="() => 'table-row'"
      :height="tableHeight"
      id="listData-route"
    >
      <!-- 追加 -->
      <template v-slot:item.add="{ item }">
        <v-btn v-show="!disabled" icon small @click="addRow(item)">
          <v-icon> mdi-table-row-plus-after </v-icon>
        </v-btn>
      </template>
      <!-- No -->
      <template v-slot:item.number="{ index }">
        <span class="text-body-1">{{ index + 1 }}</span>
      </template>
      <!-- 輸送手段 -->
      <template v-slot:item.transportCompDiv="{ index }">
        <!-- 1行目のみ表示する -->
        <v-select
          v-if="index === 0"
          :value="transportCompDiv"
          :items="transportCompDivList"
          :disabled="disabled"
          :rules="[rules.inputRequired]"
          @input="$emit('update:transportCompDiv', $event)"
        >
        </v-select>
      </template>
      <!-- 車両形状（大項目） -->
      <template v-slot:item.vehicleShapeMajor="{ index }">
        <v-select
          v-if="index === 0"
          :value="vehicleShapeMajor"
          :items="vehicleShapeMajorCodeList"
          :disabled="disabled"
          :rules="[rules.inputRequired]"
          @input="$emit('update:vehicleShapeMajor', $event)"
        ></v-select>
      </template>
      <!-- 発地 -->
      <template v-slot:item.fromPoint="{ item, index }">
        <!-- 1行目のみ発地を変更可能 -->
        <v-select
          v-if="index === 0"
          :value="item.fromPointSid"
          :items="pointList"
          return-object
          :disabled="disabled"
          :rules="[rules.inputRequired]"
          @input="updateFromPoint(item, $event)"
        ></v-select>
        <div v-else class="text-body-1">{{ item.fromPointName }}</div>
      </template>
      <!-- 着地 -->
      <template v-slot:item.toPoint="{ item }">
        <v-select
          :value="item.toPointSid"
          :items="pointList"
          return-object
          :disabled="disabled"
          :rules="[rules.inputRequired]"
          @input="updateToPoint(item, $event)"
        ></v-select>
      </template>
      <template v-slot:item.referenceDistanceTime="">
        <v-btn text disabled>
          <span>{{ $t("label.lbl_distanceAndTime") }}</span>
          <v-icon small> mdi-launch </v-icon>
        </v-btn>
      </template>
      <!-- 出発日 -->
      <template v-slot:item.departureScheduleDate="{ item }">
        <DatePicker
          :value="toJstDateStr(item.departureScheduleDate)"
          :required="!disabled"
          :disabled="disabled"
          @input="updateDepartureScheduleDate(item, $event)"
          class="mt-n4"
        />
      </template>
      <!-- 出発時間FromTo -->
      <template v-slot:item.departureScheduleTime="{ item, index: i }">
        <div class="d-flex align-center" style="height: 100%">
          <v-text-field
            dense
            :disabled="disabled"
            :value="fromToTimes?.[i]?.departureFrom"
            type="time"
            class="pa-0 ma-0 time-picker-column"
            clear-icon="mdi-close-circle"
            clearable
            style="width: 6rem"
            @input="updateDepartureScheduleTime(item, $event, fromToTimes?.[i]?.departureTo)"
          ></v-text-field>
          <span class="mx-2 mb-2">－</span>
          <v-text-field
            dense
            :disabled="disabled"
            :value="fromToTimes?.[i]?.departureTo"
            type="time"
            class="pa-0 ma-0 time-picker-column"
            clear-icon="mdi-close-circle"
            clearable
            style="width: 6rem"
            :rules="[timeFromToValidationMessages?.[i]?.departureValidationMessage ?? true]"
            @input="updateDepartureScheduleTime(item, fromToTimes?.[i]?.departureFrom, $event)"
          ></v-text-field>
        </div>
      </template>
      <!-- 到着日 -->
      <template v-slot:item.arrivalScheduleDate="{ item, index: i }">
        <DatePicker
          :value="toJstDateStr(item.arrivalScheduleDate)"
          :required="!disabled"
          :disabled="disabled"
          :rules="[dateFromToValidationMessages?.[i]?.dateValidationMessage ?? true]"
          @input="updateArrivalScheduleDate(item, $event)"
          class="mt-n4"
        />
      </template>
      <!-- 到着時間FromTo -->
      <template v-slot:item.arrivalScheduleTime="{ item, index: i }">
        <div class="d-flex align-center">
          <v-text-field
            dense
            :disabled="disabled"
            :value="fromToTimes?.[i]?.arrivalFrom"
            type="time"
            class="pa-0 ma-0 time-picker-column"
            clear-icon="mdi-close-circle"
            clearable
            style="width: 6rem"
            @input="updateArrivalScheduleTime(item, $event, fromToTimes?.[i]?.arrivalTo)"
          ></v-text-field>
          <span class="mx-2 mb-2">－</span>
          <v-text-field
            dense
            :disabled="disabled"
            :value="fromToTimes?.[i]?.arrivalTo"
            type="time"
            class="pa-0 ma-0 time-picker-column"
            clear-icon="mdi-close-circle"
            clearable
            style="width: 6rem"
            :rules="[timeFromToValidationMessages?.[i]?.arrivalValidationMessage ?? true]"
            @input="updateArrivalScheduleTime(item, fromToTimes?.[i]?.arrivalFrom, $event)"
          ></v-text-field>
        </div>
      </template>
      <!-- 備考 -->
      <template v-slot:item.remarks="{ item }">
        <div class="d-flex align-center justify-center">
          <v-btn v-show="!disabled" icon class="px-1" @click.stop="openRemarksEditDialog(item)">
            <v-icon> mdi-pencil-outline </v-icon>
          </v-btn>
          <div class="ml-1 remarks-text">{{ item.remarks }}</div>
        </div>
      </template>
      <!-- 削除 -->
      <template v-slot:item.delete="{ item, index }">
        <!-- 1行目は削除ボタンを表示しない -->
        <v-btn v-if="index !== 0" v-show="!disabled" icon small @click="deleteRow(item)">
          <v-icon> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <RemarksEditDialog
      :isShow.sync="showRemarksEditDialog"
      :remarks="editRemarksItem?.remarks ?? ''"
      @update:remarks="updateRemarks($event, editRemarksItem?.item)"
    ></RemarksEditDialog>
  </div>
</template>
<script>
import commonRules from "../../mixins/CommonRules";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import DatePicker from "../../components/DatePicker.vue";
import RemarksEditDialog from "./RemarksEditDialog.vue";

/** 個人情報・拠点区分 */
const PointDiv = {
  /** 個人情報 */
  PRIVATE_INF: 0,
  /** 拠点 */
  POINT: 1,
};
export default {
  name: "OfficeLotRouteTable",
  components: {
    DatePicker,
    RemarksEditDialog,
  },
  mixins: [commonRules],
  props: {
    lotRoutes: { type: Array, required: true, default: () => [] },
    transportCompDivList: { type: Array, required: true, default: () => [] },
    pointList: { type: Array, required: true, default: () => [] },
    vehicleShapeMajorCodeList: { type: Array, required: true, default: () => [] },
    transportCompDiv: { type: String, required: false, default: "" },
    vehicleShapeMajor: { type: String, required: false, default: "" },
    disabled: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      showRemarksEditDialog: false,
      editRemarksItem: {
        remarks: "",
        item: null,
      },
    };
  },
  computed: {
    tableHeight() {
      const lineCount = !this.lotRoutes?.length ? 1 : this.lotRoutes?.length;
      return `calc(4rem + 73px * ${lineCount > 5 ? 4 : lineCount})`;
    },
    headers() {
      return [
        // 追加
        {
          text: this.$t("label.lbl_add"),
          value: "add",
          align: "center",
          class: "px-2",
          cellClass: "px-2",
          width: "3.5rem",
        },
        // No
        {
          text: this.$t("label.lbl_No"),
          value: "number",
          align: "center",
          class: "px-2",
          cellClass: "px-2",
        },
        // 輸送手段
        {
          text: this.$t("label.lbl_transportComp"),
          value: "transportCompDiv",
          align: "center",
          class: ["px-2", "required"],
          cellClass: "px-2",
          width: "8rem",
        },
        // 車両形状（大項目）
        {
          text: this.$t("label.lbl_vehicleShapeMajor"),
          value: "vehicleShapeMajor",
          align: "center",
          class: ["px-2", "required"],
          cellClass: "px-2",
          width: "11rem",
        },
        // 発地
        {
          text: this.$t("label.lbl_pickupPointRelay"),
          value: "fromPoint",
          align: "center",
          class: ["px-2", "required"],
          cellClass: "px-2",
          width: "12rem",
        },
        // 着地
        {
          text: this.$t("label.lbl_deliveryPointRelay"),
          value: "toPoint",
          align: "center",
          class: ["px-2", "required"],
          cellClass: "px-2",
          width: "12rem",
        },
        // 参考距離・時間
        {
          text: this.$t("label.lbl_distanceAndTime"),
          value: "referenceDistanceTime",
          align: "center",
          class: "px-2",
          cellClass: "px-2",
          width: "12rem",
        },
        // 出発日
        {
          text: this.$t("label.lbl_departureDate"),
          value: "departureScheduleDate",
          align: "center",
          class: ["px-2", "required"],
          cellClass: "px-2",
          width: "15.5rem",
        },
        // 出発時間
        {
          text: this.$t("label.lbl_pickupScheduleTime"),
          value: "departureScheduleTime",
          align: "center",
          class: "pl-0 pr-2",
          cellClass: "pl-0 pr-2",
          width: "13rem",
        },
        // 到着日
        {
          text: this.$t("label.lbl_deliveryScheduleDate"),
          value: "arrivalScheduleDate",
          align: "center",
          class: ["px-2", "required"],
          cellClass: "px-2",
          width: "15.5rem",
        },
        // 到着時間
        {
          text: this.$t("label.lbl_deliveryScheduleTime"),
          value: "arrivalScheduleTime",
          align: "center",
          class: "pl-0 pr-2",
          cellClass: "pl-0 pr-2",
          width: "13rem",
        },
        // 備考
        {
          text: this.$t("label.lbl_remarks"),
          value: "remarks",
          align: "center",
          class: "px-2",
          width: "12rem",
        },
        // 削除
        {
          text: this.$t("label.lbl_delete"),
          value: "delete",
          align: "center",
          class: "px-2",
          cellClass: "px-2",
          width: "3.5rem",
        },
      ];
    },
    defaultRoute() {
      return {
        fromPointSid: null,
        fromPrivateInfSid: null,
        fromPointName: "",
        departureScheduleDate: "",
        departureScheduleTimeFrom: "",
        departureScheduleTimeTo: "",
        toPointSid: null,
        toPrivateInfSid: null,
        toPointName: "",
        arrivalScheduleDate: "",
        arrivalScheduleTimeFrom: "",
        arrivalScheduleTimeTo: "",
        remarks: "",
      };
    },
    routes() {
      return this.lotRoutes?.length ? [...this.lotRoutes] : [this.defaultRoute];
    },
    fromToTimes() {
      return this.routes.map((item) => {
        const departureFrom = this.toJstTimeStr(item.departureScheduleTimeFrom);
        const departureTo = this.toJstTimeStr(item.departureScheduleTimeTo);
        const arrivalFrom = this.toJstTimeStr(item.arrivalScheduleTimeFrom);
        const arrivalTo = this.toJstTimeStr(item.arrivalScheduleTimeTo);
        return { departureFrom, departureTo, arrivalFrom, arrivalTo };
      });
    },
    timeFromToValidationMessages() {
      return this.fromToTimes.map((item) => {
        const departureValidationMessage = this.validateTimeFromTo({
          from: item.departureFrom,
          to: item.departureTo,
        });
        const arrivalValidationMessage = this.validateTimeFromTo({
          from: item.arrivalFrom,
          to: item.arrivalTo,
        });
        return { departureValidationMessage, arrivalValidationMessage };
      });
    },
    dateFromToValidationMessages() {
      return this.routes.map((item) => {
        const dateValidationMessage = this.validateDateFromTo({
          from: item.departureScheduleDate,
          to: item.arrivalScheduleDate,
        });
        return { dateValidationMessage };
      });
    },
  },
  methods: {
    /** 備考編集用のダイアログを表示 */
    openRemarksEditDialog(item) {
      this.editRemarksItem = {
        remarks: item.remarks,
        item: item,
      };
      this.showRemarksEditDialog = true;
    },
    /** 備考更新 */
    updateRemarks(remarks, item) {
      const index = this.routes.findIndex((x) => x === item);
      if (index === -1) {
        return;
      }
      const newItem = {
        ...this.routes[index],
        remarks,
      };
      const newRoutes = this.routes.toSpliced(index, 1, newItem);
      this.editRemarksItem = null;
      this.emitRoutes(newRoutes);
    },
    validate() {
      const result = this.$refs.routeForm.validate();
      this.valid = result;
      this.$emit("update:valid", result);
    },
    /** UTCの日時文字列(yyyy/MM/dd HH:mm:ss)をJSTの日付文字列(yyyy/MM/dd)に変換する */
    toJstDateStr(utcDateTimeStr) {
      if (!utcDateTimeStr) {
        return "";
      }

      return dateTimeHelper
        .convertUTC2JST(utcDateTimeStr) // => yyyy/MM/dd HH:mm:ss.SSS
        .split(" ")[0]; // => yyyy/MM/dd
    },
    /** JSTの日付文字列(yyyy/MM/dd)をUTCの日時文字列(yyyy/MM/dd HH:mm:ss.SSS)に変換する */
    toUtcDateTimeStrFromJstDateStr(jstDateStr) {
      if (!jstDateStr) {
        return "";
      }

      const jstDateTimeStr = jstDateStr + " 00:00:00"; // => yyyy/MM/dd HH:mm:ss
      return dateTimeHelper.convertUTC(jstDateTimeStr);
    },
    /** UTCの日時文字列(yyyy/MM/dd HH:mm:ss)をJSTの時刻(時と分のみ)文字列(HH:mm)に変換する */
    toJstTimeStr(utcDateTimeStr) {
      if (!utcDateTimeStr) {
        return "";
      }

      return dateTimeHelper
        .convertUTC2JST(utcDateTimeStr) // => yyyy/MM/dd HH:mm:ss.SSS
        .substring(11, 16); // => HH:mm
    },
    /** JSTの時刻文字列(HH:mm)をUTCの日時文字列(yyyy/MM/dd HH:mm:ss.SSS)に変換する */
    toUtcDateTimeStrFromJstTimeStr(baseUtcDateTimeStr, jstTimeStr) {
      if (!baseUtcDateTimeStr || !jstTimeStr) {
        return "";
      }

      const baseJstDateStr = this.toJstDateStr(baseUtcDateTimeStr); // => yyyy/MM/dd
      const jstDateTimeStr = `${baseJstDateStr} ${jstTimeStr}`; // => yyyy/MM/dd HH:mm
      return dateTimeHelper.convertUTC(jstDateTimeStr);
    },
    emitRoutes(newRoutes) {
      this.$emit("update:lotRoutes", newRoutes);
    },
    /** 日付逆転バリデーションチェック */
    validateDateFromTo({ from, to }) {
      if (!from || !to) {
        return true;
      }
      const fromNum = Number(from.replace(/[/ :]/g, ""));
      const toNum = Number(to.replace(/[/ :]/g, ""));
      return fromNum <= toNum
        ? true
        : this.$t("check.chk_dateSinceThen", [this.$t("label.lbl_departureDate")]);
    },
    /** 時刻逆転バリデーションチェック */
    validateTimeFromTo({ from, to }) {
      if (!from || !to) {
        return true;
      }
      const fromNum = Number(from.replace(":", ""));
      const toNum = Number(to.replace(":", ""));
      return fromNum < toNum ? true : this.$t("check.chk_inputFromDate");
    },
    /** 行を追加する */
    addRow(item) {
      const index = this.routes.findIndex((x) => x === item);
      if (index === -1) {
        return;
      }

      // 追加する行の出荷元情報に前の行の届け先の情報を追加
      const obj = {
        ...this.defaultRoute,
        fromPointSid: item.toPointSid,
        fromPrivateInfSid: item.deliveryPrivateInfSid,
        fromPointName: item.toPointName,
      };
      this.emitRoutes(this.routes.toSpliced(index + 1, 0, obj));
    },
    /** 行を削除する */
    deleteRow(item) {
      const index = this.routes.findIndex((x) => x === item);
      if (index !== -1) {
        let newRoutes = this.routes.toSpliced(index, 1);
        if (newRoutes.length > index) {
          // 削除行の後の行の発地に削除行の前の行の着地を設定
          let prevRoute = newRoutes[index - 1];
          let nextRoute = newRoutes[index];
          nextRoute.fromPointSid = prevRoute.toPointSid;
          nextRoute.fromPrivateInfSid = prevRoute.deliveryPrivateInfSid;
          nextRoute.fromPointName = prevRoute.toPointName;
        }
        this.emitRoutes(newRoutes);
      }
    },
    /** 発地を選択した値で更新する（1行目のみ） */
    updateFromPoint(item, selectedPoint) {
      const index = this.routes.findIndex((x) => x === item);
      if (index !== 0) {
        // 1行目以外であれば終了
        return;
      }

      const newFromPoint = {
        fromPointSid: selectedPoint?.div === PointDiv.POINT ? selectedPoint.value : null,
        fromPrivateInfSid: selectedPoint?.div === PointDiv.PRIVATE_INF ? selectedPoint.value : null,
        fromPointName: selectedPoint?.text ?? "",
      };

      const newItem = { ...item, ...newFromPoint };
      const newRoutes = this.routes.toSpliced(index, 1, newItem);
      this.emitRoutes(newRoutes);
    },
    /** 着地（と次の行があればその行の発地）を選択した値で更新する */
    updateToPoint(item, selectedPoint) {
      const index = this.routes.findIndex((x) => x === item);
      if (index === -1) {
        return;
      }

      const newToPoint = {
        toPointSid: selectedPoint?.div === PointDiv.POINT ? selectedPoint.value : null,
        toPrivateInfSid: selectedPoint?.div === PointDiv.PRIVATE_INF ? selectedPoint.value : null,
        toPointName: selectedPoint?.text ?? "",
      };

      const newItem = { ...item, ...newToPoint };
      const newRoutes = this.routes.toSpliced(index, 1, newItem);

      // 最終行以外の着地を更新した場合は、次の行の発地を選択した着地と同じ値にする
      if (index < this.routes.length - 1) {
        const nextLineNewItem = {
          ...this.routes[index + 1],
          fromPointSid: newToPoint.toPointSid,
          fromPrivateInfSid: newToPoint.deliveryPrivateInfSid,
          fromPointName: newToPoint.toPointName,
        };
        newRoutes.splice(index + 1, 1, nextLineNewItem);
      }
      this.emitRoutes(newRoutes);
    },
    updateDepartureScheduleDate(item, newDate) {
      const index = this.routes.findIndex((x) => x === item);
      if (index === -1) {
        return;
      }

      const newUtcDateTimeStr = !newDate ? "" : this.toUtcDateTimeStrFromJstDateStr(newDate);

      // 日付が更新された場合時刻も更新する
      const baseDateStr =
        newUtcDateTimeStr !== "" ? newUtcDateTimeStr : dateTimeHelper.convertUTC();
      const fromTimeStr = this.toJstTimeStr(item.departureScheduleTimeFrom);
      const newFromStr = this.toUtcDateTimeStrFromJstTimeStr(baseDateStr, fromTimeStr);
      const toTimeStr = this.toJstTimeStr(item.departureScheduleTimeFrom);
      const newToStr = this.toUtcDateTimeStrFromJstTimeStr(baseDateStr, toTimeStr);
      const newItem = {
        ...item,
        departureScheduleDate: newUtcDateTimeStr,
        departureScheduleTimeFrom: newFromStr,
        departureScheduleTimeTo: newToStr,
      };
      const newRoutes = this.routes.toSpliced(index, 1, newItem);
      this.emitRoutes(newRoutes);
    },
    updateDepartureScheduleTime(item, fromTimeStr, toTimeStr) {
      const index = this.routes.findIndex((x) => x === item);
      if (index === -1) {
        return;
      }

      // 集荷予定日がなければ現在日時（UTC）をベースにする
      const baseUtcDateTimeStr = !item.departureScheduleDate
        ? dateTimeHelper.convertUTC()
        : item.departureScheduleDate;
      const fromUtcDateTimeStr = this.toUtcDateTimeStrFromJstTimeStr(
        baseUtcDateTimeStr,
        fromTimeStr
      );
      const toUtcDateTimeStr = this.toUtcDateTimeStrFromJstTimeStr(baseUtcDateTimeStr, toTimeStr);

      const newItem = {
        ...item,
        departureScheduleTimeFrom: fromUtcDateTimeStr,
        departureScheduleTimeTo: toUtcDateTimeStr,
      };
      const newRoutes = this.routes.toSpliced(index, 1, newItem);
      this.emitRoutes(newRoutes);
    },
    updateArrivalScheduleDate(item, newDate) {
      const index = this.routes.findIndex((x) => x === item);
      if (index === -1) {
        return;
      }

      const newUtcDateTimeStr = !newDate ? "" : this.toUtcDateTimeStrFromJstDateStr(newDate);

      // 日付が更新された場合時刻も更新する
      const baseDateStr =
        newUtcDateTimeStr !== "" ? newUtcDateTimeStr : dateTimeHelper.convertUTC();
      const fromTimeStr = this.toJstTimeStr(item.arrivalScheduleTimeFrom);
      const newFromStr = this.toUtcDateTimeStrFromJstTimeStr(baseDateStr, fromTimeStr);
      const toTimeStr = this.toJstTimeStr(item.arrivalScheduleTimeFrom);
      const newToStr = this.toUtcDateTimeStrFromJstTimeStr(baseDateStr, toTimeStr);
      const newItem = {
        ...item,
        arrivalScheduleDate: newUtcDateTimeStr,
        arrivalScheduleTimeFrom: newFromStr,
        arrivalScheduleTimeTo: newToStr,
      };
      const newRoutes = this.routes.toSpliced(index, 1, newItem);
      this.emitRoutes(newRoutes);
    },
    updateArrivalScheduleTime(item, fromTimeStr, toTimeStr) {
      const index = this.routes.findIndex((x) => x === item);
      if (index === -1) {
        return;
      }

      // 到着日がなければ現在日時（UTC）をベースにする
      const baseUtcDateTimeStr = !item.arrivalScheduleDate
        ? dateTimeHelper.convertUTC()
        : item.arrivalScheduleDate;
      const fromUtcDateTimeStr = this.toUtcDateTimeStrFromJstTimeStr(
        baseUtcDateTimeStr,
        fromTimeStr
      );
      const toUtcDateTimeStr = this.toUtcDateTimeStrFromJstTimeStr(baseUtcDateTimeStr, toTimeStr);

      const newItem = {
        ...item,
        arrivalScheduleTimeFrom: fromUtcDateTimeStr,
        arrivalScheduleTimeTo: toUtcDateTimeStr,
      };
      const newRoutes = this.routes.toSpliced(index, 1, newItem);
      this.emitRoutes(newRoutes);
    },
  },
};
</script>
<style lang="scss">
#listData-route {
  tr.table-row {
    height: 2rem;
    &:nth-child(odd) {
      background-color: #dde5f0;
    }
  }
  table > thead > tr > th[role="columnheader"] {
    background-color: #effad9;
    height: 3rem;
    font-size: large;
    color: black;
    &.required::after {
      content: "*";
      color: red;
    }
  }
}
</style>
<style scoped>
.table-max-height {
  max-height: calc(3rem + 72px * 4);
}

.remarks-text {
  max-height: 100%;
  max-width: 9rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: start;
}
</style>
