<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_lotSplit" />
      <v-form ref="form" lazy-validation>
        <v-simple-table fixed-header class="main-area; mt-3" id="listData">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">{{ $t("label.lbl_receiveOrderDate") }}</th>
                <th class="text-center">{{ $t("label.lbl_primeContractor") }}</th>
                <th class="text-center">{{ $t("label.lbl_officeSelected") }}</th>
                <th class="text-center">{{ $t("label.lbl_supplier") }}</th>
                <th class="text-center">{{ $t("label.lbl_tansportOrderNo") }}</th>
                <th class="text-center">{{ $t("label.lbl_shippingPoint") }}</th>
                <th class="text-center">{{ $t("label.lbl_addressee") }}</th>
                <th class="text-center">{{ $t("label.lbl_pickupScheduleDate") }}</th>
                <th class="text-center">{{ $t("label.lbl_datetime") }}</th>
                <th class="text-center">{{ $t("label.lbl_arrivingDate") }}</th>
                <th class="text-center">{{ $t("label.lbl_datetime") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">{{ orderDate }}</td>
                <td class="text-center">{{ primeContractor }}</td>
                <td class="text-center">{{ transportOffice }}</td>
                <td class="text-center">{{ supplier }}</td>
                <td class="text-center">{{ orderNo }}</td>
                <td class="text-center">{{ pickupPoint }}</td>
                <td class="text-center">{{ deliveryPoint }}</td>
                <td class="text-center">{{ pickupScheduleDate }}</td>
                <td class="text-center">{{ pickupScheduleTime }}</td>
                <td class="text-center">{{ deliveryScheduleDate }}</td>
                <td class="text-center">{{ deliveryScheduleTime }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-container fluid>
          <v-row>
            <div class="search-row-exeBtn">
              <!-- 戻るボタン -->
              <div style="float: left">
                <v-btn
                  color="primary"
                  type="button"
                  class="other-btn"
                  v-on:click="backbtn()"
                  style="margin: 25px"
                >
                  {{ $t("btn.btn_back") }}
                </v-btn>
              </div>
              <!-- 登録ボタン -->
              <div style="float: right">
                <v-btn
                  color="primary"
                  type="button"
                  class="other-btn"
                  v-on:click="splitBtn()"
                  style="margin: 25px"
                >
                  {{ $t("btn.btn_insert") }}
                </v-btn>
                <!-- 分割解除ボタン -->
                <v-btn
                  color="primary"
                  type="button"
                  class="other-btn"
                  v-on:click="unsplitBtn()"
                  style="margin: 25px"
                  :disabled="isPushUnsplitBtn"
                >
                  {{ $t("btn.btn_unsplit") }}
                </v-btn>
              </div>
            </div>
          </v-row>
        </v-container>
        <v-simple-table fixed-header class="operationDetailList" id="listData" height="685px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center" style="width: 4%">{{ $t("label.lbl_No") }}</th>
                <th class="text-center" style="width: 8%">{{ $t("label.lbl_lotSplit") }}</th>
                <th class="text-left" style="width: 24%">{{ $t("label.lbl_productCodeName") }}</th>
                <th class="text-left" style="width: 8%">{{ $t("label.lbl_packing") }}</th>
                <th class="text-left">{{ $t("label.lbl_weight") }}</th>
                <th class="text-left">{{ $t("label.lbl_verticalLcm") }}</th>
                <th class="text-left">{{ $t("label.lbl_besideLcm") }}</th>
                <th class="text-left">{{ $t("label.lbl_heightLcm") }}</th>
                <th class="text-left">{{ $t("label.lbl_capacity") }}</th>
                <th class="text-left" style="width: 25%">{{ $t("label.lbl_remarks") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, index) in lotSplitList" :key="index">
                <td class="text-center">{{ value.no }}</td>
                <td class="text-center">
                  <v-select
                    v-model="value.lotSplitSelected"
                    :items="lotSplitSelect"
                    :error-messages="lotNoCountup"
                    return-object
                  >
                  </v-select>
                </td>
                <td class="text-left">{{ value.productCodeName }}</td>
                <td class="text-left">{{ value.packing }}</td>
                <td class="text-left">{{ value.weight }}</td>
                <td class="text-left">{{ value.vertical }}</td>
                <td class="text-left">{{ value.width }}</td>
                <td class="text-left">{{ value.height }}</td>
                <td class="text-left">{{ value.capacity }}</td>
                <td class="text-left">{{ value.remarks }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-form>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :screenFlag="infoDialog.screenFlag"
        :changeFlag="infoDialog.changeFlag"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
    </v-sheet>
  </div>
</template>
<script>
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import SimpleDialog from "@/components/SimpleDialog";
import Loading from "@/components/loading";
import NavBar from "../../components/NavBar.vue";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { i18n } from "../../lang/lang.js";

export default {
  name: appConfig.MENU_ID.P_TOD_005,
  components: {
    Loading,
    NavBar,
    SimpleDialog,
  },
  data: () => ({
    // ローディング用
    loadingCounter: 0,
    //受注日
    orderDate: "",
    // 元請営業所
    primeContractor: "",
    // 担当営業所
    transportOffice: "",
    // 取引先
    supplier: "",
    // 受注番号
    orderNo: "",
    // 出荷元
    pickupPoint: "",
    // お届け先
    deliveryPoint: "",
    // 集荷予定日
    pickupScheduleDate: "",
    // 時間（集荷）
    pickupScheduleTime: "",
    // 到着日
    deliveryScheduleDate: "",
    // 時間（到着日）
    deliveryScheduleTime: "",
    // 送り状SID
    invNumSid: "",
    // ロット分割一覧
    lotSplitList: [],
    // ロット分割プルダウン
    lotSplitSelect: [],
    // 分割解除ボタン活性・非活性
    isPushUnsplitBtn: false,
    // ダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      sixPageFlag: false,
      // ダイアログの外クリック制御
      outsideClickNotCloseFlg: false,
    },
    // エラーメッセージ
    lotNoCountup: "",
  }),
  // computed() {

  // },
  mounted() {
    // 前画面から受け取った検索条件を保持する。
    if (this.$route.params.searchParam) {
      this.searchParam = this.$route.params.searchParam;
    }
    // 前画面から受け取った値を設定する。
    // 受注日
    this.orderDate = this.$route.params.orderDate;
    // // 元請営業所
    this.primeContractor = this.$route.params.primeContractor;
    // // 担当営業所
    this.transportOffice = this.$route.params.officeSelected;
    // // 取引先
    this.supplier = this.$route.params.clientName;
    // // 受注番号
    this.orderNo = this.$route.params.tansportOrder;
    // // 出荷元
    this.pickupPoint = this.$route.params.pickupPoint;
    // // お届け先
    this.deliveryPoint = this.$route.params.deliveryPoint;
    // // 集荷予定日
    this.pickupScheduleDate = this.$route.params.pickupScheduleDate;
    // // 時間（集荷）
    this.pickupScheduleTime = this.$route.params.pickupScheduleTime;
    // // 到着日
    this.deliveryScheduleDate = this.$route.params.deliveryScheduleDate;
    // // 時間（到着日）
    this.deliveryScheduleTime = this.$route.params.deliveryScheduleTime;
    // // 送り状SID
    this.invNumSid = this.$route.params.invNumSid;
    // // ヘッダ情報ヘッダ領域テスト用データSTART
    // this.orderDate = "2024/11/11";
    // // 元請営業所
    // this.primeContractor = "日高営業所";
    // // 担当営業所
    // this.transportOffice = "日高営業所";
    // // 取引先
    // this.supplier = "ダイハツ";
    // // 受注番号
    // this.orderNo = "2400000001";
    // // 出荷元
    // this.pickupPoint = "智光山";
    // // お届け先
    // this.deliveryPoint = "柏原営業所";
    // // 集荷予定日
    // this.pickupScheduleDate = "2024/11/03";
    // // 時間（集荷）
    // this.pickupScheduleTime = "13:00 - 14:00";
    // // 到着日
    // this.deliveryScheduleDate = "2024/11/03";
    // // 時間（到着日）
    // this.deliveryScheduleTime = "19:00 - 20:00";
    // // 送り状SID
    // this.invNumSid = "o10231412";
    // ヘッダ情報ヘッダ領域テスト用データEND
    // ロット分割明細取得API実施
    // this.$httpClient.secureGet = function () {
    //   return new Promise((resolve) => {
    //     resolve({
    //       data: {
    //         resCom: {
    //           resComStatus: "Success",
    //           resComCode: "000",
    //           resComCount: 4,
    //         },
    //         resIdv: {
    //           lotSplitDetails: [
    //             {
    //               lotSID: "1003",
    //               lotSplitNo: 3, // ロット分割している場合は数値型で数字、していない場合はnullを入力。
    //               packingSid: "p12345678901",
    //               updateDateTime: "2024/12/22 13:44:32",
    //               itemName: "ｺﾍﾟﾝ",
    //               packingNacCdName: "裸",
    //               totalWeight: "1,500.000",
    //               freightSizeL: "200.00",
    //               freightSizeW: "200.00",
    //               freightSizeH: "200.00",
    //               volume: "8.000",
    //               remarks: "",
    //             },
    //             {
    //               lotSID: "1002",
    //               lotSplitNo: 2, // ロット分割している場合は数値型で数字、していない場合はnullを入力。
    //               packingSid: "p12345678902",
    //               updateDateTime: "2024/12/22 19:14:33",
    //               itemName: "ﾀﾝﾄ",
    //               packingNacCdName: "裸",
    //               totalWeight: "1,500.000",
    //               freightSizeL: "339.50",
    //               freightSizeW: "147.50",
    //               freightSizeH: "177.50",
    //               volume: "8.889",
    //               remarks: "",
    //             },
    //             {
    //               lotSID: "1001",
    //               lotSplitNo: 1, // ロット分割している場合は数値型で数字、していない場合はnullを入力。
    //               packingSid: "p12345678903",
    //               updateDateTime: "2024/12/23 5:39:33",
    //               itemName: "車内掃除用道具",
    //               packingNacCdName: "小型段ボール",
    //               totalWeight: "1.000",
    //               freightSizeL: "30.00",
    //               freightSizeW: "30.00",
    //               freightSizeH: "20.00",
    //               volume: "0.018",
    //               remarks: "",
    //             },
    //             {
    //               lotSID: "1003",
    //               lotSplitNo: 3, // ロット分割している場合は数値型で数字、していない場合はnullを入力。
    //               packingSid: "p12345678904",
    //               updateDateTime: "2024/12/22 10:22:53",
    //               itemName: "付属品",
    //               packingNacCdName: "段ボール",
    //               totalWeight: "10.000",
    //               freightSizeL: "50.00",
    //               freightSizeW: "50.00",
    //               freightSizeH: "50.00",
    //               volume: "0.125",
    //               remarks: "天地無用、荷主出荷（集荷）と配達要",
    //             },
    //             // {
    //             //   lotSID: "1233",
    //             //   lotSplitNo: 1, // ロット分割している場合は数値型で数字、していない場合はnullを入力。
    //             //   packingSid: "p12345678904",
    //             //   updateDateTime: "2024/12/22 10:22:53",
    //             //   itemName: "付属品",
    //             //   packingNacCdName: "段ボール",
    //             //   totalWeight: "10",
    //             //   freightSizeL: "50",
    //             //   freightSizeW: "50",
    //             //   freightSizeH: "50",
    //             //   volume: "0.0064",
    //             //   remarks: "天地無用",
    //             // },
    //             // {
    //             //   lotSID: "1233",
    //             //   lotSplitNo: 1, // ロット分割している場合は数値型で数字、していない場合はnullを入力。
    //             //   packingSid: "p12345678904",
    //             //   updateDateTime: "2024/12/22 10:22:53",
    //             //   itemName: "付属品",
    //             //   packingNacCdName: "段ボール",
    //             //   totalWeight: "10",
    //             //   freightSizeL: "50",
    //             //   freightSizeW: "50",
    //             //   freightSizeH: "50",
    //             //   volume: "0.0064",
    //             //   remarks: "天地無用",
    //             // },
    //             // {
    //             //   lotSID: "1233",
    //             //   lotSplitNo: 1, // ロット分割している場合は数値型で数字、していない場合はnullを入力。
    //             //   packingSid: "p12345678904",
    //             //   updateDateTime: "2024/12/22 10:22:53",
    //             //   itemName: "付属品",
    //             //   packingNacCdName: "段ボール",
    //             //   totalWeight: "10",
    //             //   freightSizeL: "50",
    //             //   freightSizeW: "50",
    //             //   freightSizeH: "50",
    //             //   volume: "0.0064",
    //             //   remarks: "天地無用",
    //             // },
    //             // {
    //             //   lotSID: "1233",
    //             //   lotSplitNo: 1, // ロット分割している場合は数値型で数字、していない場合はnullを入力。
    //             //   packingSid: "p12345678904",
    //             //   updateDateTime: "2024/12/22 10:22:53",
    //             //   itemName: "付属品",
    //             //   packingNacCdName: "段ボール",
    //             //   totalWeight: "10",
    //             //   freightSizeL: "50",
    //             //   freightSizeW: "50",
    //             //   freightSizeH: "50",
    //             //   volume: "0.0064",
    //             //   remarks: "天地無用",
    //             // },
    //             // {
    //             //   lotSID: "1233",
    //             //   lotSplitNo: 1, // ロット分割している場合は数値型で数字、していない場合はnullを入力。
    //             //   packingSid: "p12345678904",
    //             //   updateDateTime: "2024/12/22 10:22:53",
    //             //   itemName: "付属品",
    //             //   packingNacCdName: "段ボール",
    //             //   totalWeight: "10",
    //             //   freightSizeL: "50",
    //             //   freightSizeW: "50",
    //             //   freightSizeH: "50",
    //             //   volume: "0.0064",
    //             //   remarks: "天地無用",
    //             // },
    //             // {
    //             //   lotSID: "1233",
    //             //   lotSplitNo: 1, // ロット分割している場合は数値型で数字、していない場合はnullを入力。
    //             //   packingSid: "p12345678904",
    //             //   updateDateTime: "2024/12/22 10:22:53",
    //             //   itemName: "付属品",
    //             //   packingNacCdName: "段ボール",
    //             //   totalWeight: "10",
    //             //   freightSizeL: "50",
    //             //   freightSizeW: "50",
    //             //   freightSizeH: "50",
    //             //   volume: "0.0064",
    //             //   remarks: "天地無用",
    //             // },
    //             // {
    //             //   lotSID: "1233",
    //             //   lotSplitNo: 1, // ロット分割している場合は数値型で数字、していない場合はnullを入力。
    //             //   packingSid: "p12345678904",
    //             //   updateDateTime: "2024/12/22 10:22:53",
    //             //   itemName: "付属品",
    //             //   packingNacCdName: "段ボール",
    //             //   totalWeight: "10",
    //             //   freightSizeL: "50",
    //             //   freightSizeW: "50",
    //             //   freightSizeH: "50",
    //             //   volume: "0.0064",
    //             //   remarks: "天地無用",
    //             // },
    //             // {
    //             //   lotSID: "1233",
    //             //   lotSplitNo: 1, // ロット分割している場合は数値型で数字、していない場合はnullを入力。
    //             //   packingSid: "p12345678904",
    //             //   updateDateTime: "2024/12/22 10:22:53",
    //             //   itemName: "付属品",
    //             //   packingNacCdName: "段ボール",
    //             //   totalWeight: "10",
    //             //   freightSizeL: "50",
    //             //   freightSizeW: "50",
    //             //   freightSizeH: "50",
    //             //   volume: "0.0064",
    //             //   remarks: "天地無用",
    //             // },
    //             // {
    //             //   lotSID: "1233",
    //             //   lotSplitNo: 1, // ロット分割している場合は数値型で数字、していない場合はnullを入力。
    //             //   packingSid: "p12345678904",
    //             //   updateDateTime: "2024/12/22 10:22:53",
    //             //   itemName: "付属品",
    //             //   packingNacCdName: "段ボール",
    //             //   totalWeight: "10",
    //             //   freightSizeL: "50",
    //             //   freightSizeW: "50",
    //             //   freightSizeH: "50",
    //             //   volume: "0.0064",
    //             //   remarks: "天地無用",
    //             // },
    //             // {
    //             //   lotSID: "1233",
    //             //   lotSplitNo: 1, // ロット分割している場合は数値型で数字、していない場合はnullを入力。
    //             //   packingSid: "p12345678904",
    //             //   updateDateTime: "2024/12/22 10:22:53",
    //             //   itemName: "付属品",
    //             //   packingNacCdName: "段ボール",
    //             //   totalWeight: "10",
    //             //   freightSizeL: "50",
    //             //   freightSizeW: "50",
    //             //   freightSizeH: "50",
    //             //   volume: "0.0064",
    //             //   remarks: "天地無用",
    //             // },
    //             // {
    //             //   lotSID: "1233",
    //             //   lotSplitNo: 1, // ロット分割している場合は数値型で数字、していない場合はnullを入力。
    //             //   packingSid: "p12345678904",
    //             //   updateDateTime: "2024/12/22 10:22:53",
    //             //   itemName: "付属品",
    //             //   packingNacCdName: "段ボール",
    //             //   totalWeight: "10",
    //             //   freightSizeL: "50",
    //             //   freightSizeW: "50",
    //             //   freightSizeH: "50",
    //             //   volume: "0.0064",
    //             //   remarks: "天地無用",
    //             // },
    //             // {
    //             //   lotSID: "1233",
    //             //   lotSplitNo: 1, // ロット分割している場合は数値型で数字、していない場合はnullを入力。
    //             //   packingSid: "p12345678904",
    //             //   updateDateTime: "2024/12/22 10:22:53",
    //             //   itemName: "付属品",
    //             //   packingNacCdName: "段ボール",
    //             //   totalWeight: "10",
    //             //   freightSizeL: "50",
    //             //   freightSizeW: "50",
    //             //   freightSizeH: "50",
    //             //   volume: "0.0064",
    //             //   remarks: "天地無用",
    //             // },
    //             // {
    //             //   lotSID: "1233",
    //             //   lotSplitNo: 1, // ロット分割している場合は数値型で数字、していない場合はnullを入力。
    //             //   packingSid: "p12345678904",
    //             //   updateDateTime: "2024/12/22 10:22:53",
    //             //   itemName: "付属品",
    //             //   packingNacCdName: "段ボール",
    //             //   totalWeight: "10",
    //             //   freightSizeL: "50",
    //             //   freightSizeW: "50",
    //             //   freightSizeH: "50",
    //             //   volume: "0.0064",
    //             //   remarks: "天地無用",
    //             // },
    //             // {
    //             //   lotSID: "1233",
    //             //   lotSplitNo: 1, // ロット分割している場合は数値型で数字、していない場合はnullを入力。
    //             //   packingSid: "p12345678904",
    //             //   updateDateTime: "2024/12/22 10:22:53",
    //             //   itemName: "付属品",
    //             //   packingNacCdName: "段ボール",
    //             //   totalWeight: "10",
    //             //   freightSizeL: "50",
    //             //   freightSizeW: "50",
    //             //   freightSizeH: "50",
    //             //   volume: "0.0064",
    //             //   remarks: "天地無用",
    //             // },
    //             // {
    //             //   lotSID: "1233",
    //             //   lotSplitNo: 1, // ロット分割している場合は数値型で数字、していない場合はnullを入力。
    //             //   packingSid: "p12345678904",
    //             //   updateDateTime: "2024/12/22 10:22:53",
    //             //   itemName: "付属品",
    //             //   packingNacCdName: "段ボール",
    //             //   totalWeight: "10",
    //             //   freightSizeL: "50",
    //             //   freightSizeW: "50",
    //             //   freightSizeH: "50",
    //             //   volume: "0.0064",
    //             //   remarks: "天地無用",
    //             // },
    //           ],
    //         },
    //       },
    //     });
    //   });
    // };
    // リクエストの設定
    const config = this.$httpClient.createGetApiRequestConfig();
    config.params.invNumSid = this.invNumSid;
    return new Promise((resolve, reject) => {
      this.$httpClient
        .secureGet(appConfig.API_URL.BIZ_TRANSPORT_LOT_SPLIT_DETAILS, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            let list = [];
            let no = 0;
            var count = 0;
            for (const row of jsonData.resIdv.lotSplitDetails) {
              list.push({
                no: ++no,
                lotSplitSelected: row.lotSplitNo,
                productCodeName: row.itemName,
                packing: row.packingNacCdName,
                weight: row.totalWeight,
                vertical: row.freightSizeL,
                width: row.freightSizeW,
                height: row.freightSizeH,
                capacity: row.volume,
                remarks: row.remarks,
                packingSids: row.packingSid,
                lotNumber: row.lotSID,
                updateDateTime: row.updateDateTime,
              });
              if (row.lotSID == null || row.lotSID == "") {
                count++;
              }
            }
            // ロット分割プルダウン
            for (let i = 1; i < list.length + 1; i++) {
              this.lotSplitSelect.push(i);
            }

            this.lotSplitList = list;
            // 分割解除ボタン活性・非活性
            // (ロットSIDがすべて設定されていない。つまりロット分割されていない場合は非活性)
            if (count == jsonData.resCom.resComCount) {
              this.isPushUnsplitBtn = true;
            }
            resolve(response);
          } else {
            this.infoDialog.message = jsonData.resCom.resComMessage;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
          reject();
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    });
  },

  methods: {
    /**
     * 戻るボタン処理（登録・分割解除ボタン押下時に前画面に戻る際にも使用する。）
     */
    backbtn() {
      this.checkFlg = true;

      this.$router.push({
        name: appConfig.SCREEN_ID.P_TOD_002,
        // 検索条件
        params: {
          searchParam: this.searchParam,
        },
      });
    },
    /**
     * 登録ボタン処理
     */
    splitBtn() {
      // 単項目チェック 入力された数値が１から始めて１ずつカウントアップされていない場合。
      if (!this.checkLotNoCountup()) {
        return;
      }
      // 分割・分割解除フラグに分割：０を設定する。
      this.callLotSplitAPI(0);
    },
    /**
     * 分割解除ボタン処理
     */
    unsplitBtn() {
      // 分割・分割解除フラグに解除：１を設定する。
      this.callLotSplitAPI(1);
    },

    /**
     * 分割・分割解除APIコール処理
     */
    checkLotNoCountup() {
      // 下記チェックしやすくするため、画面入力されたロット分割Noをソートする。
      let sortedLotSplitNoList = [];
      for (const row of this.lotSplitList) {
        sortedLotSplitNoList.push(row.lotSplitSelected);
      }
      sortedLotSplitNoList.sort((a, b) => {
        return a - b;
      });
      // 1からカウントアップされていない場合、チェックエラー。
      var check = 1;
      if (sortedLotSplitNoList[0] > check) {
        this.lotNoCountup = i18n.tc("check.chk_lotNoCountup");
        return false;
      }
      // 1ずつカウントアップされていない場合、チェックエラー。
      for (const splitNo of sortedLotSplitNoList) {
        if (splitNo == check) {
          continue;
        } else if (splitNo == check + 1) {
          check++;
        } else {
          this.lotNoCountup = i18n.tc("check.chk_lotNoCountup");
          return false;
        }
      }
      return true;
    },

    /**
     * 分割・分割解除APIコール処理
     */
    callLotSplitAPI(splitFlg) {
      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();
      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_TOD_005;
      // 送り状SID
      body.reqIdv.invNumSid = this.invNumSid;
      // 荷姿指示SIDリスト
      let list = [];
      for (const row of this.lotSplitList) {
        list.push({
          // 荷姿指示SIDリスト.荷姿指示SID
          packingSid: row.packingSids,
          // 荷姿指示SIDリスト.ロットSID
          lotSID: row.lotNumber,
          // 荷姿指示SIDリスト.ロット分割No
          lotSplitNo: row.lotSplitSelected,
          // 荷姿指示SIDリスト.更新日時(楽観ロック用：取得APIから取得した更新日時をDBで扱えるよう変換)
          updateDateTime: dateTimeHelper.convertJST(row.updateDateTime),
        });
      }
      body.reqIdv.packingSids = list;
      // 分割・分割解除フラグ
      body.reqIdv.splitFlg = splitFlg;
      // POSTAPIスタブ
      // this.$httpClient.securePost = function (url, body, config) {
      //   body;
      //   config;
      //   if (url == appConfig.API_URL.BIZ_LOT_SPLIT) {
      //     var value = new Promise((resolve) => {
      //       resolve({
      //         data: {
      //           resCom: {
      //             resComStatus: "Success",
      //             resComCode: "000",
      //           },
      //           resIdv: {},
      //         },
      //       });
      //     }).catch((ex) => {
      //       ex;
      //     });
      //     return value;
      //   }
      // };
      // APIコール
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_LOT_SPLIT, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              resolve(response);
              // 前画面に戻る。
              this.backbtn();

              // エラー時
            } else {
              reject(new Error(jsonData.resCom.resComMessage));
            }
          })
          .catch((ex) => {
            reject(ex);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      }).catch((ex) => {
        this.infoDialog.message = ex.message;
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
        this.infoDialog.homePageFlag = false;
        this.infoDialog.trnListFlg = false;
        this.infoDialog.trnAddFlg = false;
        this.infoDialog.outsideClickNotCloseFlg = false;
      });
    },
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.field {
  display: flex;
}
</style>
